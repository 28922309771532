import { Circle } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

export const CustomTooltip = ({ value, color, label }: any) => (
  <Box
    sx={{
      bgcolor: "#fff",
      borderRadius: 2,
      width: "180px",
      padding: 2,
      filter: "drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.08))",
      position: "relative",
      top: "55px",
      left: "105px",
      "&::before": {
        content: "''",
        position: "absolute",
        top: "50%",
        left: "-23px",
        zIndex: "-1",
        transform: "rotate(45deg) translateY(-50%)",
        borderRadius: "8px",
        height: "30px",
        width: "30px",
        bgcolor: "#fff",
      },
    }}
  >
    <Typography fontSize={32} fontWeight={600} mb={1.5} color="#355962">
      {value}
    </Typography>
    <Box sx={{ display: "flex", alignItems: "ceter", gap: 1 }}>
      <Circle htmlColor={color} />
      <Typography
        variant="subtitle1"
        fontWeight="medium"
        color="#637E85"
        textTransform={"capitalize"}
      >
        {label}
      </Typography>
    </Box>
  </Box>
);

export const CustomTooltipPieChart = ({ value, color, label }: any) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      gap: 0.5,
      p: 1,
      height: "40px",
      minWidth: "100px",
      maxWidth: "150px",
      backgroundColor: "#FFFFFF",
      borderRadius: "6px",
    }}
  >
    <Box
      sx={{
        height: "15px",
        width: "15px",
        borderRadius: "2px",
        bgcolor: color,
      }}
    />
    <Typography variant="body1" color={"#355962"}>
      {label}:
    </Typography>
    <Typography variant="body1" color={"#355962"}>
      {value}
    </Typography>
  </Box>
);

export const BloodGlucoseCustomTooltip = ({ value, color, label }: any) => (
  <Box
    sx={{
      p: 1,
      minWidth: "150px",
      backgroundColor: "#FFFFFF",
      borderRadius: "6px",
    }}
  >
    <Typography fontSize={16} fontWeight={700} color={"#242529"} mb={1}>
      Blood Glucose
    </Typography>
    <Box sx={{ display: "flex", alignItems: "center", gap: 0.75 }}>
      <Box
        sx={{
          height: "10px",
          width: "10px",
          borderRadius: "2px",
          bgcolor: color,
        }}
      />
      <Typography fontWeight={700} fontSize={12} color={"#242529"}>
        {value}%
      </Typography>
    </Box>
  </Box>
);

export const CustomTooltipLineGraph = ({ value, color, label, type }: any) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 0.5,
      p: 1,
      minHeight: "40px",
      minWidth: "150px",
      backgroundColor: "#FFFFFF",
      borderRadius: "6px",
      border: "1px solid #E3E0E5",
    }}
  >
    {type !== "blood_glucose" && (
      <Box
        sx={{
          height: "15px",
          width: "15px",
          borderRadius: "2px",
          bgcolor: color,
        }}
      />
    )}
    <Typography variant="body1" color={"#355962"} fontWeight={550}>
      x : {label} ,
    </Typography>
    <Typography variant="body1" color={"#355962"} fontWeight={550}>
      y : {value.toFixed(2)}
    </Typography>
  </Box>
);
