import React from "react";
import {
  Box,
  LinearProgress,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  NoDataContainer,
  StyledTableCell,
  TableLayoutStyle,
} from "../../../../Common/styles/table";

export const StyleHeader: SxProps = {
  bgcolor: "#F9FAFB",
  borderTop: "1px solid #E0E3EB",
  borderBottom: "1px solid #E0E3EB",
  color: "#355962",
};
export const StyleBody: SxProps = {
  color: "#637E85",
  fontSize: "14px",
  fontWeight: 600,
};

const RespiratoryRateInsights = ({ tableData, loading }: any) => {
  return (
    <Box>
      <Box
        sx={{
          padding: "16px",
          bgcolor: "#FFFFFF",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
          Respiratory Rate Data
        </Typography>
      </Box>
      <Box>
        <Table>
          <TableHead sx={{ ...TableLayoutStyle }}>
            <TableRow>
              <TableCell sx={StyleHeader}>Date</TableCell>
              <TableCell sx={StyleHeader}>Respiratory Rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              display: "block",
              ...(tableData?.length > 0 && {
                height: "calc(100vh - 418px)",
                overflow: "auto",
              }),
            }}
          >
            {!loading &&
              tableData?.length > 0 &&
              tableData.map((item: any, index: number) => (
                <TableRow key={index} sx={TableLayoutStyle}>
                  <StyledTableCell sx={StyleBody}>
                    {item.dateLabel} {item?.time ? " at " + item?.time : null}
                  </StyledTableCell>
                  <StyledTableCell sx={StyleBody}>
                    {item?.value}
                  </StyledTableCell>
                </TableRow>
              ))}
            {!loading && tableData?.length === 0 && (
              <NoDataContainer>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            )}
            {loading && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default RespiratoryRateInsights;
