import {
  Box,
  CircularProgress,
  circularProgressClasses,
  CircularProgressProps,
  SxProps,
  Typography,
} from "@mui/material";
const foodWrapper: SxProps = {
  p: 2,
  minHeight: "100px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const CustomCircularProgressWithLabel = (
  props: CircularProgressProps & {
    taken: number;
    total: number;
    percentage: number;
    ringcolor?: string;
    fontSize?: number;
    fontWeight?: number;
  }
) => {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        value={props.taken}
        size={60}
        sx={{
          color: "#E5E7EB",
        }}
        {...props}
      />
      <CircularProgress
        variant="determinate"
        size={50}
        value={props?.percentage}
        {...props}
        sx={{
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
          color: props.ringcolor,
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 7,
          right: 10,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          fontSize={props.fontSize || 16}
          fontWeight={props.fontWeight || 600}
          color="#6B7280"
        >
          {Math.round(props.taken)}/{Math.round(props.total)}{" "}
          {/* Static text */}
        </Typography>
      </Box>
    </Box>
  );
};

function getRingColor(percentage: any) {
  if (percentage >= 100) {
    return "#70AE71";
  } else if (percentage < 100 && percentage >= 50) {
    return "#FACA15";
  } else if (percentage < 50) {
    return "#FF7164";
  }
}

const MedicationItem = ({ medication }: any) => {
  return (
    <Box sx={foodWrapper}>
      <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="body1" fontWeight={"medium"}>
          {medication.name}
        </Typography>
      </div>
      <Box sx={{ mr: 35 }}>
        <CustomCircularProgressWithLabel
          taken={medication?.taken}
          total={medication?.total}
          percentage={medication?.percentage}
          ringcolor={getRingColor(medication?.percentage)}
          fontSize={14}
          fontWeight={600}
        />
      </Box>
    </Box>
  );
  // return (
  //   <Box key={medication?.id} sx={medBox}>
  //     <Typography fontWeight={600} fontSize="20px" color="#355962" mb={3}>
  //       Medication time {medication?.reminderTime}
  //     </Typography>
  //     {medication?.meds.length > 0 ? (
  //       <Box
  //         sx={{
  //           display: "flex",
  //           flexDirection: "column",
  //           gap: 2,
  //         }}
  //       >
  //         {medication?.meds.map((med: any) => {
  //           return (
  //             <Box
  //               key={med.id}
  //               sx={
  //                 med?.status === "taken"
  //                   ? takenLog
  //                   : med?.status === "skipped"
  //                   ? skippedLog
  //                   : logBox
  //               }
  //             >
  //               <Checkbox
  //                 icon={
  //                   <CircleOutlined
  //                     htmlColor={
  //                       med?.status === "skipped" ? "#F98080" : "#9CA3AF"
  //                     }
  //                   />
  //                 }
  //                 checkedIcon={<CheckCircle htmlColor="#76B700" />}
  //                 checked={med?.status === "taken"}
  //               />
  //               <Box>
  //                 <Typography
  //                   color={med?.status === "skipped" ? "#F98080" : "#111928"}
  //                   fontSize={"15px"}
  //                   fontWeight={"medium"}
  //                   sx={{
  //                     textDecoration:
  //                       med?.status === "skipped" ? "line-through" : "none",
  //                   }}
  //                 >
  //                   {med.label}
  //                 </Typography>
  //                 {med?.status === "taken" && (
  //                   <Typography
  //                     color="#111928"
  //                     fontSize={"11px"}
  //                     fontWeight="regular"
  //                   >
  //                     Logged at {med?.takenTime}
  //                   </Typography>
  //                 )}
  //               </Box>
  //               <Typography
  //                 fontSize={"13px"}
  //                 sx={{ marginLeft: "auto" }}
  //                 color="#111928"
  //                 fontWeight={"regular"}
  //               >
  //                 {med.dose}
  //               </Typography>
  //             </Box>
  //           );
  //         })}
  //       </Box>
  //     ) : (
  //       <Typography variant="body1" color="gray" textAlign={"center"}>
  //         No Data
  //       </Typography>
  //     )}
  //   </Box>
  // );
};

export default MedicationItem;
