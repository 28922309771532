import { Button, IconButton, SxProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setDateLoaded,
  setHealthEndDate,
  setHealthStartDate,
  setOffSet,
  setSelectedCriteria,
} from "../../../Redux/reducers/healthSlice";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const HeaderStyle: SxProps = {
  height: "80px",
  width: "100%",
  background: "#ffffff",
  borderRadius: "0px 0px 8px 8px",
  mb: 2,
  display: "flex",
  alignItems: "center",
  p: 2,
  borderTop: "1px solid",
  borderColor: "#E0E3EB",
};

function getStartEndDate(criteria: any, offSet: any) {
  const endTemp = DateTime.now();
  let startTemp: DateTime = endTemp.plus({ days: 0 });

  if (criteria === "week" && offSet % 7 === 0) {
    startTemp = endTemp.plus({ weeks: offSet / 7 });
  } else if (criteria === "month" && offSet % 30 === 0) {
    startTemp = endTemp.plus({ months: offSet / 30 });
  } else if (criteria === "year" && offSet % 365 === 0) {
    startTemp = endTemp.plus({ years: offSet / 365 });
  } else {
    startTemp = endTemp.plus({ days: offSet });
  }
  const start = startTemp.startOf(criteria);
  const end = startTemp.endOf(criteria);
  return {
    start: start.toFormat("LL-dd-yyyy"),
    end: end.toFormat("LL-dd-yyyy"),
  };
}

const HealthDateSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  // const [openStart, setOpenStart] = useState(false);
  // const [openEnd, setOpenEnd] = useState(false);
  // const [minDate, setMinDate] = useState<DateTime | null>(null);
  // const [maxStart] = useState(DateTime.now().plus({ days: -1 }));
  // const [maxDate, setMaxDate] = useState(DateTime.now());
  const { type, criteria, offSet, disableButton } = useAppSelector(
    (state) => state.health
  );

  // const changeDate = (val: DateTime | null, type: "start" | "end") => {
  //   if (type === "end") {
  //     dispatch(setHealthEndDate(val?.toFormat("LL-dd-yyyy") || null));
  //   } else {
  //     if (endDate && val) {
  //       const end = DateTime.fromFormat(endDate, "LL-dd-yyyy");
  //       const days = end.diff(val, "days").get("days");
  //       if (days > 90) {
  //         dispatch(setHealthEndDate(null));
  //         setOpenEnd(true);
  //       }
  //     }
  //     dispatch(setHealthStartDate(val?.toFormat("LL-dd-yyyy") || null));
  //   }
  // };

  // useEffect(() => {
  //   const endTemp = DateTime.now();
  //   let startTemp: DateTime = endTemp.plus({ days: 0 });

  //   if (criteria === "week" && offSet % 7 === 0) {
  //     startTemp = endTemp.plus({ weeks: offSet / 7 });
  //   } else if (criteria === "month" && offSet % 30 === 0) {
  //     startTemp = endTemp.plus({ months: offSet / 30 });
  //   } else if (criteria === "year" && offSet % 365 === 0) {
  //     startTemp = endTemp.plus({ years: offSet / 365 });
  //   } else {
  //     startTemp = endTemp.plus({ days: offSet });
  //   }
  //   const start = startTemp.startOf(criteria);
  //   const end = startTemp.endOf(criteria);
  //   dispatch(setHealthEndDate(end.toFormat("LL-dd-yyyy")));
  //   dispatch(setHealthStartDate(start.toFormat("LL-dd-yyyy")));
  // }, [dispatch, offSet, criteria, startDate, endDate]);

  // useEffect(() => {
  //   if (startDate) {
  //     const days = DateTime.now()
  //       .diff(DateTime.fromFormat(startDate, "LL-dd-yyyy"), "days")
  //       .get("days");
  //     if (days > 90) {
  //       setMaxDate(
  //         DateTime.fromFormat(startDate, "LL-dd-yyyy").plus({ days: 90 })
  //       );
  //     } else {
  //       setMaxDate(DateTime.now());
  //     }
  //     setMinDate(
  //       DateTime.fromFormat(startDate, "LL-dd-yyyy").plus({ days: 1 })
  //     );
  //   } else {
  //     setMaxDate(DateTime.now());
  //     setMinDate(null);
  //   }
  // }, [startDate, setMaxDate, setMinDate]);

  useEffect(() => {
    dispatch(setOffSet(0));
    if (type === "health") {
      const end = DateTime.now();
      const start = end.plus({ days: -30 });
      dispatch(setHealthEndDate(end.toFormat("LL-dd-yyyy")));
      dispatch(setHealthStartDate(start.toFormat("LL-dd-yyyy")));
      dispatch(setDateLoaded(true));
    } else if (
      type === "blood_glucose" ||
      type === "respiration" ||
      type === "stats" ||
      type === "heart_rate" ||
      type === "spo2" ||
      type === "temperature"
    ) {
      dispatch(setSelectedCriteria("day"));
      const { start, end } = getStartEndDate("day", 0);
      // const endTemp = DateTime.now();
      // const end = endTemp.endOf("day");
      // const start = endTemp.startOf("day");
      dispatch(setHealthEndDate(end));
      dispatch(setHealthStartDate(start));
      dispatch(setDateLoaded(true));
    } else if (type === "weight") {
      dispatch(setSelectedCriteria("month"));
      const { start, end } = getStartEndDate("month", 0);
      dispatch(setHealthEndDate(end));
      dispatch(setHealthStartDate(start));
      dispatch(setDateLoaded(true));
    } else {
      dispatch(setSelectedCriteria("week"));
      const { start, end } = getStartEndDate("week", 0);
      dispatch(setHealthEndDate(end));
      dispatch(setHealthStartDate(start));
      dispatch(setDateLoaded(true));
    }
  }, [type, dispatch]);

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     const start = DateTime.fromFormat(startDate, "LL-dd-yyyy");
  //     const end = DateTime.fromFormat(endDate, "LL-dd-yyyy");
  //     const days = end.diff(start, "days").get("days");
  //     dispatch(setSelectedDate(days));
  //   }
  // }, [startDate, endDate, dispatch]);

  function handleDateChange(selectedCriteria: string) {
    let tempOffSet = offSet;
    if (selectedCriteria === "increment") {
      // tempOffSet+=1
      switch (criteria) {
        case "day":
          tempOffSet += 1;
          break;
        case "week":
          tempOffSet += 7;
          break;
        case "month":
          tempOffSet += 30;

          break;
        case "year":
          tempOffSet += 365;

          break;
      }
    } else if (selectedCriteria === "decrement") {
      switch (criteria) {
        case "day":
          tempOffSet -= 1;

          break;
        case "week":
          tempOffSet -= 7;
          break;
        case "month":
          tempOffSet -= 30;
          break;
        case "year":
          tempOffSet -= 365;
          break;
      }
    }
    const { start, end } = getStartEndDate(criteria, tempOffSet);
    dispatch(setOffSet(tempOffSet));
    dispatch(setHealthEndDate(end));
    dispatch(setHealthStartDate(start));
  }
  function getDisplayCriteria() {
    if (criteria === "day") {
      if (offSet === 0) {
        return "Today";
      } else {
        return `${Math.abs(offSet)} ${
          Math.abs(offSet) === 1 ? "day" : "days"
        } ago`;
      }
    } else if (criteria === "week") {
      if (offSet === 0) {
        return "This week";
      } else {
        return `${Math.abs(offSet) / 7} ${
          Math.abs(offSet) / 7 === 1 ? "week" : "weeks"
        } ago`;
      }
    } else if (criteria === "month") {
      if (offSet === 0) {
        return "This month";
      } else {
        return `${Math.abs(offSet) / 30} ${
          Math.abs(offSet) / 30 === 1 ? "month" : "months"
        } ago`;
      }
    } else if (criteria === "year") {
      if (offSet === 0) {
        return "This year";
      } else {
        return `${Math.abs(offSet) / 365} ${
          Math.abs(offSet) / 365 === 1 ? "year" : "years"
        } ago`;
      }
    }
  }
  function handleCriteriaChange(selectedCriteria: any) {
    const { start, end } = getStartEndDate(selectedCriteria, 0);

    dispatch(setSelectedCriteria(selectedCriteria));
    dispatch(setOffSet(0));

    dispatch(setHealthEndDate(end));
    dispatch(setHealthStartDate(start));
  }

  return (
    <Box sx={HeaderStyle}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            borderRadius: "8px",
            height: "43px",
          }}
        >
          <IconButton
            onClick={() => handleDateChange("decrement")}
            disabled={disableButton}
          >
            <ChevronLeft />
          </IconButton>
        </Box>
        <Box
          sx={{
            border: 1,
            borderColor: "divider",

            borderRadius: "8px",
            height: "43px",
            width: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="subtitle1" fontWeight={"medium"}>
            {getDisplayCriteria()}
          </Typography>
        </Box>
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            borderRadius: "8px",
            height: "43px",
          }}
        >
          <IconButton
            onClick={() => handleDateChange("increment")}
            disabled={offSet === 0 || disableButton}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>
      {/* <Typography fontSize={"14px"} fontWeight={"regular"} mr={1}>
        Start Date
      </Typography>
      <DatePicker
        format="MM/dd/yyyy"
        onChange={(newValue: any) => {
          changeDate(newValue, "start");
        }}
        value={startDate ? DateTime.fromFormat(startDate, "LL-dd-yyyy") : null}
        open={openStart}
        onOpen={() => {
          setOpenStart(true);
        }}
        onClose={() => {
          setOpenStart(false);
        }}
        maxDate={maxStart}
        slotProps={{
          textField: {
            variant: "outlined",
            sx: {
              maxWidth: "200px",
              mb: 1,
              mr: 2,
            },
            inputProps: {
              readOnly: true,
              placeholder: "MM/DD/YYYY",
            },
          },
        }}
        // renderInput={(params) => (
        //   <TextField
        //     {...params}
        //     sx={{ maxWidth: "200px", mb: 1, mr: 2 }}
        //     inputProps={{
        //       ...params.inputProps,
        //       readOnly: true,
        //       placeholder: "MM/DD/YYYY",
        //     }}
        //   />
        // )}
      />
      <Typography fontSize={"14px"} fontWeight={"regular"} mr={1}>
        End Date
      </Typography>
      <DatePicker
        format="MM/dd/yyyy"
        onChange={(newValue: any) => {
          changeDate(newValue, "end");
        }}
        open={openEnd}
        onOpen={() => {
          setOpenEnd(true);
        }}
        onClose={() => {
          setOpenEnd(false);
        }}
        maxDate={maxDate}
        minDate={minDate}
        value={endDate ? DateTime.fromFormat(endDate, "LL-dd-yyyy") : null}
        slotProps={{
          textField: {
            variant: "outlined",
            sx: {
              maxWidth: "200px",
              mb: 1,
            },
            inputProps: {
              readOnly: true,
              placeholder: "MM/DD/YYYY",
            },
          },
        }}
        // renderInput={(params) => (
        //   <TextField
        //     {...params}
        //     sx={{ maxWidth: "200px", mb: 1 }}
        //     inputProps={{
        //       ...params.inputProps,
        //       readOnly: true,
        //       placeholder: "MM/DD/YYYY",
        //     }}
        //   />
        // )}
      /> */}
      <span style={{ marginLeft: "auto" }}></span>
      {type !== "health" &&
        (type === "blood_glucose" ||
          type === "heart_rate" ||
          type === "respiration" ||
          type === "stats" ||
          type === "temperature" ||
          type === "spo2") && (
          <Box
            sx={{
              borderBottom: criteria === "day" ? 2 : 0,
              borderColor: "#387091",
              mr: 1,
            }}
          >
            <Button
              variant="text"
              onClick={() => handleCriteriaChange("day")}
              disabled={disableButton}
            >
              Day
            </Button>
          </Box>
        )}
      {type !== "health" && type !== "weight" && (
        <Box
          sx={{
            borderBottom: criteria === "week" ? 2 : 0,
            borderColor: "#387091",
            mr: 1,
          }}
        >
          <Button
            variant="text"
            onClick={() => handleCriteriaChange("week")}
            disabled={disableButton}
          >
            Week
          </Button>
        </Box>
      )}
      <Box
        sx={{
          borderBottom: criteria === "month" ? 2 : 0,
          borderColor: "#387091",
          mr: 1,
        }}
      >
        <Button
          variant="text"
          onClick={() => handleCriteriaChange("month")}
          disabled={disableButton}
        >
          Month
        </Button>
      </Box>
      {type !== "blood_glucose" &&
        type !== "food" &&
        type !== "medication" &&
        type !== "stats" &&
        type !== "respiration" &&
        type !== "temperature" &&
        type !== "heart_rate" &&
        type !== "steps" &&
        type !== "sleep" &&
        type !== "spo2" && (
          <Box
            sx={{
              borderBottom: criteria === "year" ? 2 : 0,
              borderColor: "#387091",
              mr: 1,
            }}
          >
            <Button
              variant="text"
              onClick={() => handleCriteriaChange("year")}
              disabled={disableButton}
            >
              Year
            </Button>
          </Box>
        )}
      {/* <Box
        sx={{
          borderBottom: selectedDate === 60 ? 2 : 0,
          borderColor: "#387091",
          mr: 1,
        }}
      >
        <Button variant="text" onClick={() => modifyDates(60)}>
          Last 60 Days
        </Button>
      </Box> */}
      {/* <Box
        sx={{
          borderBottom: selectedDate === 90 ? 2 : 0,
          borderColor: "#387091",
        }}
      >
        <Button variant="text" onClick={() => modifyDates(90)}>
          Last 90 Days
        </Button>
      </Box> */}
    </Box>
  );
};

export default HealthDateSelector;
/*

  <span style={{ marginLeft: "auto" }}></span>
      {type !== "health" && (
        <Button
          variant={selectedDate === 7 ? "outlined" : "text"}
          sx={{ mr: 1 }}
          onClick={() => modifyDates(7)}
        >
          Last 7 Days
        </Button>
      )}
      <Button
        variant={selectedDate === 30 ? "outlined" : "text"}
        sx={{ mr: 1 }}
        onClick={() => modifyDates(30)}
      >
        Last 30 Days
      </Button>
      <Button
        variant={selectedDate === 60 ? "outlined" : "text"}
        sx={{ mr: 1 }}
        onClick={() => modifyDates(60)}
      >
        Last 60 Days
      </Button>
      <Button
        variant={selectedDate === 90 ? "outlined" : "text"}
        onClick={() => modifyDates(90)}
      >
        Last 90 Days
      </Button>


*/
