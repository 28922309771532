import { Box, Button, Typography } from "@mui/material";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../Common/styles/header";
// import DashboardContent from "./DashboardContent/DashboardContent";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import AddWidgetModal from "./Modals/AddWidgetModal";
import DashboardContent from "./DashboardContent/DashboardContent";

const Dashboard = () => {
  const [toggleLoader, setToggleLoader] = useState<boolean>(false);
  const [openWidgets, setOpenWidgets] = useState<boolean>(false);
  const [widgetList, setWidgetList] = useState<any[]>([]);
  const refreshPage = () => {
    setToggleLoader((prev: boolean) => !prev);
  };

  const openWidgetModal = () => {
    setOpenWidgets(true);
  };
  const closeWidgetModal = () => {
    setOpenWidgets(false);
  };

  return (
    <>
      <StyledHeader>
        <Box sx={HeaderLeftContent}>
          <Typography fontSize={30} fontWeight="bold">
            Dashboard
          </Typography>
        </Box>
        <Box sx={HeaderRightContent}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={openWidgetModal}
          >
            Add Widget
          </Button>
        </Box>
      </StyledHeader>

      <Box sx={{ mt: 3 }}>
        <DashboardContent
          toggleLoader={toggleLoader}
          openWidgetModal={openWidgetModal}
          refreshPage={refreshPage}
          setWidgetList={setWidgetList}
        />
      </Box>
      {openWidgets && (
        <AddWidgetModal
          showModal={openWidgets}
          closeModal={closeWidgetModal}
          refreshPage={refreshPage}
          widgets={widgetList}
        />
      )}
    </>
  );
};

export default Dashboard;
