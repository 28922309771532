import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../Redux/hooks";
import { setButtonToggle } from "../../../../../../Redux/reducers/healthSlice";
import { DateTime } from "luxon";
import { AxiosResponse } from "axios";
import http from "../../../../../../utils/http";
import { calculateYMax, getGraphData } from "../../../../../../utils/journal";
import { errorToastMessage } from "../../../../../../utils/toast";
import { Box, Divider, Typography } from "@mui/material";
import { ContainerStyle, DetailStyle } from "../style";
import TemplateGraph from "./TemplateGraph";

const Activity = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { startDate, endDate } = useAppSelector((state) => state.analysis);
  const { userData } = useAppSelector((state) => state.health);
  const [yMax, setYMax] = useState<number>(0);
  const [graphData, setGraphData] = useState<any>({
    data: [
      {
        id: "activity",
        data: [],
      },
    ],
    loading: false,
    error: "",
    empty: false,
  });
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (startDate && endDate) {
          dispatch(setButtonToggle(true));
          setGraphData({
            data: [
              {
                id: "steps",
                data: [],
              },
            ],
            loading: true,
            error: "",
            empty: false,
          });

          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy HH:mm:ss");
          let end: any = DateTime.fromFormat(endDate, "LL-dd-yyyy HH:mm:ss");
          const res: AxiosResponse = await http.get(
            `/steps-log/by-user-id?page=${1}&size=${1000}&gender=${
              userData?.gender || "female"
            }&heightInInches=${userData?.height}&userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );
          const lineData = res.data?.rows;
          let steps: any[] = [];
          steps = getGraphData(lineData);

          const empty = lineData.length > 0 ? false : true;
          const yMaxValue = calculateYMax(steps);
          setYMax(yMaxValue);
          setGraphData({
            data: [
              {
                id: "activity",
                data: steps,
              },
            ],
            loading: false,
            error: "",
            empty: empty,
          });
          dispatch(setButtonToggle(false));
        } else {
          setGraphData({
            data: [
              {
                id: "steps",
                data: [],
              },
            ],
            loading: false,
            error: "",
            empty: true,
          });

          dispatch(setButtonToggle(false));
        }
      } catch (err) {
        setGraphData((prev: any) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,

            data: [
              {
                id: "steps",
                data: [],
              },
            ],
            empty: false,
          };
        });
        errorToastMessage(err as Error);
        dispatch(setButtonToggle(false));
      }
    };
    fetchDetails();
  }, [
    dispatch,
    startDate,
    endDate,
    setGraphData,
    id,
    userData?.gender,
    userData?.height,
  ]);
  return (
    <Box sx={ContainerStyle}>
      <Box sx={DetailStyle}>
        <Box>
          <Typography
            fontWeight={"medium"}
            fontSize={"22px"}
            color="#355962"
            marginTop={2}
          >
            Activity
          </Typography>
        </Box>
        <Box></Box>
        {/* <IconButton>
          <MoreVert />
        </IconButton> */}
      </Box>
      <Divider orientation="vertical" />
      <TemplateGraph
        data={graphData}
        yMax={yMax}
        yMin={0}
        threshold={500}
        startDate={startDate}
        endDate={endDate}
        color="#0895A3"
      />
    </Box>
  );
};

export default Activity;
