import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Modal,
  Table,
  TableHead,
  TableRow,
  TextField,
  TableBody,
  CircularProgress,
  Button,
  TableFooter,
  TablePagination,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../../Common/styles/modal";
import { GridSearchIcon } from "@mui/x-data-grid";
import { mealTypes } from "../Builder/TemplateBuilder";
import {
  NoDataContainer,
  StyledTableCell,
  TablePaginationStyle,
  pageSize,
  paginationLabel,
} from "../../../Common/styles/table";
import { AxiosResponse } from "axios";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

import { debounce } from "lodash";
import {
  formatValueCalories,
  formatValueGrams,
} from "../../../../utils/recipe";

const ImportTemplateModal = ({
  closeModal,
  setFieldValue,
  fieldName,
  setTemplateLoader,
  id,
}: any) => {
  const [loading, setLoading] = useState(true);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [selectedRecipe, setSelectedRecipe] = useState<any>(null);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [selectedMealTypes, setSelectedMealTypes] = useState<any[]>([]);
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let url: string = `/recipes?page=${page + 1}&size=${pageSize}`;
        const queryParams: string[] = [];

        if (search) {
          url += `&query=${search}`;
        }

        if (selectedMealTypes.length > 0) {
          queryParams.push(`query=${selectedMealTypes.join(",")}`);
        }

        if (queryParams.length > 0) {
          url += `&${queryParams.join("&")}`;
        }

        const res: AxiosResponse = await http.get(url);
        const response = res?.data?.data?.data;
        const recipeData = response?.map((recipe: any) => ({
          recipeId: recipe.id || "-",
          foodName: recipe.foodName || "-",
          energy: recipe.calories || 0,
          fat: recipe.fat || 0,
          carbs: recipe.carbs || 0,
          proteins: recipe.proteins || 0,
          sugar: recipe.suger || 0,
          fiber: recipe.fiber || 0,
        }));
        setData(recipeData);
        setTotalPageCount(res.data?.data?.pagination?.totalPages * 10 || 0);
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [search, selectedMealTypes, page]);

  const handleRowClick = (recipe: any) => {
    setSelectedRecipe(selectedRecipe === recipe ? null : recipe);
  };

  const modifySearchTerm = useMemo(
    () =>
      debounce((val) => {
        setSearch(val);
        setPage(0);
      }, 500),
    [setSearch]
  );

  const handleCheckboxChange = (mealType: any) => {
    setSelectedMealTypes((prevSelected) =>
      prevSelected.includes(mealType)
        ? prevSelected.filter((type) => type !== mealType)
        : [...prevSelected, mealType]
    );
  };
  const submitHandler = () => {
    try {
      if (!selectedRecipe) {
        toastMessage("error", "Please select a recipe");
        return;
      }
      setSubmitLoader(true);
      setTemplateLoader(true);
      setFieldValue(fieldName, {
        id: id,
        recipeId: selectedRecipe.recipeId,
        recipeName: selectedRecipe.foodName,
        energy: selectedRecipe.energy,
        protein: selectedRecipe.proteins,
        carbs: selectedRecipe.carbs,
        fats: selectedRecipe.fat,
        sugar: selectedRecipe.sugar,
        fiber: selectedRecipe.fiber,
      });
      setSubmitLoader(false);
      setTemplateLoader(false);
      closeModal();
    } catch {
      setSubmitLoader(false);
    }
  };
  const handlePageChange = (_1: any, newPage: number) => {
    setPage(newPage);
  };
  return (
    <Modal open={true} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, width: "1300px" }}>
        <ModalHeader title="Add Recipe" onCloseClick={closeModal} />
        {!loading ? (
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <TextField
                onChange={(e) => modifySearchTerm(e.target.value)}
                placeholder="Search for Recipes by Name"
                defaultValue={search}
                sx={{ width: "550px" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <GridSearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                sx={{
                  ...ModalActionButtonStyles,
                  mt: 0,
                }}
              >
                {!submitLoader ? (
                  <>
                    <Button variant="contained" onClick={submitHandler}>
                      Add
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </Box>
            <Box>
              <Box display={"flex"} mt={3} mb={3}>
                {mealTypes.map((mealType: any) => (
                  <FormControl key={mealType.value}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedMealTypes.includes(mealType.value)}
                          onChange={() => handleCheckboxChange(mealType.value)}
                        />
                      }
                      label={mealType?.label}
                    />
                  </FormControl>
                ))}
              </Box>
              <Box sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell width={"5%"}></StyledTableCell>
                      <StyledTableCell sx={{ width: "500px" }}>
                        Food Name
                      </StyledTableCell>
                      <StyledTableCell>Energy</StyledTableCell>
                      <StyledTableCell>Fat</StyledTableCell>
                      <StyledTableCell>Carbs</StyledTableCell>
                      <StyledTableCell>Proteins</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((row: any) => (
                      <TableRow
                        key={row.recipeId}
                        onClick={() => handleRowClick(row)}
                        sx={{
                          ".MuiTableCell-root": {
                            p: "12.5px 15px",
                          },
                        }}
                      >
                        <StyledTableCell width={"5%"}>
                          <Checkbox
                            checked={selectedRecipe?.recipeId === row?.recipeId}
                            icon={<CheckBoxOutlineBlank />}
                            checkedIcon={<CheckBox />}
                          />
                        </StyledTableCell>
                        <StyledTableCell sx={{ width: "500px" }}>
                          {row.foodName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatValueCalories(row.energy)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatValueGrams(row.fat)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatValueGrams(row.carbs)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {formatValueGrams(row.proteins)}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {totalPageCount > pageSize && (
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          sx={TablePaginationStyle}
                          count={totalPageCount}
                          page={page}
                          rowsPerPage={pageSize}
                          rowsPerPageOptions={[pageSize]}
                          onPageChange={handlePageChange}
                          labelDisplayedRows={paginationLabel}
                        />
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
                {!loading && data?.length === 0 && (
                  <NoDataContainer>
                    <Typography variant="body1" color="gray">
                      No Data
                    </Typography>
                  </NoDataContainer>
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ImportTemplateModal;
