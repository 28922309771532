import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { NoDataContainer } from "../../../../Common/styles/table";

const StepsDataInsights = ({ stepsInsightData, loading }: any) => {
  return (
    <Box sx={{ minHeight: "150px" }}>
      <Box
        sx={{
          padding: "16px",
          bgcolor: "#FFFFFF",
          borderRadius: "8px 8px 0 0",
          borderBottom: "1px solid #E0E3EB",
        }}
      >
        <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
          Data Insights
        </Typography>
      </Box>
      {!loading &&
        stepsInsightData &&
        stepsInsightData?.map((item: any, index: number) => (
          <Box
            key={index}
            sx={{
              m: "16px",
              height: "166px",
              borderRadius: "12px",
              border: "1px solid #E0E3EB",
            }}
          >
            <Typography
              fontWeight={"600"}
              fontSize={"14px"}
              color="#637E85"
              borderBottom="1px solid #E0E3EB"
              padding="16px"
            >
              {item?.name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "112px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  {item?.steps?.toFixed(2) || "-"}
                </Typography>
                <Typography
                  sx={{
                    color: "#637E85",
                    fontWeight: "400",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Average Steps
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  {item?.miles?.toFixed(2) || "-"}
                </Typography>
                <Typography
                  sx={{
                    color: "#637E85",
                    fontWeight: "400",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Distance (Miles)
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  {item?.km?.toFixed(2) || "-"}
                </Typography>
                <Typography
                  sx={{
                    color: "#637E85",
                    fontWeight: "400",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Distance (Km)
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      {!loading && !stepsInsightData && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default StepsDataInsights;
