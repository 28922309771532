import { ResponsivePie } from "@nivo/pie";
import { BloodGlucoseCustomTooltip } from "../../Common/UI/Tooltip";
import { Box, IconButton, Typography } from "@mui/material";
import { commonContainerWrapper } from "../../Common/styles/flex";
import { MoreVert } from "@mui/icons-material";
import { NoDataContainer } from "../../Common/styles/table";

type Props = {
  data: any;
  handleOptionsClick: (e: any, data: any) => void;
};
const BloodGlucosePiechart = ({ data, handleOptionsClick }: Props) => {
  return (
    <Box
      sx={{
        ...commonContainerWrapper,
        mb: 2,
        minWidth: "500px",
        maxWidth: "50%",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography fontSize={30} fontWeight={700} color="text.primary">
          {data?.title}
        </Typography>
        <Box>
          <IconButton onClick={(e) => handleOptionsClick(e, data)}>
            <MoreVert />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          height: data?.data?.length > 0 ? "300px" : "150px",
          width: "450px",
          my: "50px",
        }}
      >
        {data?.data?.length > 0 ? (
          <ResponsivePie
            data={data?.data}
            margin={{ top: 0, right: 80, bottom: 0, left: 0 }}
            enableArcLinkLabels={false}
            colors={["#70AE71", "#F49D4C", "#FF7164"]}
            endAngle={-360}
            valueFormat={(value: any) => `${value}%`}
            arcLabelsSkipAngle={3}
            innerRadius={0.56}
            theme={{
              labels: {
                text: {
                  fontSize: 16,
                  fill: "#FFFFFF",
                },
              },
              legends: {
                text: {
                  fontSize: 14,
                  fontWeight: 400,
                  fill: "#111928",
                },
              },
            }}
            legends={[
              {
                anchor: "right",
                direction: "column",
                justify: false,
                translateX: 80,
                translateY: 0,
                itemsSpacing: 16,
                itemWidth: 75,
                itemHeight: 15,
                itemTextColor: "#111928",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 12,
                symbolShape: "circle",
              },
            ]}
            tooltip={(value: any) => (
              <BloodGlucoseCustomTooltip
                value={value?.datum?.value}
                color={value?.datum?.color}
                label={value?.datum?.label}
              />
            )}
          />
        ) : (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
      </Box>
    </Box>
  );
};
export default BloodGlucosePiechart;
