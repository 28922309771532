import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import {
  Box,
  LinearProgress,
  SxProps,
  Table,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import {
  NoDataContainer,
  StyledTableCell,
  TableLayoutStyle,
} from "../../../../Common/styles/table";
import { DateTime } from "luxon";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import { commonContainerWrapper } from "../../../../Common/styles/flex";
import StepsGraph from "./StepsGraph";
import { transFormBarGraphData } from "../../../../../utils/health";
import StepsDataInsights from "./StepsDataInsights";
import { setButtonToggle } from "../../../../../Redux/reducers/healthSlice";

interface StepsMetrics {
  dateLabel: string;
  x1: string;
  y1: number | null;
  steps: number | string;
}
const StyledText: SxProps = {
  fontWeight: 600,
  fontSize: "12px",
  color: "#637E85",
};
const StyledStepCount: SxProps = {
  fontWeight: 600,
  fontSize: "18px",
  color: "#000000",
};

const calculateYMax = (data: any) => {
  const yValues = data.map((point: any) => point.y1);
  const maxY = Math.max(...yValues);
  return maxY;
};

const Steps = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { startDate, endDate, criteria, userData } = useAppSelector(
    (state) => state.health
  );

  const [yMax, setYMax] = useState<number>(0);
  const [data, setData] = useState<StepsMetrics[]>([]);
  const [stepsInsightData, setStepsInsightData] = useState<any>();
  const [graphData, setGraphData] = useState<any>({
    data: [
      {
        id: "steps",
        data: [],
      },
    ],
    loading: false,
    criteria: criteria,
    error: "",
    empty: false,
  });

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (startDate && endDate) {
          dispatch(setButtonToggle(true));
          setGraphData({
            data: [
              {
                id: "steps",
                data: [],
              },
            ],
            loading: true,
            criteria: criteria,
            error: "",
            empty: false,
          });

          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf("day");
          const res: AxiosResponse = await http.get(
            `/steps-log/by-user-id?page=${1}&size=${1000}&gender=${
              userData?.gender || "female"
            }&heightInInches=${userData?.height}&userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );
          const lineData = res.data?.rows;
          const stpIns = res.data?.inSight;
          setStepsInsightData([
            { ...stpIns?.avg, name: "Average" },
            { ...stpIns?.max, name: "High" },
            { ...stpIns?.min, name: "Low" },
            { ...stpIns?.total, name: "Total" },
          ]);
          const stepsObj: {
            [key: string]: { y: number; steps: number | string };
          } = {};
          lineData.forEach((day: any) => {
            const date = day?.createdAt?.split("T")?.[0];
            if (date) {
              stepsObj[date] = {
                y: day.value,
                steps: day.value || "-",
              };
            }
          });
          const isEmpty = Object.keys(stepsObj).length === 0;
          const n = end.diff(start, "days").get("days");
          const newData: any = [];
          for (let i = 0; i < n; i++) {
            const key = end.toFormat("yyyy-LL-dd");
            newData.push({
              dateLabel: end.toFormat("LLLL dd"),
              x1: end.toFormat("dd/LL/yyyy"),
              y1: stepsObj[key] ? stepsObj[key].y : null,
              steps: stepsObj[key] ? stepsObj[key].steps : "-",
            });
            end = end.plus({
              day: -1,
            });
          }
          const yMaxValue = calculateYMax(newData);
          setYMax(yMaxValue);
          const newData1: any = [];
          for (let i = newData.length - 1; i >= 0; i--) {
            newData1.push(newData[i]);
          }
          const stepsChartData = transFormBarGraphData(newData1);
          setGraphData({
            data: isEmpty
              ? [
                  {
                    id: "steps",
                    data: [],
                  },
                ]
              : [
                  {
                    id: "steps",
                    data: stepsChartData,
                  },
                ],
            loading: false,
            criteria: criteria,
            error: "",
            empty: isEmpty ? true : false,
          });
          setData(newData1);
          dispatch(setButtonToggle(false));
        } else {
          setGraphData({
            data: [
              {
                id: "steps",
                data: [],
              },
            ],
            loading: false,
            criteria: criteria,
            error: "",
            empty: true,
          });

          dispatch(setButtonToggle(false));
        }
      } catch (err) {
        setGraphData((prev: any) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            criteria: criteria,

            data: [
              {
                id: "steps",
                data: [],
              },
            ],
            empty: false,
          };
        });
        errorToastMessage(err as Error);
        dispatch(setButtonToggle(false));
      }
    };
    fetchDetails();
  }, [
    dispatch,
    startDate,
    endDate,
    setGraphData,
    setData,
    id,
    userData?.gender,
    userData?.height,
    criteria,
  ]);

  return (
    <>
      <Box sx={{ ...commonContainerWrapper, mb: 2 }}>
        <StepsGraph data={graphData} criteria={criteria} yMax={yMax} />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
            mb: 3,
            width: "60%",
          }}
        >
          <Box
            sx={{
              padding: "16px",
              bgcolor: "#FFFFFF",
              borderRadius: "8px 8px 0 0",
            }}
          >
            <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
              Steps Data
            </Typography>
          </Box>
          <Box>
            <Table
              sx={{
                borderTop: "1px solid #E0E3EB",
              }}
            >
              {/* <TableHead sx={{ ...TableLayoutStyle }}>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell>Minutes Steps</StyledTableCell>
              </TableRow>
            </TableHead> */}
              <TableBody>
                {!graphData?.loading &&
                  !graphData?.empty &&
                  data?.length > 0 &&
                  data?.map((item: any) => (
                    <TableRow
                      key={item?.dateLabel}
                      sx={{
                        ...TableLayoutStyle,
                        //   ".MuiTableCell-root": {
                        //     py: "7px",
                        //   },
                      }}
                    >
                      <StyledTableCell sx={{ color: "#637E85" }}>
                        {item?.dateLabel}
                      </StyledTableCell>
                      <StyledTableCell sx={{ color: "#637E85" }}>
                        {item?.steps === "-" ? (
                          "-"
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              alignItems: "center",
                            }}
                          >
                            <Typography sx={StyledStepCount}>
                              {item?.steps}
                            </Typography>
                            <Typography sx={StyledText}>Steps</Typography>
                          </Box>
                        )}
                      </StyledTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {!graphData?.loading && graphData?.empty && (
              <NoDataContainer>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            )}
            {graphData?.loading && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
            mb: 3,
            width: "40%",
          }}
        >
          <StepsDataInsights
            stepsInsightData={stepsInsightData}
            loading={graphData?.loading}
          />
        </Box>
      </Box>
    </>
  );
};

export default Steps;
