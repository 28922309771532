import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { NoDataContainer } from "../../../../../Common/styles/table";
import { LoadingContainer } from "../../../../../Cloud/cloud.style";

const BloodPressureDataInsights = ({ insightData, loading }: any) => {
  return (
    <Box sx={{ minHeight: "150px" }}>
      <Box
        sx={{
          padding: "16px",
          bgcolor: "#FFFFFF",
          borderRadius: "8px 8px 0 0",
          borderBottom: "1px solid #E0E3EB",
        }}
      >
        <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
          Data Insights
        </Typography>
      </Box>
      {!loading && insightData && (
        <>
          <Box
            sx={{
              m: "16px",
              height: "166px",
              borderRadius: "12px",
              border: "1px solid #E0E3EB",
            }}
          >
            <Typography
              fontWeight={"600"}
              fontSize={"14px"}
              color="#637E85"
              borderBottom="1px solid #E0E3EB"
              padding="16px"
            >
              Average Values
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "112px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  {insightData?.average?.systolicAverage?.toFixed(2) || "-"}
                </Typography>
                <Typography
                  sx={{
                    color: "#637E85",
                    fontWeight: "400",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Systolic (mmHg)
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  {insightData?.average?.diastolicAverage?.toFixed(2) || "-"}
                </Typography>
                <Typography
                  sx={{
                    color: "#637E85",
                    fontWeight: "400",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Diastolic (mmHg)
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              m: "16px",
              height: "166px",
              borderRadius: "12px",
              border: "1px solid #E0E3EB",
            }}
          >
            <Typography
              fontWeight={"600"}
              fontSize={"14px"}
              color="#637E85"
              borderBottom="1px solid #E0E3EB"
              padding="16px"
            >
              Highest Values
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "112px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  {insightData?.systolic?.high || "-"}
                </Typography>
                <Typography
                  sx={{
                    color: "#637E85",
                    fontWeight: "400",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Systolic max (mmHg)
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  {insightData?.diastolic?.high || "-"}
                </Typography>
                <Typography
                  sx={{
                    color: "#637E85",
                    fontWeight: "400",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Diastolic max (mmHg)
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              m: "16px",
              height: "166px",
              borderRadius: "12px",
              border: "1px solid #E0E3EB",
            }}
          >
            <Typography
              fontWeight={"600"}
              fontSize={"14px"}
              color="#637E85"
              borderBottom="1px solid #E0E3EB"
              padding="16px"
            >
              Lowest Values
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "112px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  {insightData?.systolic?.low || "-"}
                </Typography>
                <Typography
                  sx={{
                    color: "#637E85",
                    fontWeight: "400",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Systolic min (mmHg)
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  {insightData?.systolic?.low || "-"}
                </Typography>
                <Typography
                  sx={{
                    color: "#637E85",
                    fontWeight: "400",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Diastolic min (mmHg)
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {!loading && !insightData && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ ...LoadingContainer, mt: "5%" }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default BloodPressureDataInsights;
