import {
  Box,
  Button,
  CircularProgress,
  Divider,
  SxProps,
  Typography,
} from "@mui/material";
import { StyledTab, StyledTabs, a11yProps } from "../../Common/UI/TabPanel";
import { useAppDispatch, useAppSelector } from "../../../Redux/hooks";
import {
  setDateLoaded,
  setHealthType,
} from "../../../Redux/reducers/healthSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AssignQuestionnaireModal from "./Block/Questionnaire/QuestionBlock/AssignQuestionnaireModal";
import AddIcon from "@mui/icons-material/Add";
import AssociateFoodMeal from "./Block/Food/AssociateFoodMeal";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { DownloadIcon } from "../../Common/assets/Icons";

const Container: SxProps = {
  p: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "8px 8px 0px 0px",
  backgroundColor: "#ffffff",
};

const tabs = [
  {
    key: "patient_overview",
    label: "Patient Overview",
  },
  // {
  //   key: "health",
  //   label: "Health",
  // },
  {
    key: "blood_glucose",
    label: "Blood Glucose",
  },
  {
    key: "blood_pressure",
    label: "Blood Pressure",
  },
  {
    key: "heart_rate",
    label: "Heart Rate",
  },
  {
    key: "steps",
    label: "Activity",
  },
  {
    key: "weight",
    label: "Weight",
  },
  {
    key: "food",
    label: "Nutrition",
  },
  {
    key: "medication",
    label: "Medication",
  },
  {
    key: "sleep",
    label: "Sleep",
  },
  {
    key: "spo2",
    label: "SpO2",
  },
  {
    key: "respiration",
    label: "Respiratory Rate",
  },

  {
    key: "temperature",
    label: "Temperature",
  },
  {
    key: "questionnaire",
    label: "Questionnaire",
  },
  // {
  //   key: "onboarding",
  //   label: "Onboarding Questionnaire",
  // },
  {
    key: "exercise",
    label: "Exercises",
  },
  {
    key: "learn",
    label: "Learn",
  },
  {
    key: "hip",
    label: "Hip",
  },
  {
    key: "waist",
    label: "Waist",
  },
  {
    key: "water",
    label: "Water",
  },
  // {
  //   key: "pkg_summary",
  //   label: "PKG Summary",
  // },
  // {
  //   key: "exercise_analysis",
  //   label: "Exercise Analysis",
  // },
  // {
  //   key: "daily_log",
  //   label: "Daily Log",
  // },
  // {
  //   key: "symptoms",
  //   label: "Symptoms",
  // },
  // {
  //   key: "tests",
  //   label: "Test",
  // },
  // {
  //   key: "reports",
  //   label: "Reports",
  // },
];

const coachTabs = [
  {
    key: "patient_overview",
    label: "Patient Overview",
  },
  {
    key: "health",
    label: "Health",
  },
  {
    key: "food",
    label: "Food",
  },
  {
    key: "medication",
    label: "Medication",
  },
  {
    key: "sleep",
    label: "Sleep",
  },
  {
    key: "steps",
    label: "Steps",
  },
  {
    key: "questionnaire",
    label: "Questionnaire",
  },
  {
    key: "exercise",
    label: "Exercises",
  },
  {
    key: "messages",
    label: "Messages",
  },
  {
    key: "learn",
    label: "Learn",
  },
  {
    key: "hip",
    label: "Hip",
  },
  {
    key: "waist",
    label: "Waist",
  },
  {
    key: "weight",
    label: "Weight",
  },
  {
    key: "water",
    label: "Water",
  },
];

const HealthTabs: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  // const [downloadModal, setDownloadModal] = useState<any>({
  //   show: false,
  //   userId: "",
  // });
  const { type } = useAppSelector((state) => state.health);
  const { hasHealthCoachAccess } = useAppSelector((state) => state.user);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showFoodModal, setShowFoodModal] = useState<boolean>(false);
  const [mealPlan, setMealPlan] = useState("");
  const [mealPlanLoading, setMealPlanLoading] = useState(false);
  const [toggle, setToggle] = useState(false);

  const tabChange = (_1: any, val: string) => {
    if (
      val === "patient_overview" ||
      val === "chat" ||
      val === "exercise_analysis"
    ) {
      dispatch(setHealthType(val));
    } else {
      dispatch(setHealthType(val));
      dispatch(setDateLoaded(false));
    }
  };

  const handleNavigate = () => {
    navigate(`/app/participants/${id}/prescription`);
  };
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const openFoodModal = () => {
    setShowFoodModal(true);
  };
  const closeFoodModal = () => {
    setShowFoodModal(false);
  };

  const refreshPage = () => {
    setToggle((prev) => !prev);
  };

  useEffect(() => {
    const fetchMealData = async () => {
      try {
        setMealPlanLoading(true);
        const mealPlanRes: AxiosResponse = await http.get(
          `/usermealplans/${id}`
        );
        const mealRes = mealPlanRes?.data;

        if (mealRes[mealRes?.length - 1]?.mealPlanId) {
          const newMealPlanRes: AxiosResponse = await http.get(
            `/meal-plan/${mealRes[mealRes?.length - 1]?.mealPlanId}`
          );

          const newMealRes = newMealPlanRes?.data?.data;
          setMealPlan(newMealRes?.mealName);
        }
        setMealPlanLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setMealPlanLoading(false);
      }
    };
    fetchMealData();
  }, [toggle, id]);

  const toggleDownload = async (userId: any) => {
    try {
      setDownloadLoading(true);
      const res: AxiosResponse = await http.get(
        `/user-details/download?userId=${userId}&tables=all`,
        { responseType: "blob" }
      );
      const blob = new Blob([res.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `user_data_${userId}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
      toastMessage("success", res.data.message);
    } catch (err) {
      errorToastMessage(err as Error);
    } finally {
      setDownloadLoading(false);
    }
  };

  // const toggleDownloadModal = (userId: string) => {
  //   setDownloadModal({
  //     show: true,
  //     userId: userId,
  //   });
  // };

  return (
    <>
      <Box sx={Container}>
        <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
          Health
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          {type === "food" && (
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              {mealPlanLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress size={25} />
                </Box>
              ) : (
                <Typography
                  variant="body1"
                  color="#6B7280"
                  fontWeight="regular"
                >
                  {mealPlan}
                </Typography>
              )}
              <Button
                variant="contained"
                onClick={openFoodModal}
                startIcon={<AddIcon />}
              >
                Assign Meal Plan
              </Button>
            </Box>
          )}
          {type === "medication" && (
            <Button variant="contained" onClick={handleNavigate}>
              Prescribe Medication
            </Button>
          )}
          {type === "questionnaire" && (
            <Button
              variant="contained"
              onClick={openModal}
              startIcon={<AddIcon />}
            >
              Assign Questionnaire
            </Button>
          )}
          {type === "patient_overview" && (
            <>
              {!downloadLoading ? (
                <Button
                  onClick={() => toggleDownload(id)}
                  variant="outlined"
                  disabled={downloadLoading}
                  startIcon={<DownloadIcon />}
                >
                  Download
                </Button>
              ) : (
                <CircularProgress size={32} />
              )}
            </>
          )}
        </Box>
      </Box>
      <Divider sx={{ color: "#E5E7EB" }} />
      <StyledTabs value={type} onChange={tabChange}>
        {(hasHealthCoachAccess ? coachTabs : tabs).map((tab, index) => (
          <StyledTab
            key={tab.key}
            label={tab.label}
            value={tab.key}
            {...a11yProps(index)}
          />
        ))}
      </StyledTabs>
      {showModal && (
        <AssignQuestionnaireModal
          showModal={showModal}
          closeModal={closeModal}
        />
      )}
      {showFoodModal && (
        <AssociateFoodMeal
          showModal={showFoodModal}
          closeModal={closeFoodModal}
          refreshPage={refreshPage}
        />
      )}
    </>
  );
};

export default HealthTabs;
