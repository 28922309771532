import { Box } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { DateTime } from "luxon";
import ChartLoader from "../ChartLoader";
const colors: any = {
  Carbs: "#FF5A1F",
  Proteins: "#7E3AF2",
  Fat: "#1C64F2",
  Fiber: "#047481",
};

const FoodGraph = ({ data, criteria, yMax }: any) => {
  const dataColors = data?.data?.map((item: any) => colors[item.id]);
  const legendItems = [
    { id: "carbs", label: "Carbs", color: colors.Carbs },
    { id: "proteins", label: "Protein", color: colors.Proteins },
    { id: "fat", label: "Fats", color: colors.Fat },
    { id: "fiber", label: "Fiber", color: colors.Fiber },
  ];

  return (
    <Box sx={{ height: "360px" }}>
      <ResponsiveLine
        data={data?.data}
        margin={{ top: 20, right: 15, bottom: 70, left: 65 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: 0,
          max: yMax > 100 ? yMax : 100,
          clamp: true,
        }}
        enableGridX={false}
        enablePoints={true}
        pointSize={13}
        pointColor={"#FFFFFF"}
        pointBorderWidth={2}
        pointBorderColor={({ serieId }: any) => colors[serieId]}
        yFormat=" >-.2f"
        colors={dataColors}
        lineWidth={3}
        axisTop={null}
        axisRight={null}
        layers={[
          "grid",
          "markers",
          "axes",
          // "areas",
          // "crosshair",
          "lines",
          "points",
          "mesh",
          "legends",
          ChartLoader(data?.loading, data?.error, data?.empty, "#70AE71"),
        ]}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legendOffset: 60,
          legend: "Date",
          legendPosition: "middle",
          tickValues:
            criteria === "month"
              ? data?.[0]?.data
                  ?.map((v: any, i: number) => {
                    const divider = 5;
                    if (i % divider === 0) return v?.x;
                    else return null;
                  })
                  .filter((v: any) => v)
              : undefined,
          format: (d) => {
            let dayOfWeek: any = d;
            if (criteria === "week") {
              const date = DateTime.fromFormat(d, "dd/MM/yyyy");
              dayOfWeek = date.toFormat("EEE");
            } else if (criteria === "month") {
              const date = DateTime.fromFormat(d, "dd/MM/yyyy");
              dayOfWeek = date.toFormat("d");
            }
            return dayOfWeek;
          },
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 15,
          tickRotation: 0,
          legendOffset: -50,
          legend: "Nutrition",
          legendPosition: "middle",
          renderTick: ({ x, y, rotate, textAnchor, value }) => (
            <text
              x={x}
              y={y}
              fill="#355962"
              textAnchor={textAnchor}
              dominantBaseline="middle"
              transform={`translate(-15 ,2) rotate(${rotate})`}
              fontSize={14}
              fontWeight={500}
            >
              {value}
            </text>
          ),
        }}
        theme={{
          fontSize: 14,
          grid: {
            line: {
              strokeDasharray: "6 6",
            },
          },
          axis: {
            ticks: {
              text: {
                fontSize: 14,
                fontWeight: 550,
                fill: "#355962",
              },
            },
            domain: {
              line: {
                stroke: "#E0E3EB",
              },
            },
            legend: {
              text: {
                fill: "#9CA3AF",
                fontSize: 14,
                fontWeight: 500,
              },
            },
          },
          legends: {
            text: {
              fontSize: 16,
              fontWeight: 600,
              fill: "#637E85",
            },
          },
        }}
        isInteractive={true}
        useMesh={true}
        legends={[
          {
            anchor: "bottom-right",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: 70,
            itemsSpacing: 10,
            itemDirection: "left-to-right",
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
            data: legendItems.map((item) => ({
              id: item.id,
              label: item.label,
              color: item.color,
            })),
          },
        ]}
      />
    </Box>
  );
};

export default FoodGraph;
