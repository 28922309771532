import React, { useEffect, useState } from "react";
import { Box, IconButton, SxProps } from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import ChartLoader from "../ChartLoader";
import { DateTime } from "luxon";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import { CustomTooltipLineGraph } from "../../../../Common/UI/Tooltip";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import Spo2Insights from "./Spo2Insights/Spo2Insights";
import Spo2DataInsights from "./Spo2Insights/Spo2DataInsights";
import { setButtonToggle } from "../../../../../Redux/reducers/healthSlice";

const container: SxProps = {
  borderRadius: "12px",
  backgroundColor: "#ffffff",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: 3,
  height: "450px",
  width: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};

const Spo2 = () => {
  const { id } = useParams();
  const { startDate, endDate, criteria } = useAppSelector(
    (state) => state.health
  );
  const [spo2InsightData, setSpo2InsightData] = useState<any>();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<Boolean>(true);
  const [data, setData] = useState<any>({
    data: [
      {
        id: "spo2",
        data: [],
      },
    ] as any,
    loading: false,
    error: "",
    tableData: [],
    empty: false,
    criteria: criteria,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          dispatch(setButtonToggle(true));

          setData({
            data: [
              {
                id: "spo2",
                data: [],
              },
            ] as any,
            loading: true,
            error: "",
            empty: false,
            criteria: criteria,
          });
          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end: any;
          if (criteria === "day") {
            end = DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf("day");
          } else {
            end = DateTime.fromFormat(endDate, "LL-dd-yyyy").startOf("day");
          }

          const res: AxiosResponse = await http.get(
            `/spo2-log/by-user-id?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
            // &page=${1}&size=${1000}`
          );
          const spo2Data = res?.data?.rows?.rows;
          setSpo2InsightData(res.data?.inSight);
          let spo2Table: any = [];
          spo2Table = spo2Data.map((item: any) => ({
            dateLabel: DateTime.fromISO(item?.createdAt).toFormat(
              "LLLL dd yyyy"
            ),
            time: DateTime.fromISO(item?.createdAt).toFormat("hh:mm a"),
            value: item.value,
          }));

          let spo2: any = [];
          spo2Data.forEach((item: any) => {
            spo2.push({
              x: DateTime.fromISO(item?.createdAt).toFormat(
                "yyyy-LL-dd hh:mm a"
              ),
              y: item.value,
            });
          });
          const empty = spo2.length > 0 ? false : true;
          setLoading(false);
          setData({
            data: [
              {
                id: "spo2",
                data: spo2,
              },
            ],
            loading: false,
            error: "",
            empty: empty,
            tableData: spo2Table,
            criteria: criteria,
          });
          dispatch(setButtonToggle(false));
        } else {
          setLoading(false);
          dispatch(setButtonToggle(false));

          setData({
            data: [
              {
                id: "spo2",
                data: [],
              },
            ] as any,
            loading: false,
            error: "",
            empty: true,
            tableData: [],
            criteria: criteria,
          });
        }
      } catch (err) {
        setLoading(false);
        dispatch(setButtonToggle(false));

        setData((prev: any) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            data: [
              {
                id: "spo2",
                data: [],
              },
            ],
            empty: false,
            tableData: [],
            criteria: criteria,
          };
        });
        errorToastMessage(err as Error);
      }
    };
    fetchData();
  }, [dispatch, id, startDate, endDate, criteria]);
  // const handleSwipe = (offSet: number) => {
  //   let newStart: any = "";
  //   let newEnd: any = "";
  //   if (startDate && endDate) {
  //     if (criteria === "day") {
  //       newStart = DateTime.fromFormat(startDate || "", "LL-dd-yyyy")
  //         .plus({ days: offSet })
  //         .toFormat("LL-dd-yyyy");
  //       newEnd = DateTime.fromFormat(endDate || "", "LL-dd-yyyy")
  //         .plus({ days: offSet })
  //         .toFormat("LL-dd-yyyy");
  //     } else if (criteria === "week") {
  //       newStart = DateTime.fromFormat(startDate || "", "LL-dd-yyyy")
  //         .plus({ weeks: offSet })
  //         .toFormat("LL-dd-yyyy");
  //       newEnd = DateTime.fromFormat(endDate || "", "LL-dd-yyyy")
  //         .plus({ weeks: offSet })
  //         .toFormat("LL-dd-yyyy");
  //     } else if (criteria === "month") {
  //       newStart = DateTime.fromFormat(startDate || "", "LL-dd-yyyy")
  //         .plus({ months: offSet })
  //         .toFormat("LL-dd-yyyy");
  //       newEnd = DateTime.fromFormat(endDate || "", "LL-dd-yyyy")
  //         .plus({ months: offSet })
  //         .toFormat("LL-dd-yyyy");
  //     }
  //     console.log(newStart, newEnd);
  //     if (DateTime.fromFormat(newEnd, "LL-dd-yyyy") < DateTime.now()) {
  //       dispatch(setHealthEndDate(newEnd));
  //       dispatch(setHealthStartDate(newStart));
  //     }
  //   }
  // };

  return (
    <>
      <Box sx={container}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            minHeight: "1px",
            py: 4,
            px: 1,
            overflowX: "scroll",
          }}
        >
          {/* <Box>
          <IconButton onClick={() => handleSwipe(-1)}>
            <ChevronLeft />
          </IconButton>
        </Box> */}
          <ResponsiveScatterPlot
            data={data?.data}
            margin={{ top: 20, right: 60, bottom: 90, left: 60 }}
            xScale={{
              // format: "%I:%M %p",
              format: "%Y-%m-%d %I:%M %p",
              precision: "minute",
              type: "time",
              useUTC: false,
            }}
            yScale={{
              type: "linear",
              min: 80,
              max: 100,
            }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              format:
                criteria === "week"
                  ? "%a"
                  : criteria === "day"
                  ? "%I %p"
                  : "%e",
              tickValues:
                data?.empty || data.loading
                  ? [
                      DateTime.fromFormat(
                        startDate || "",
                        "LL-dd-yyyy"
                      ).toFormat("LL-dd-yyyy"),
                    ]
                  : data?.criteria === "day"
                  ? "every 2 hours"
                  : data?.criteria === "week"
                  ? "every 1 day"
                  : "every 5 days",
              legend: "Time",
              legendPosition: "middle",
              legendOffset: 80,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 15,
              tickRotation: 0,
              legendOffset: -50,
              legend: "spO2",
              legendPosition: "middle",
            }}
            theme={{
              fontFamily: "Poppins, sans-serif",
              fontSize: 14,
              axis: {
                ticks: {
                  text: {
                    fontSize: 14,
                    fontWeight: 500,
                    fill: "#355962",
                  },
                },
                legend: {
                  text: {
                    fill: "#9CA3AF",
                    fontWeight: 500,
                    fontSize: 14,
                  },
                },
              },
            }}
            colors={["#327091"]}
            nodeSize={10}
            useMesh={true}
            layers={[
              "grid",
              "axes",
              "nodes",
              "markers",
              ChartLoader(data?.loading, data?.error, data?.empty, "#70AE71"),
            ]}
            tooltip={(value: any) => {
              const date = value?.node?.data?.x;
              let label =
                DateTime.fromJSDate(date).toFormat("dd/LL/yyyy hh:mm a");

              return (
                <CustomTooltipLineGraph
                  value={value?.node?.data?.y}
                  color={value?.node?.color}
                  label={label}
                />
              );
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
            mb: 3,
            width: "60%",
          }}
        >
          <Spo2Insights tableData={data?.tableData} loading={loading} />
        </Box>
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
            mb: 3,
            width: "40%",
          }}
        >
          <Spo2DataInsights
            spo2InsightData={spo2InsightData}
            loading={loading}
          />
        </Box>
      </Box>
    </>
  );
};

export default Spo2;
