import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../../../Redux/hooks";
import { AxiosResponse } from "axios";
import { DateTime } from "luxon";
import { setLoading } from "../../../../../../Redux/reducers/cmsBuilderSlice";
import { setButtonToggle } from "../../../../../../Redux/reducers/healthSlice";
import http from "../../../../../../utils/http";
import { calculateYMax, getGraphData } from "../../../../../../utils/journal";
import { errorToastMessage } from "../../../../../../utils/toast";
import { Box, Divider, Typography } from "@mui/material";
import { ContainerStyle, DetailStyle } from "../style";
import TemplateGraph from "./TemplateGraph";

const Temperature = () => {
  const { id } = useParams();
  const { startDate, endDate } = useAppSelector((state) => state.analysis);
  const [yMax, setYMax] = useState<number>(0);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>({
    data: [
      {
        id: "temperature",
        data: [],
      },
    ] as any,
    loading: false,
    error: "",
    empty: false,
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (startDate && endDate) {
          dispatch(setButtonToggle(true));

          setData({
            data: [
              {
                id: "temperature",
                data: [],
              },
            ] as any,
            loading: true,
            error: "",
            empty: false,
          });
          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy HH:mm:ss");
          let end: any = DateTime.fromFormat(endDate, "LL-dd-yyyy HH:mm:ss");
          const res: AxiosResponse = await http.get(
            `/temperature-log/by-user-id?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
            // &page=${1}&size=${1000}
          );
          const tempData = res.data?.rows?.rows;

          let temp: any = [];
          temp = getGraphData(tempData);
          const empty = tempData.length > 0 ? false : true;
          setLoading(false);
          setData({
            data: [
              {
                id: "temperature",
                data: temp,
              },
            ],
            loading: false,
            error: "",
            empty: empty,
          });
          const yMax = calculateYMax(temp);
          setYMax(yMax);
          dispatch(setButtonToggle(false));
        } else {
          setLoading(false);
          setData({
            data: [
              {
                id: "temperature",
                data: [],
              },
            ] as any,
            loading: false,
            error: "",
            empty: true,
          });
          setYMax(0);
          dispatch(setButtonToggle(false));
        }
      } catch (err) {
        setLoading(false);
        dispatch(setButtonToggle(false));

        setData((prev: any) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            data: [
              {
                id: "temperature",
                data: [],
              },
            ],
            empty: false,
          };
        });
        errorToastMessage(err as Error);
      }
    };

    fetchData();
  }, [dispatch, id, startDate, endDate]);
  return (
    <Box sx={ContainerStyle}>
      <Box sx={DetailStyle}>
        <Box>
          <Typography
            fontWeight={"medium"}
            fontSize={"22px"}
            color="#355962"
            marginTop={2}
          >
            Temperature
          </Typography>
          <Typography
            color="#678188"
            fontSize={"14px"}
            fontWeight={"medium"}
            marginTop={1}
          >
            Farenheit
          </Typography>
        </Box>
        <Box></Box>
        {/* <IconButton>
          <MoreVert />
        </IconButton> */}
      </Box>
      <Divider orientation="vertical" />
      <TemplateGraph
        data={data}
        yMin={96}
        yMax={yMax}
        threshold={104}
        startDate={startDate}
        endDate={endDate}
        color={"#4E8CF8"}
      />
    </Box>
  );
};

export default Temperature;
