import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { errorToastMessage } from "../../../utils/toast";
import { sanitizeQuestions } from "../../../utils/onboarding";
import http from "../../../utils/http";
import { StyledHeader } from "../../Common/styles/header";
import { ChevronLeft } from "@mui/icons-material";
import { NoDataContainer } from "../../Common/styles/table";
import { QuestionItem } from "./QuestionItem";
import { useAppSelector } from "../../../Redux/hooks";
import {
  canModifyDailyLog,
  canModifyOnboarding,
  canModifySymptoms,
} from "../../../utils/roles";

const OnboardingPreview: React.FC<{ type: string }> = ({ type }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>({});
  // const [toggle, setToggle] = useState(false);
  const [error, setError] = useState("");
  // const [menuLoader, setMenuLoader] = useState<boolean>(false);
  const userRole = useAppSelector((state) => state.user.role);
  const [hasModifyAccess, setModifyAccess] = useState(false);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let name = "";
        const path = window.location.pathname;
        if (path.startsWith("/app/onboarding")) {
          name = "Onboarding Builder";
          const canModify = canModifyOnboarding(userRole);
          setModifyAccess(canModify);
        } else if (path.startsWith("/app/daily-log")) {
          name = "Daily Log Builder";
          const canModify = canModifyDailyLog(userRole);
          setModifyAccess(canModify);
        } else if (path.startsWith("/app/symptoms")) {
          name = "Symptoms Log Builder";
          const canModify = canModifySymptoms(userRole);
          setModifyAccess(canModify);
        }
        const status = "draft";
        const res = await http.get(`/onboarding/bots?type=${type}`);
        const id = res.data.data.id;
        const questions = sanitizeQuestions(res.data.data.steps);
        setData({ questions: questions, id: id, type, name, status });
        setLoading(false);
        setError("");
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
        setError("Something Went Wrong");
      }
    };
    fetchDetails();
  }, [
    // toggle,
    setData,
    setLoading,
    setError,
    userRole,
    type,
  ]);

  const handleBack = () => {
    navigate(-1);
  };

  // const publish = async () => {
  //   try {
  //     setMenuLoader(true);
  //     let url = "";
  //     const path = window.location.pathname;
  //     if (path.startsWith("/app/daily-log")) {
  //       url = `/daily-log/${id}`;
  //     } else if (path.startsWith("/app/symptoms")) {
  //       url = `/symptoms/${id}`;
  //     }
  //     const res: AxiosResponse = await http.patch(url, {
  //       status: "active",
  //     });
  //     toastMessage("success", res.data.message);
  //     setMenuLoader(false);
  //     setToggle((prev) => !prev);
  //   } catch (err) {
  //     errorToastMessage(err as Error);
  //     setMenuLoader(false);
  //   }
  // };

  const edit = () => {
    const path = window.location.pathname;
    if (path.startsWith("/app/onboarding")) {
      navigate("/app/onboarding/builder");
    } else if (path.startsWith("/app/daily-log")) {
      navigate(`/app/daily-log/builder`);
    } else if (path.startsWith("/app/symptoms")) {
      navigate(`/app/symptoms/builder`);
    }
  };

  return (
    <Box sx={{ height: "100%" }}>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <StyledHeader>
        <IconButton onClick={handleBack}>
          <ChevronLeft fontSize="large" htmlColor="#111928" />
        </IconButton>
        <Typography fontSize={24} fontWeight="bold">
          {data?.name || "Preview"}
        </Typography>
        <span style={{ marginLeft: "auto" }}></span>
        {hasModifyAccess && (
          <>
            {data?.status === "draft" && (
              <>
                <Button variant="contained" onClick={edit}>
                  Edit
                </Button>
                {/* {data?.type === "onboarding" &&
              (menuLoader ? (
                <Box sx={{ ml: 1 }}>
                  <CircularProgress size={25} />
                </Box>
              ) : (
                <Button variant="contained" onClick={publish} sx={{ ml: 1 }}>
                  Publish
                </Button>
              ))} */}
              </>
            )}
          </>
        )}
      </StyledHeader>
      <Box sx={{ py: 2 }}>
        {error ? (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              {error}
            </Typography>
          </NoDataContainer>
        ) : data?.questions?.length ? (
          data?.questions?.map((question: any) => {
            return <QuestionItem key={question.id} question={question} />;
          })
        ) : (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Steps Present
            </Typography>
          </NoDataContainer>
        )}
      </Box>
    </Box>
  );
};

export default OnboardingPreview;
