import React from "react";
import { Box } from "@mui/material";
import { DateTime } from "luxon";
import { ResponsiveBar } from "@nivo/bar";
import { calculatePadding } from "../../../../../utils/recipe";
import ChartLoader from "../ChartLoader";

const StepsGraph = ({ data, criteria, yMax }: any) => {
  const padding = calculatePadding(data?.data[0]?.data.length);
  return (
    <Box sx={{ height: "360px" }}>
      <ResponsiveBar
        data={data?.data[0]?.data}
        keys={["value"]}
        indexBy="date"
        colors={["#70AE71"]}
        margin={{ top: 30, right: 0, bottom: 70, left: 80 }}
        padding={padding}
        groupMode="grouped"
        valueScale={{
          type: "linear",
          min: 0,
          max: yMax > 500 ? yMax : 500,
          clamp: true,
        }}
        indexScale={{ type: "band" }}
        innerPadding={3}
        axisTop={null}
        axisRight={{ tickSize: 0 }}
        enableGridX={false}
        theme={{
          fontSize: 14,
          textColor: "#355962",
          grid: {
            line: {
              strokeDasharray: "6 6",
            },
          },
          axis: {
            ticks: {
              text: {
                fontSize: 14,
                fontWeight: 550,
              },
            },
            domain: {
              line: {
                stroke: "#E0E3EB",
              },
            },
            legend: {
              text: {
                fill: "#327091",
                fontWeight: 600,
                fontSize: 13,
              },
            },
          },
        }}
        layers={[
          "grid",
          "axes",
          "bars",
          "markers",
          "legends",
          "annotations",
          ChartLoader(data?.loading, data?.error, data?.empty, "#70AE71"),
        ]}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legendOffset: 45,
          legend: "Day",
          legendPosition: "middle",
          tickValues:
            criteria === "month"
              ? data?.data[0]?.data
                  ?.map((v: any, i: number) => {
                    const divider = 5;
                    if (i % divider === 0) return v?.date;
                    else return null;
                  })
                  .filter((v: any) => v)
              : undefined,
          format: (value: any) => {
            let dayOfWeek: any = value;
            if (criteria === "week") {
              const date = DateTime.fromFormat(value, "dd/MM/yyyy");
              dayOfWeek = date.toFormat("EEE");
            } else if (criteria === "month") {
              const date = DateTime.fromFormat(value, "dd/MM/yyyy");
              dayOfWeek = date.toFormat("d");
            }
            return dayOfWeek;
          },
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 15,
          tickRotation: 0,
          legendOffset: -70,
          legend: "Steps",
          legendPosition: "middle",
          format: (value: any) => `${value?.toLocaleString()}`,
        }}
        enableLabel={false}
        tooltipLabel={(value: any) => {
          return value?.indexValue;
        }}
      />
    </Box>
  );
};

export default StepsGraph;
