import { SxProps } from "@mui/material";

const logStatusContainer = {
  border: 1,
  height: "50px",
  width: "fitContent",
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  padding: "15px 20px",
  mr: 1,
  fontWeight: "600",
  fontSize: "16px",
};

export const loggedContainer: SxProps = {
  ...logStatusContainer,
  borderColor: "#327091",
  color: "#327091",
};

export const notloggedContainer: SxProps = {
  ...logStatusContainer,
  borderColor: "#FF8A4C",
  color: "#FF8A4C",
};

export const JournalAccordion: SxProps = {
  boxShadow: "none",
  border: "1px solid #E5E7EB",
  borderRadius: "8px !important",
  mb: 2.5,
  "::before": {
    backgroundColor: "transparent",
  },
};

export const JournalAccordionHeaderContainer: SxProps = {
  display: "flex",
  flex: 1,
  minWidth: "1px",
  alignItems: "center",
};

export const JournalAccordionHeader: SxProps = {
  py: 1.5,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flex: 1,
  minWidth: "1px",
};
export const AdherenceContainer: SxProps = {
  backgroundColor: "#FFFFFF",
  borderRadius: "8px",
  padding: "15px 20px",
  mb: 2.5,
};
export const LinearProgressStyle: SxProps = {
  "& .MuiLinearProgress-root": {
    height: "19px",
    borderRadius: 2.5,
    mt: 1,
    backgroundColor: "#E5E7EB",
  },
  marginBottom: "16px",
};

export const MainContainer: SxProps = {
  borderRadius: "12px",
  backgroundColor: "#ffffff",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: 3,
  height: "450px",
  width: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};
