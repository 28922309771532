import { Avatar, Skeleton, SxProps, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import {
  setDateLoaded,
  setHealthEndDate,
  setHealthStartDate,
  setOffSet,
  setUserDetails,
} from "../../../../../Redux/reducers/analysisSlice";
import { PersonOutlineOutlined } from "@mui/icons-material";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import { errorToastMessage } from "../../../../../utils/toast";

const InfoContainer: SxProps = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  pr: 2,
  mr: 2,
};

const BorderedInfoContainer: SxProps = {
  ...InfoContainer,
};

const text1: SxProps = {
  color: "#111928",
  fontSize: "18px",
  fontWeight: "600",
  lineHeight: "150%",
  mb: 1,
  textTransform: "capitalize",
};

const text2: SxProps = {
  color: "#6B7280",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "150%",
};
const HeaderStyle: SxProps = {
  height: "112px",
  width: "100%",
  borderRadius: "16px",
  background: "#FEFEFE",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: 3,
  display: "flex",
  alignItems: "center",
  p: 2,
  justifyContent: "space-between",
};

export function getStartEndDate(offSet: any) {
  let endTemp = DateTime.now();
  let startTemp: DateTime = endTemp.plus({ hours: 0 });
  endTemp = endTemp.plus({ hours: offSet * 3 });
  startTemp = endTemp.plus({ hours: -3 });
  return {
    start: startTemp.toFormat("LL-dd-yyyy HH:mm:ss"),
    end: endTemp.toFormat("LL-dd-yyyy HH:mm:ss"),
  };
}

const StatsDateSelector: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const { user, toggleReload } = useAppSelector((state) => state.analysis);
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        dispatch(setUserDetails(null));
        const res = await http.get(`/participants/${id}/profile`);
        const response = res.data.data;
        const data = {
          email: response?.user?.email,
          name: response?.user?.firstName + " " + response?.user?.lastName,
          id: response?.user?.id,
          gender: response?.gender,
          height: response?.height || 1,
        };
        dispatch(setUserDetails(data));
        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [id, setLoading, dispatch, toggleReload]);

  useEffect(() => {
    dispatch(setOffSet(0));
    const endTemp = DateTime.now();
    const startTemp = endTemp.plus({ hours: -3 });
    dispatch(setHealthEndDate(endTemp.toFormat("LL-dd-yyyy HH:mm:ss")));
    dispatch(setHealthStartDate(startTemp.toFormat("LL-dd-yyyy HH:mm:ss")));
    dispatch(setDateLoaded(true));
  }, [dispatch]);

  return (
    <Box sx={HeaderStyle}>
      <Box sx={{ display: "flex" }}>
        <Avatar
          variant={"square"}
          sx={{
            height: "80px",
            width: "80px",
            bgcolor: "lightgray",
            color: "#000",
            mr: 2,
          }}
        >
          <PersonOutlineOutlined fontSize="large" />
        </Avatar>
        <Box sx={BorderedInfoContainer}>
          {loading ? (
            <>
              <Skeleton
                variant="rectangular"
                sx={{ height: "27px", width: "100px", mb: 1 }}
              />
              <Skeleton
                variant="rectangular"
                sx={{ height: "18px", width: "100px" }}
              />
            </>
          ) : (
            <>
              <Typography sx={text1}>{user?.name || "-"}</Typography>
              <Typography sx={text2}>{user?.email || "-"}</Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default StatsDateSelector;
