import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import { NoDataContainer } from "../../../../Common/styles/table";
import { DateTime } from "luxon";
import {
  CheckCircleRounded,
  ExpandMore,
  WarningRounded,
} from "@mui/icons-material";
import HipItem from "./HipItem";
import {
  JournalAccordion,
  JournalAccordionHeader,
  JournalAccordionHeaderContainer,
  loggedContainer,
  MainContainer,
  notloggedContainer,
} from "../style";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import { ResponsiveLine } from "@nivo/line";
import ChartLoader from "../ChartLoader";
import { CustomTooltipLineGraph } from "../../../../Common/UI/Tooltip";
import { setButtonToggle } from "../../../../../Redux/reducers/healthSlice";

export interface HipEntry {
  id: string;
  hip: number;
  time: string;
}

interface HipMetrics {
  dateLabel: string;
  dayLabel: string;
  logged: boolean;
  entries: Array<HipEntry>;
}

const calculateYMax = (data: any) => {
  const yValues = data.map((point: any) => point.y);
  const maxY = Math.max(...yValues);
  return maxY;
};

const Hip = () => {
  const { id } = useParams();
  const { startDate, endDate, criteria } = useAppSelector(
    (state) => state.health
  );
  const [data, setData] = useState<HipMetrics[]>([]);
  // const [insightsData, setInsightsData] = useState<any>(null);
  const [yMax, setYMax] = useState<number>(0);
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<string | false>(false);

  const [graphData, setGraphData] = useState<any>({
    data: [
      {
        id: "hip",
        data: [],
      },
    ] as any,
    loading: false,
    criteria: criteria,
    error: "",
    empty: false,
  });

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (startDate && endDate) {
          dispatch(setButtonToggle(true));
          setGraphData({
            data: [
              {
                id: "hip",
                data: [],
              },
            ] as any,
            loading: true,
            criteria: criteria,
            error: "",
            empty: false,
          });

          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf("day");
          if (criteria === "month" || criteria === "year") {
            end = DateTime.fromFormat(endDate, "LL-dd-yyyy").startOf("day");
          }

          const res: AxiosResponse = await http.get(
            `/user-details?userId=${id}&size=${1000}&page=${1}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );
          const data = res?.data?.rows;
          // setInsightsData(res?.data?.inSight);
          let hipData: any = [];
          const now = DateTime.now();
          if (end > now) {
            if (criteria === "month") {
              end = now;
            } else if (criteria === "year") {
              end = now.startOf("month");
            }
          }
          // data.forEach((item: any) => {
          //   hipData.push({
          //     x: DateTime.fromFormat(
          //       item?.createdAt?.split("T")?.[0],
          //       "yyyy-LL-dd"
          //     ).toFormat("yyyy-LL-dd hh:mm a"),
          //     y: item.hip,
          //   });
          // });

          const empty = data.length > 0 ? false : true;

          const groupedData = data.reduce((acc: any, item: any) => {
            const date = DateTime.fromISO(item.createdAt).toFormat(
              "yyyy-LL-dd"
            );
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(item);
            return acc;
          }, {});

          const n =
            criteria === "year"
              ? end.diff(start, "months").get("months") + 1
              : end.diff(start, "days").get("days");
          const newData = [];
          for (let i = 0; i < n; i++) {
            const key = end.toFormat("yyyy-LL-dd");
            const entries = (groupedData?.[key] || []).map((entry: any) => {
              return {
                id: entry.id,
                hip: entry.hip,
                time: DateTime.fromISO(entry.createdAt).toFormat("hh:mm a"),
              };
            });
            newData.push({
              dayLabel: end.toFormat("cccc"),
              dateLabel: end.toFormat("dd LLLL yyyy"),
              logged: entries.length > 0,
              entries: entries,
            });
            const averageValue =
              entries.length > 0
                ? entries.reduce(
                    (sum: number, entry: any) => sum + entry.hip,
                    0
                  ) / entries.length
                : null;
            hipData.push({
              x: DateTime.fromFormat(
                end?.toISO()?.split("T")?.[0],
                "yyyy-LL-dd"
              ).toFormat("yyyy-LL-dd hh:mm a"),
              y: averageValue,
            });
            end =
              criteria === "year"
                ? end.plus({
                    month: -1,
                  })
                : end.plus({
                    day: -1,
                  });
          }

          const yMaxValue = calculateYMax(hipData);
          setYMax(yMaxValue);
          setGraphData({
            data: [
              {
                id: "hip",
                data: !empty ? hipData : [],
              },
            ],
            loading: false,
            error: "",
            empty: data.length > 0 ? false : true,
            criteria: criteria,
          });
          setData(newData);
          dispatch(setButtonToggle(false));
        } else {
          setGraphData({
            data: [
              {
                id: "hip",
                data: [],
              },
            ] as any,
            loading: false,
            error: "",
            empty: true,
            criteria: criteria,
          });
          dispatch(setButtonToggle(false));
        }
      } catch (err) {
        dispatch(setButtonToggle(false));

        setGraphData((prev: any) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            criteria: criteria,

            data: [
              {
                id: "hip",
                data: [],
              },
            ],
            empty: false,
            tableData: [],
          };
        });
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [dispatch, startDate, endDate, setData, setGraphData, id, criteria]);
  console.log(yMax);
  return (
    <>
      <Box sx={MainContainer}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            minHeight: "1px",
            py: 4,
            px: 1,
          }}
        >
          <ResponsiveLine
            data={graphData?.data}
            curve="monotoneX"
            margin={{ top: 20, right: 60, bottom: 70, left: 80 }}
            xScale={{
              format: "%Y-%m-%d %I:%M %p",
              precision: "minute",
              type: "time",
              useUTC: false,
            }}
            yScale={{
              type: "linear",
              min: 10,
              max: yMax > 70 ? yMax : 70,
            }}
            layers={[
              "grid",
              "markers",
              "axes",
              // "areas",
              // "crosshair",
              "lines",
              "points",
              "mesh",
              "legends",
              ChartLoader(
                graphData?.loading,
                graphData?.error,
                graphData?.empty,
                "#70AE71"
              ),
            ]}
            enableGridY={true}
            enableGridX={true}
            yFormat=" >-.2f"
            colors={["#327091"]}
            lineWidth={3}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              format:
                graphData?.criteria === "month"
                  ? "%e"
                  : graphData?.criteria === "week"
                  ? "%a"
                  : "%B",
              tickValues:
                graphData?.empty || graphData.loading
                  ? [
                      DateTime.fromFormat(
                        startDate || "",
                        "LL-dd-yyyy"
                      ).toFormat("LL-dd-yyyy"),
                    ]
                  : graphData?.criteria === "month"
                  ? "every 3 days"
                  : graphData?.criteria === "week"
                  ? "every 1 day"
                  : "every 1 month",
              legend: "Time",
              legendPosition: "middle",
              legendOffset: 40,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 15,
              tickRotation: 0,
              legendOffset: -55,
              legend: "Hip",
              legendPosition: "middle",

              // tickValues: [50, 100, 150, 200, 250, 300],
            }}
            // pointSize={10}
            // pointColor="#FFF"
            // pointBorderWidth={2}
            // pointBorderColor={{ from: "serieColor" }}
            theme={{
              fontFamily: "Poppins, sans-serif",
              fontSize: 14,
              axis: {
                ticks: {
                  text: {
                    fontSize: 14,
                    fontWeight: 500,
                    fill: "#355962",
                  },
                },
                legend: {
                  text: {
                    fill: "#9CA3AF",
                    fontWeight: 500,
                    fontSize: 14,
                  },
                },
              },
            }}
            isInteractive={true}
            useMesh={true}
            tooltip={(value: any) => {
              const date = value?.point?.data?.x;

              let label = !(criteria === "year")
                ? DateTime.fromJSDate(date).toFormat("LL/dd/yyyy")
                : DateTime.fromJSDate(date).toFormat("MMM yyyy");

              return (
                <CustomTooltipLineGraph
                  value={value?.point?.data?.y}
                  color={value?.point?.color}
                  label={label}
                />
              );
            }}
            // xFormat="%Y-%m-%d %I:%M %p"
          />
        </Box>
      </Box>
      {!graphData?.loading &&
        data?.length > 0 &&
        data?.map((item) => {
          return (
            <Accordion
              key={item.dateLabel}
              TransitionProps={{ unmountOnExit: true }}
              expanded={expanded === `panel${item.dateLabel}`}
              onChange={handleChange(`panel${item.dateLabel}`)}
              sx={JournalAccordion}
            >
              <AccordionSummary
                expandIcon={
                  <IconButton>
                    <ExpandMore sx={{ fontSize: 24 }} />
                  </IconButton>
                }
                sx={{
                  ".Mui-expanded": {
                    m: "12px 0px !important",
                  },
                }}
              >
                <Box sx={JournalAccordionHeaderContainer}>
                  <Box sx={JournalAccordionHeader}>
                    <Box>
                      <Typography
                        fontWeight={600}
                        fontSize={"22px"}
                        color={"#355962"}
                        mb={0.5}
                      >
                        {item?.dateLabel}
                      </Typography>
                      <Typography
                        fontWeight={500}
                        fontSize={"16px"}
                        color={"#355962"}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {item?.dayLabel}
                      </Typography>
                    </Box>
                  </Box>
                  {item.logged ? (
                    <Box sx={loggedContainer}>
                      <CheckCircleRounded sx={{ mr: 1 }} />
                      Hip Logged
                    </Box>
                  ) : (
                    <Box sx={notloggedContainer}>
                      <WarningRounded sx={{ mr: 1 }} />
                      Hip Not Logged
                    </Box>
                  )}
                </Box>
              </AccordionSummary>
              {item.entries.length > 0 ? (
                <AccordionDetails
                  sx={{
                    p: 2,
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(auto-fill , minmax(272px , 1fr))",
                    gap: 2,
                    mb: 2,
                    alignContent: "center",
                  }}
                >
                  {item.entries.map((logEntry) => {
                    return <HipItem key={logEntry.id} data={logEntry} />;
                  })}
                </AccordionDetails>
              ) : (
                <NoDataContainer>
                  <Typography variant="body1" color="gray">
                    No Data
                  </Typography>
                </NoDataContainer>
              )}
            </Accordion>
          );
        })}
    </>
  );
};

export default Hip;
