import React from "react";
import {
  Box,
  LinearProgress,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  NoDataContainer,
  StyledTableCell,
  TableLayoutStyle,
} from "../../../../../Common/styles/table";

export const StyleHeader: SxProps = {
  bgcolor: "#F9FAFB",
  borderTop: "1px solid #E0E3EB",
  borderBottom: "1px solid #E0E3EB",
  color: "#355962",
};
export const StyleBody: SxProps = {
  color: "#637E85",
  fontSize: "14px",
  fontWeight: 600,
};

const BloodPressureInsights = ({ tableData, loading }: any) => {
  const filteredData = tableData?.filter(
    (item: any) =>
      item.dateLabel &&
      item.dateLabel.trim() !== "" &&
      item.dateLabel &&
      item.dateLabel.trim() !== "."
  );
  return (
    <Box>
      <Box
        sx={{
          padding: "16px",
          bgcolor: "#FFFFFF",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
          Blood Pressure Data
        </Typography>
      </Box>
      <Box>
        <Table>
          <TableHead sx={{ ...TableLayoutStyle }}>
            <TableRow>
              <TableCell sx={StyleHeader}>Date</TableCell>
              <TableCell sx={StyleHeader}>Systolic/Diastolic</TableCell>
              <TableCell sx={StyleHeader}>Heart rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              display: "block",
              ...(tableData?.length > 0 && {
                height: "calc(100vh - 350px)",
                overflow: "auto",
              }),
            }}
          >
            {!loading &&
              filteredData?.length > 0 &&
              filteredData.map((item: any, index: number) => (
                <TableRow key={index} sx={TableLayoutStyle}>
                  <StyledTableCell sx={StyleBody}>
                    {item.dateLabel} {item?.time ? " at " + item?.time : null}
                  </StyledTableCell>
                  <StyledTableCell sx={StyleBody}>
                    <Typography
                      sx={{
                        color: "#000000",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      {item?.systolic?.high}/{item?.diastolic?.high}
                    </Typography>{" "}
                    <Typography style={{ fontSize: "12px" }}>mmHg</Typography>
                  </StyledTableCell>
                  <StyledTableCell sx={StyleBody}>
                    {item?.heartRate ? (
                      <>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: "600",
                            fontSize: "18px",
                          }}
                        >
                          {item?.heartRate}
                        </Typography>{" "}
                        <Typography style={{ fontSize: "12px" }}>
                          bpm
                        </Typography>
                      </>
                    ) : (
                      <Typography>-</Typography>
                    )}
                  </StyledTableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {!loading && filteredData?.length === 0 && (
          <NoDataContainer>
            <Typography variant="body1" color="gray">
              No Data
            </Typography>
          </NoDataContainer>
        )}
        {loading && (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BloodPressureInsights;
