import {
  Box,
  CircularProgress,
  Divider,
  SxProps,
  Typography,
} from "@mui/material";
import { NoDataContainer } from "../../../../Common/styles/table";
import { LoadingContainer } from "../../../../CMS/cms.style";

export const tempContainer: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "60px",
  p: "20px",
};

export const tempName: SxProps = {
  fontWeight: "400",
  fontSize: "14px",
  color: "#111928",
};

export const tempValue: SxProps = {
  fontWeight: "600",
  fontSize: "18px",
  color: "#355962",
};

const FoodDataInsights = ({ insightsData, loading }: any) => {
  return (
    <Box sx={{ minHeight: "300px" }}>
      <Box
        sx={{
          padding: "16px",
          bgcolor: "#FFFFFF",
          borderRadius: "8px 8px 0 0",
          borderBottom: "1px solid #E0E3EB",
        }}
      >
        <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
          Data Insights
        </Typography>
      </Box>
      {!loading && insightsData && (
        <Box
          sx={{
            m: "16px",
            borderRadius: "12px",
            border: "1px solid #E0E3EB",
          }}
        >
          <Typography
            fontWeight={"600"}
            fontSize={"14px"}
            color="#637E85"
            borderBottom="1px solid #E0E3EB"
            padding="16px"
          >
            Average
          </Typography>
          <Box>
            <Box sx={tempContainer}>
              <Typography sx={tempName}>Carbs</Typography>
              <Typography sx={tempValue}>
                {insightsData?.avgCarbs?.toFixed(0) || "-"}
              </Typography>
            </Box>
            <Divider />
            <Box sx={tempContainer}>
              <Typography sx={tempName}>Protein</Typography>
              <Typography sx={tempValue}>
                {insightsData?.avgProteins?.toFixed(0) || "-"}
              </Typography>
            </Box>
            <Divider />
            <Box sx={tempContainer}>
              <Typography sx={tempName}>Fiber</Typography>
              <Typography sx={tempValue}>
                {insightsData?.avgFibers?.toFixed(0) || "-"}
              </Typography>
            </Box>
            <Divider />
            <Box sx={tempContainer}>
              <Typography sx={tempName}>Fat</Typography>
              <Typography sx={tempValue}>
                {insightsData?.avgFat?.toFixed(0) || "-"}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
      {!loading && !insightsData && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={LoadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default FoodDataInsights;
