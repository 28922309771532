import { SxProps } from "@mui/material";

export const ContainerStyle: SxProps = {
  borderRadius: "12px",
  backgroundColor: "#ffffff",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: 3,
  height: "300px",
  width: "100%",
  overflow: "hidden",
  display: "flex",
};
export const GraphStyle: SxProps = {
  height: "300px",
  width: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};

export const DetailStyle: SxProps = {
  p: "0px 16px",
  width: "22%",
  height: "70px",
};
