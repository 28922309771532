import React, { useEffect, useState } from "react";
import { Box, SxProps } from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import ChartLoader from "../ChartLoader";
import { DateTime } from "luxon";
import { ResponsiveLine } from "@nivo/line";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import { CustomTooltipLineGraph } from "../../../../Common/UI/Tooltip";
import HeartRateInsights from "./HeartRateInsights";
import HeartRateDataInsights from "./HeartRateDataInsights";
import { setButtonToggle } from "../../../../../Redux/reducers/healthSlice";

const container: SxProps = {
  borderRadius: "12px",
  backgroundColor: "#ffffff",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: 3,
  height: "450px",
  width: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};

const calculateYMax = (data: any) => {
  const yValues = data.map((point: any) => point.y);
  const maxY = Math.max(...yValues);
  return maxY;
};

const HeartRate = () => {
  const { id } = useParams();
  const { startDate, endDate, criteria } = useAppSelector(
    (state) => state.health
  );
  // const newStart = DateTime.fromFormat(startDate || "", "LL-dd-yyyy");
  // const newEnd = DateTime.fromFormat(endDate || "", "LL-dd-yyyy");
  const [insightsData, setInsightsData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [yMax, setYMax] = useState<number>(0);
  // const diff = newEnd.diff(newStart, "days").get("days");
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>({
    data: [
      {
        id: "heart",
        data: [],
      },
    ] as any,
    loading: false,
    criteria: criteria,
    tableData: [],
    error: "",
    empty: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          dispatch(setButtonToggle(true));

          setData({
            data: [
              {
                id: "heart",
                data: [],
              },
            ] as any,
            loading: true,
            criteria: criteria,
            tableData: [],

            error: "",
            empty: false,
          });
          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end: any = DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf(
            "day"
          );
          const res: AxiosResponse = await http.get(
            `/heart-rate-log/by-user-id?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
            // &page=${1}&size=${1000}`
          );
          const heartData = res.data?.rows;
          setInsightsData(
            res?.data?.inSights?.reduce((acc: any, item: any) => {
              acc[item.type_of] = {
                average: item.average,
                high: item.high,
                low: item.low,
              };
              return acc;
            }, {})
          );
          let heart: any = [];
          let heartTable: any = [];
          heartTable = heartData.map((item: any) => ({
            dateLabel: DateTime.fromISO(item?.createdAt).toFormat(
              "LLLL dd yyyy"
            ),
            time: DateTime.fromISO(item?.createdAt).toFormat("hh:mm a"),
            value: item.value,
          }));
          heartData.forEach((item: any) => {
            heart.push({
              x: DateTime.fromISO(item?.createdAt).toFormat(
                "yyyy-LL-dd hh:mm a"
              ),
              y: item.value,
            });
          });
          const isEmpty = heartData.length === 0;
          const yMaxValue = calculateYMax(heart);
          setYMax(yMaxValue);
          setData({
            data: [
              {
                id: "heart",
                data: isEmpty ? [] : heart,
              },
            ],
            loading: false,
            criteria: criteria,
            tableData: heartTable,
            error: "",
            empty: isEmpty,
          });
          dispatch(setButtonToggle(false));
        } else {
          setData({
            data: [
              {
                id: "heart",
                data: [],
              },
            ] as any,
            loading: false,
            criteria: criteria,
            tableData: [],

            error: "",
            empty: true,
          });
        }
        setLoading(false);
        dispatch(setButtonToggle(false));
      } catch (err) {
        setData((prev: any) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            data: [
              {
                id: "heart",
                data: [],
              },
            ],
            criteria: criteria,
            tableData: [],

            empty: false,
          };
        });
        errorToastMessage(err as Error);
        setLoading(false);
        dispatch(setButtonToggle(false));
      }
    };

    fetchData();
  }, [dispatch, id, startDate, endDate, criteria]);

  return (
    <>
      <Box sx={container}>
        <Box sx={{ flex: 1, minHeight: "1px", p: 4 }}>
          <ResponsiveLine
            data={data?.data}
            curve="monotoneX"
            margin={{ top: 20, right: 50, bottom: 60, left: 60 }}
            xScale={{
              // format: "%I:%M %p",
              format: "%Y-%m-%d %I:%M %p",
              precision: "minute",
              type: "time",
              useUTC: false,
            }}
            yScale={{
              type: "linear",
              min: 40,
              max: yMax > 120 ? yMax : 120,
            }}
            layers={[
              "grid",
              "markers",
              "axes",
              // "areas",
              // "crosshair",
              "lines",
              "points",
              "mesh",
              "legends",
              ChartLoader(data?.loading, data?.error, data?.empty, "#70AE71"),
            ]}
            enableGridX={false}
            yFormat=" >-.2f"
            colors={["#327091"]}
            lineWidth={3}
            axisTop={null}
            // pointSize={6}
            // pointColor="#FFF"
            // pointBorderWidth={2}
            // pointBorderColor={{ from: "serieColor" }}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              format:
                criteria === "week"
                  ? "%a"
                  : criteria === "day"
                  ? "%I %p"
                  : "%e",
              tickValues:
                data?.empty || data.loading
                  ? [
                      DateTime.fromFormat(
                        startDate || "",
                        "LL-dd-yyyy"
                      ).toFormat("LL-dd-yyyy"),
                    ]
                  : data?.criteria === "day"
                  ? "every 2 hours"
                  : data?.criteria === "week"
                  ? "every 1 day"
                  : "every 5 days",
              legend: "Time",
              legendPosition: "middle",
              legendOffset: 80,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 15,
              tickRotation: 0,
              legendOffset: -50,
              legend: "Heart Rate(bpm)",
              legendPosition: "middle",
            }}
            theme={{
              fontFamily: "Poppins, sans-serif",
              fontSize: 14,
              axis: {
                ticks: {
                  text: {
                    fontSize: 14,
                    fontWeight: 500,
                    fill: "#355962",
                  },
                },
                legend: {
                  text: {
                    fill: "#9CA3AF",
                    fontWeight: 500,
                    fontSize: 14,
                  },
                },
              },
            }}
            // enablePoints={true}
            isInteractive={true}
            useMesh={true}
            tooltip={(value: any) => {
              const date = value?.point?.data?.x;
              let label = DateTime.fromJSDate(date).toFormat("dd/LL/yyyy");

              return (
                <CustomTooltipLineGraph
                  value={value?.point?.data?.y}
                  color={value?.point?.color}
                  label={label}
                />
              );
            }}
            // xFormat="%Y-%m-%d %I:%M %p"
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ ...container, width: "60%", height: "auto" }}>
          <HeartRateInsights tableData={data?.tableData} loading={loading} />
        </Box>
        <Box sx={{ ...container, width: "40%", height: "auto" }}>
          <HeartRateDataInsights insightData={insightsData} loading={loading} />
        </Box>
      </Box>
    </>
  );
};

export default HeartRate;
