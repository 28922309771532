import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import { NoDataContainer } from "../../../../Common/styles/table";
import {
  CheckCircleRounded,
  ExpandMore,
  WarningRounded,
} from "@mui/icons-material";
import {
  JournalAccordion,
  JournalAccordionHeader,
  JournalAccordionHeaderContainer,
  loggedContainer,
  notloggedContainer,
} from "../style";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import MedicationWrapper from "./MedicationWrapper";
import MedicationAdherence from "./MedicationAdherence";
import { DateTime } from "luxon";
import MedicationItem from "./MedicationItem";
import MedicationDataInsights from "./MedicationDataInsights";
import { setButtonToggle } from "../../../../../Redux/reducers/healthSlice";

export interface medicationEntryItem {
  id: string;
  label: string;
  dose: string;
  status: string;
  takenTime: any;
}
export interface medicationEntry {
  id: string;
  reminderTime: any;
  val: number;
  meds: Array<medicationEntryItem>;
}

// interface MedicationMetrics {
//   dateLabel: string;
//   dayLabel: string;
//   logged: boolean;
// }
// const medicationColors = [
//   "#327091",
//   "#0694A2",
//   "#E74694",
//   "#C27803",
//   "#42389D",
//   "#0EAD69",
//   "#F05252",
//   "#FF5A1F",
//   "#1C64F2",
//   "#7E3AF2",
// ];

// const formatData = (data: any[], medicationList: string[]) => {
//   const lineGraph: any[] = medicationList.map(
//     (name: string, index: number) => ({
//       id: name,
//       data: [],
//       color: medicationColors[index % medicationColors.length],
//     })
//   );
//   data.forEach((dayData: any) => {
//     const x = dayData.date;
//     medicationList.forEach((name, index) => {
//       const medicationData = dayData.entries.find(
//         (entry: any) => entry.name === name
//       );
//       const y = medicationData?.percentage ?? 0;
//       lineGraph.find((line: any) => line.id === name).data.push({ x, y });
//     });
//   });
//   return lineGraph;
// };

const Medication = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  // const [data, setData] = useState<MedicationMetrics[]>([]);
  const [error, setError] = useState("");
  const { startDate, endDate } = useAppSelector((state) => state.health);
  const [insights, setInsights] = useState<any>(null);
  const [medicationList, setMedicationList] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [adherence, setAdherence] = useState<any>();
  const [accordionData, setAccordionData] = useState<any[]>([]);

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          dispatch(setButtonToggle(true));

          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf("day");

          const adherenceResponse: AxiosResponse = await http.get(
            `/activity/medication/calendar-view?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );
          const adherenceData = adherenceResponse?.data?.data?.data;

          const groupedData = adherenceData.reduce((acc: any, item: any) => {
            const date = DateTime.fromFormat(
              item.date?.split("T")?.[0],
              "yyyy-LL-dd"
            ).toFormat("yyyy-LL-dd");
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(item);
            return acc;
          }, {});

          const accordionData = Object.keys(groupedData).map((date) => {
            const dailyEntries = groupedData[date];
            const categorizedEntries = dailyEntries.reduce(
              (acc: any, entry: any) => {
                const medicationName = entry.medication_name;
                if (!acc[medicationName]) {
                  acc[medicationName] = [];
                }
                acc[medicationName].push({
                  name: medicationName,
                  taken: +entry.taken ?? 0,
                  total: +entry.total ?? 0,
                  percentage: +entry.percentage_taken ?? 0,
                });
                return acc;
              },
              { taken: 0, total: 0, percentage: 0 }
            );
            return {
              dateLabel: DateTime.fromISO(date).toFormat("dd LLLL yyyy"),
              dayLabel: DateTime.fromISO(date).toFormat("cccc"),
              date: DateTime.fromISO(date).toFormat("dd/MM/yyyy"),
              logged: dailyEntries.length > 0,
              entries: categorizedEntries,
            };
          });

          // const n = end.diff(start, "days").get("days");
          // const newData: any[] = [];
          // for (let i = 0; i < n; i++) {
          //   const key = end.toFormat("yyyy-LL-dd");
          //   const entries = (groupedData?.[key] || []).map((entry: any) => {
          //     return {
          //       name: entry.medication_name,
          //       total: +entry.total ?? 0,
          //       taken: +entry.taken ?? 0,
          //       percentage: +entry.percentage_taken ?? 0,
          //     };
          //   });
          //   newData.push({
          //     dayLabel: end.toFormat("cccc"),
          //     dateLabel: end.toFormat("dd LLLL yyyy"),
          //     date: end.toFormat("dd/MM/yyyy"),
          //     logged: entries.length > 0,
          //     entries: entries,
          //   });
          //   end = end.plus({
          //     day: -1,
          //   });
          // }
          // newData?.reverse();
          const inSights = adherenceResponse?.data?.data?.inSight;
          setInsights(inSights);
          const data = inSights?.map((medication: any) => {
            return {
              name: medication.medication_name,
              individualPercent: medication?.average_percentage_taken,
            };
          });
          setAdherence(data);

          const uniqueMedications: string[] = Array.from(
            new Set(adherenceData.map((item: any) => item.medication_name))
          );
          setMedicationList(uniqueMedications);
          // const lineGraph = formatData(newData, uniqueMedications);
          // setAdherence(lineGraph);
          setAccordionData(accordionData);
          setError("");
          setLoading(false);
          dispatch(setButtonToggle(false));
        } else {
          setLoading(false);
          dispatch(setButtonToggle(false));
        }
      } catch (err) {
        setError("Something went Wrong");
        errorToastMessage(err as Error);
        setLoading(false);
        dispatch(setButtonToggle(false));
      }
    };

    fetchDetails();
  }, [dispatch, startDate, endDate, setLoading, setError, id]);

  return loading ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress size={25} />
    </Box>
  ) : error ? (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        {error}
      </Typography>
    </NoDataContainer>
  ) : adherence?.length > 0 ? (
    <>
      {adherence && <MedicationAdherence data={adherence} />}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ bgcolor: "#FFF", borderRadius: "8px", width: "58%" }}>
          <Box sx={{ px: 3, py: 5 }}>
            <Typography variant="h6">Medication Data</Typography>
          </Box>
          <Box sx={{ bgcolor: "#E0E3EB", p: 3 }}>
            <Typography color={"#355962"} variant="body1" fontWeight={600}>
              Date
            </Typography>
          </Box>
          {accordionData?.map((item) => {
            return (
              <Accordion
                key={item.dateLabel}
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded === `panel${item.dateLabel}`}
                onChange={handleChange(`panel${item.dateLabel}`)}
                sx={{
                  ...JournalAccordion,
                  mb: 0,
                  "&.Mui-expanded": {
                    margin: 0,
                    borderRadius: 0,
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <IconButton>
                      <ExpandMore sx={{ fontSize: 24 }} />
                    </IconButton>
                  }
                >
                  <Box sx={JournalAccordionHeaderContainer}>
                    <Box sx={JournalAccordionHeader}>
                      <Box>
                        <Typography
                          fontWeight={600}
                          fontSize={"22px"}
                          color={"#355962"}
                          mb={0.5}
                        >
                          {item?.dateLabel}
                        </Typography>
                        <Typography
                          fontWeight={500}
                          fontSize={"16px"}
                          color={"#355962"}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {item?.dayLabel}
                        </Typography>
                      </Box>
                    </Box>
                    {item.logged ? (
                      <Box sx={loggedContainer}>
                        <CheckCircleRounded sx={{ mr: 1 }} />
                        Medication Logged
                      </Box>
                    ) : (
                      <Box sx={notloggedContainer}>
                        <WarningRounded sx={{ mr: 1 }} />
                        Medication Not Logged
                      </Box>
                    )}
                  </Box>
                </AccordionSummary>
                <Divider />
                <AccordionDetails sx={{ p: 1 }}>
                  {medicationList.map(
                    (medication: any, index: number, array: string[]) => (
                      <Box key={medication} sx={{ mb: 2 }}>
                        <Box>
                          {item?.entries?.[medication]?.length > 0 ? (
                            item?.entries?.[medication]?.map(
                              (logEntry: any, index: number) => (
                                <Box key={index}>
                                  <MedicationItem
                                    key={logEntry.id}
                                    medication={logEntry}
                                  />
                                </Box>
                              )
                            )
                          ) : (
                            <NoDataContainer>
                              <Typography variant="body1" color="gray">
                                No Data
                              </Typography>
                            </NoDataContainer>
                          )}
                        </Box>
                        {index < array.length - 1 && <Divider />}
                      </Box>
                    )
                  )}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
            mb: 3,
            width: "40%",
          }}
        >
          <MedicationDataInsights insightsData={insights} loading={loading} />
        </Box>
      </Box>
    </>
  ) : (
    <NoDataContainer>
      <Typography variant="body1" color="gray">
        No Data
      </Typography>
    </NoDataContainer>
  );
};

export default Medication;
