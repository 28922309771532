import { Box, IconButton, Typography } from "@mui/material";
import { ResponsiveBarCanvas } from "@nivo/bar";
import { renderGridLinesToCanvas } from "@nivo/axes";
import { commonContainerWrapper } from "../../Common/styles/flex";
import { MoreVert } from "@mui/icons-material";
import { calculatePaddingForMedhAdherence } from "../../../utils/recipe";

type Props = {
  data: any;
  handleOptionsClick: (e: any, data: any) => void;
};

const MedicationAdherenceBarGraph = ({ data, handleOptionsClick }: Props) => {
  const padding = calculatePaddingForMedhAdherence(data?.data?.length);
  return (
    <Box sx={{ ...commonContainerWrapper, mb: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography fontSize={30} fontWeight={700} color="text.primary">
          {data.title}
        </Typography>
        <Box>
          <IconButton onClick={(e) => handleOptionsClick(e, data)}>
            <MoreVert />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ height: "360px" }}>
        <ResponsiveBarCanvas
          data={data.data}
          keys={["value"]}
          indexBy="date"
          colors={"#327091"}
          margin={{ top: 30, right: 0, bottom: 42, left: 60 }}
          padding={padding}
          groupMode="grouped"
          valueScale={{ type: "linear", min: 0, max: 100, clamp: true }}
          indexScale={{ type: "band" }}
          innerPadding={3}
          axisTop={null}
          axisRight={{ tickSize: 0 }}
          enableGridX={true}
          borderRadius={6}
          layers={[
            "axes",
            (ctx, { innerWidth, innerHeight, xScale, yScale }) => {
              ctx.lineWidth = 1;
              ctx.strokeStyle = "#E0E3EB";
              ctx.setLineDash([6, 6]);
              // renderGridLinesToCanvas<string | number>(ctx, {
              //   width: innerWidth,
              //   height: innerHeight,
              //   scale: xScale,
              //   axis: "x",
              // });
              renderGridLinesToCanvas<string | number>(ctx, {
                width: innerWidth,
                height: innerHeight,
                scale: yScale,
                axis: "y",
                values: [0, 20, 40, 60, 80, 100],
              });
            },
            "bars",
          ]}
          renderBar={(ctx, { bar, borderRadius }) => {
            ctx.fillStyle = bar.color;
            ctx.beginPath();
            if (bar.width / 2 < borderRadius) {
              borderRadius = bar.width / 2;
            }
            if (borderRadius > 0) {
              const radius = Math.min(borderRadius, bar.height);
              ctx.moveTo(bar.x + radius, bar.y);
              ctx.lineTo(bar.x + bar.width - radius, bar.y);
              ctx.quadraticCurveTo(
                bar.x + bar.width,
                bar.y,
                bar.x + bar.width,
                bar.y + radius
              );
              ctx.lineTo(bar.x + bar.width, bar.y + bar.height);
              ctx.lineTo(bar.x, bar.y + bar.height);
              ctx.lineTo(bar.x, bar.y + radius);
              ctx.quadraticCurveTo(bar.x, bar.y, bar.x + radius, bar.y);
              ctx.closePath();
            }
            ctx.fill();
          }}
          theme={{
            fontSize: 14,
            textColor: "#355962",
            axis: {
              ticks: {
                text: {
                  fontSize: 14,
                  fontWeight: 550,
                },
              },
              domain: {
                line: {
                  stroke: "#E0E3EB",
                },
              },
              legend: {
                text: {
                  fill: "#9CA3AF",
                  fontWeight: 550,
                  fontSize: 14,
                },
              },
            },
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legendOffset: 35,
            legend: "Months",
            legendPosition: "middle",
            //   tickValues:
            //     data.length > 31
            //       ? data
            //           .map((v: any, i: number) => {
            //             const divider = data.length > 50 ? 3 : 2;
            //             if (i % divider === 0) return v?.date;
            //             else return null;
            //           })
            //           .filter((v: any) => v)
            //       : undefined,
            //   format: (d) => {
            //     const startDate = DateTime.fromFormat(d, "dd/MM/yyyy");
            //     const Ordinal = startDate.day;

            //     return `${Ordinal}`;
            //   },
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 15,
            tickRotation: 0,
            tickValues: [0, 20, 40, 60, 80, 100],
            legendOffset: -50,
            legend: "Adherence Percentage",
            legendPosition: "middle",
            format: (value) => `${value}`,
          }}
          enableLabel={false}
          tooltipLabel={(value: any) => {
            return value?.indexValue;
          }}
        />
      </Box>
    </Box>
  );
};

export default MedicationAdherenceBarGraph;
