import { Box, IconButton } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import React from "react";
import ChartLoader from "../../ChartLoader";
import { DateTime } from "luxon";
import { CustomTooltipLineGraph } from "../../../../../Common/UI/Tooltip";
import { GraphStyle } from "../style";
import { useAppDispatch, useAppSelector } from "../../../../../../Redux/hooks";
import {
  setHealthEndDate,
  setHealthStartDate,
  setOffSet,
} from "../../../../../../Redux/reducers/analysisSlice";
import { useSwipeable } from "react-swipeable";
import { getStartEndDate } from "../StatsDateSelector";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

type Props = {
  data: any;
  yMax: number;
  yMin: number;
  startDate: any;
  endDate: any;
  color: string;
  threshold: number;
};

const TemplateGraph = ({
  data,
  yMax,
  yMin,
  startDate,
  endDate,
  color,
  threshold,
}: Props) => {
  const dispatch = useAppDispatch();
  const { offSet } = useAppSelector((state) => state.analysis);
  function handleDateChange(selectedCriteria: string) {
    let tempOffSet = offSet;
    if (selectedCriteria === "increment" && offSet === 0) {
      return;
    }
    if (selectedCriteria === "increment") {
      tempOffSet = offSet + 1;
    } else if (selectedCriteria === "decrement") {
      tempOffSet = offSet - 1;
    }
    const { start, end } = getStartEndDate(tempOffSet);
    dispatch(setOffSet(tempOffSet));
    dispatch(setHealthEndDate(end));
    dispatch(setHealthStartDate(start));
  }
  const swipeable = useSwipeable({
    onSwipedLeft: () => handleDateChange("increment"),
    onSwipedRight: () => handleDateChange("decrement"),
    trackMouse: true,
    trackTouch: true,
    delta: 150,
  });
  return (
    <Box sx={GraphStyle}>
      <Box
        sx={{
          flex: 1,
          minHeight: "1px",
          p: 4,
          display: "flex",
          alignItems: "center",
          height: "300px",
        }}
      >
        <Box sx={{ height: "50px" }}>
          <IconButton onClick={() => handleDateChange("decrement")}>
            <ChevronLeft />
          </IconButton>
        </Box>
        <Box sx={GraphStyle} {...swipeable}>
          <ResponsiveLine
            data={data?.data}
            curve="monotoneX"
            margin={{ top: 50, right: 50, bottom: 60, left: 60 }}
            xScale={{
              // format: "%I:%M %p",
              format: "%Y-%m-%d %I:%M %p",
              precision: "minute",
              type: "time",
              useUTC: false,
            }}
            yScale={{
              type: "linear",
              min: yMin,
              max: yMax > threshold ? yMax : threshold,
            }}
            layers={[
              "grid",
              "markers",
              "axes",
              // "areas",
              // "crosshair",
              "lines",
              "points",
              "mesh",
              "legends",
              ChartLoader(data?.loading, data?.error, data?.empty, "#70AE71"),
            ]}
            enableGridX={false}
            yFormat=" >-.2f"
            colors={[color]}
            lineWidth={3}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              format: "%I %p",
              tickValues:
                data?.empty || data.loading
                  ? [
                      DateTime.fromFormat(
                        startDate || "",
                        "LL-dd-yyyy HH:mm:ss"
                      ).toFormat("LL-dd-yyyy HH:mm:ss"),
                    ]
                  : "every 1 hour",
              legend: "Time",
              legendPosition: "middle",
              legendOffset: 80,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 15,
              tickRotation: 0,
            }}
            theme={{
              fontFamily: "Poppins, sans-serif",
              fontSize: 14,
              axis: {
                ticks: {
                  text: {
                    fontSize: 14,
                    fontWeight: 500,
                    fill: "#355962",
                  },
                },
                legend: {
                  text: {
                    fill: "#9CA3AF",
                    fontWeight: 500,
                    fontSize: 14,
                  },
                },
              },
            }}
            // enablePoints={true}
            isInteractive={true}
            useMesh={true}
            tooltip={(value: any) => {
              const date = value?.point?.data?.x;
              let label =
                DateTime.fromJSDate(date).toFormat("dd/LL/yyyy hh:mm a");

              return (
                <CustomTooltipLineGraph
                  value={+value?.point?.data?.y}
                  color={value?.point?.color}
                  label={label}
                />
              );
            }}
            // xFormat="%Y-%m-%d %I:%M %p"
          />
        </Box>
        <Box sx={{ height: "50px" }}>
          <IconButton
            onClick={() => handleDateChange("increment")}
            disabled={offSet === 0}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default TemplateGraph;
