import React, { useEffect, useState } from "react";
import {
  Box,
  // CircularProgress,
  SxProps,
  // Typography,
} from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import ChartLoader from "../ChartLoader";
import { DateTime } from "luxon";
import { ResponsiveLine } from "@nivo/line";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import { CustomTooltipLineGraph } from "../../../../Common/UI/Tooltip";
import BloodGlucoseInsights from "./BloodGlucoseInsights/BloodGlucoseInsights";
import GlucoseDataInsights from "./BloodGlucoseInsights/GlucoseDataInsights";
import { setButtonToggle } from "../../../../../Redux/reducers/healthSlice";

const container: SxProps = {
  borderRadius: "12px",
  backgroundColor: "#ffffff",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: 3,
  height: "450px",
  width: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};

const chartWrapper: SxProps = {
  flex: 1,
  width: "100%",
  height: "450px",
};
const calculateYMax = (data: any) => {
  const yValues = data.map((point: any) => point.y);
  const maxY = Math.max(...yValues);
  return maxY;
};

const BloodGlucose = () => {
  const { id } = useParams();
  const { startDate, endDate, criteria } = useAppSelector(
    (state) => state.health
  );
  const dispatch = useAppDispatch();
  // const newStart = DateTime.fromFormat(startDate || "", "LL-dd-yyyy");
  // const newEnd = DateTime.fromFormat(endDate || "", "LL-dd-yyyy");
  // const diff = newEnd.diff(newStart, "days").get("days");
  const [loading, setLoading] = useState<Boolean>(true);
  const [yMax, setYMax] = useState<number>(0);
  const [glucoseInsightData, setGlucoseInsightData] = useState<any>();
  const [data, setData] = useState<any>({
    data: [
      {
        id: "glucose",
        data: [],
      },
    ] as any,
    loading: false,
    criteria: criteria,
    tableData: [],
    error: "",
    empty: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true);
        if (startDate && endDate) {
          setLoading(true);
          dispatch(setButtonToggle(true));
          setData({
            data: [
              {
                id: "glucose",
                data: [],
              },
            ] as any,
            loading: true,
            criteria: criteria,
            tableData: [],
            error: "",
            empty: false,
          });
          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end: any = DateTime.fromFormat(endDate, "LL-dd-yyyy").startOf(
            "day"
          );

          if (criteria === "day") {
            end = DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf("day");
          } else {
            end = DateTime.fromFormat(endDate, "LL-dd-yyyy").startOf("day");
          }

          const res: AxiosResponse = await http.get(
            `/glucose-log/by-user-id?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
            // &page=${1}&size=${1000}`
          );
          const glucoseData = res.data?.rows;
          setGlucoseInsightData(res.data?.inSight);
          const empty = glucoseData.length > 0 ? false : true;

          const now = DateTime.now();
          if (end > now) {
            end = now;
          }
          const groupedData = glucoseData.reduce((acc: any, item: any) => {
            const date = DateTime.fromISO(item.createdAt).toFormat(
              "yyyy-LL-dd"
            );
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(item);
            return acc;
          }, {});

          let glucoseTable: any = [];
          glucoseTable = glucoseData.map((item: any) => ({
            dateLabel: DateTime.fromISO(item?.createdAt).toFormat(
              "LLLL dd yyyy"
            ),
            time: DateTime.fromISO(item?.createdAt).toFormat("hh:mm a"),
            value: item.value,
          }));

          let glucose: any = [];
          if (criteria !== "day") {
            let n: number = 0;
            n = end.diff(start, "days").get("days");

            for (let i = 0; i < n; i++) {
              const key = end.toFormat("yyyy-LL-dd");
              const entries = (groupedData?.[key] || []).map((entry: any) => {
                return {
                  id: entry.id,
                  value: entry.value,
                  time: DateTime.fromISO(entry.createdAt).toFormat("hh:mm a"),
                };
              });
              const averageValue =
                entries.length > 0
                  ? entries.reduce(
                      (sum: number, entry: any) => sum + entry.value,
                      0
                    ) / entries.length
                  : null;
              glucose.push({
                x: DateTime.fromFormat(
                  end?.toISO()?.split("T")?.[0],
                  "yyyy-LL-dd"
                ).toFormat("yyyy-LL-dd hh:mm a"),
                y: averageValue?.toFixed(2),
              });
              end = end.plus({ days: -1 });
            }
          } else {
            glucoseData.forEach((item: any) => {
              glucose.push({
                x: DateTime.fromISO(item?.createdAt).toFormat(
                  "yyyy-LL-dd hh:mm a"
                ),
                y: item.value,
              });
            });
          }
          setLoading(false);
          const yMaxValue = calculateYMax(glucose);
          setYMax(yMaxValue);
          setData({
            data: [
              {
                id: "glucose",
                data: glucose,
              },
            ],
            loading: false,
            criteria: criteria,

            error: "",
            empty: empty,
            tableData: glucoseTable,
          });
          dispatch(setButtonToggle(false));
        } else {
          setLoading(false);
          setData({
            data: [
              {
                id: "glucose",
                data: [],
              },
            ] as any,
            loading: false,
            criteria: criteria,

            error: "",
            empty: true,
            tableData: [],
          });
          dispatch(setButtonToggle(false));
        }

        // setLoading(false);
      } catch (err) {
        setLoading(false);
        dispatch(setButtonToggle(true));

        setData((prev: any) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            criteria: criteria,

            data: [
              {
                id: "glucose",
                data: [],
              },
            ],
            empty: false,
            tableData: [],
          };
        });
        errorToastMessage(err as Error);
      }
    };

    fetchData();
  }, [dispatch, id, startDate, endDate, criteria]);
  // const dispatch = useAppDispatch();

  // const handleSwipe = (offSet: number) => {
  //   if (startDate && endDate) {
  //     const newStart = DateTime.fromFormat(startDate || "", "LL-dd-yyyy")
  //       .plus({ days: offSet })
  //       .toFormat("LL-dd-yyyy");
  //     const newEnd = DateTime.fromFormat(endDate || "", "LL-dd-yyyy")
  //       .plus({ days: offSet })
  //       .toFormat("LL-dd-yyyy");

  //     if (DateTime.fromFormat(newEnd, "LL-dd-yyyy") < DateTime.now()) {
  //       dispatch(setHealthEndDate(newEnd));
  //       dispatch(setHealthStartDate(newStart));
  //     }
  //   }
  // };
  // const CustomLineChart = ({
  //   lineGenerator,
  //   series,
  //   ctx,
  //   lineWidth,
  //   innerHeight,
  // }: any) => {
  //   lineGenerator.context(ctx);
  //   const gradient = ctx.createLinearGradient(0, 0, 0, innerHeight);

  //   gradient.addColorStop(0, "#EF8E34");
  //   gradient.addColorStop(0.5, "#38A18F");
  //   gradient.addColorStop(1, "#38A18F");

  //   ctx.strokeStyle = gradient;
  //   ctx.lineWidth = lineWidth;
  //   ctx.beginPath();
  //   lineGenerator(series[0].data.map((d: any) => d.position));
  //   ctx.stroke();
  // };
  return (
    <>
      <Box sx={container}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            minHeight: "1px",
            py: 4,
            px: 1,
          }}
        >
          {/* <Box>
          <IconButton onClick={() => handleSwipe(-1)}>
            <ChevronLeft />
          </IconButton>
        </Box> */}
          <Box sx={chartWrapper}>
            <ResponsiveLine
              data={data?.data}
              curve="monotoneX"
              margin={{ top: 40, right: 50, bottom: 85, left: 70 }}
              xScale={{
                // format: "%I:%M %p",
                format: "%Y-%m-%d %I:%M %p",
                precision: "minute",
                type: "time",
                useUTC: false,
              }}
              yScale={{
                type: "linear",
                min: 50,
                max: yMax > 300 ? yMax : 300,
              }}
              // gridYValues={[0, 50, 100, 150, 200, 250, 300]}
              layers={[
                "grid",
                "markers",
                "axes",
                // "areas",
                // "crosshair",
                "lines",
                "points",
                "mesh",
                "legends",
                ChartLoader(data?.loading, data?.error, data?.empty, "#70AE71"),
              ]}
              enableGridY={true}
              enableGridX={true}
              yFormat=" >-.2f"
              colors={["#327091"]}
              lineWidth={3}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 0,
                tickPadding: 10,
                tickRotation: 0,
                format:
                  criteria === "week"
                    ? "%a"
                    : criteria === "day"
                    ? "%I %p"
                    : "%e",
                tickValues:
                  data?.empty || data.loading
                    ? [
                        DateTime.fromFormat(
                          startDate || "",
                          "LL-dd-yyyy"
                        ).toFormat("LL-dd-yyyy"),
                      ]
                    : data?.criteria === "day"
                    ? "every 2 hours"
                    : data?.criteria === "week"
                    ? "every 1 day"
                    : "every 4 days",
                legend: "Time",
                legendPosition: "middle",
                legendOffset: 50,
              }}
              axisLeft={{
                tickSize: 0,
                tickPadding: 15,
                tickRotation: 0,
                legendOffset: -60,
                legend: "Glucose(mg/DL)",
                legendPosition: "middle",
                tickValues: [50, 100, 150, 200, 250, 300],
              }}
              theme={{
                fontFamily: "Poppins, sans-serif",
                fontSize: 14,
                axis: {
                  ticks: {
                    text: {
                      fontSize: 14,
                      fontWeight: 500,
                      fill: "#355962",
                    },
                  },
                  legend: {
                    text: {
                      fill: "#9CA3AF",
                      fontWeight: 500,
                      fontSize: 14,
                    },
                  },
                },
              }}
              // enablePoints={true}
              isInteractive={true}
              useMesh={true}
              tooltip={(value: any) => {
                const date = value?.point?.data?.x;
                let label = !(data?.criteria === "day")
                  ? DateTime.fromJSDate(date).toFormat("dd/LL/yyyy")
                  : DateTime.fromJSDate(date).toFormat("dd/LL/yyyy hh:mm a");

                return (
                  <CustomTooltipLineGraph
                    value={value?.point?.data?.y}
                    color={value?.point?.color}
                    label={label}
                  />
                );
              }}
              // xFormat="%Y-%m-%d %I:%M %p"
            />
          </Box>
          {/* <Box>
          <IconButton onClick={() => handleSwipe(1)}>
            <ChevronRight />
          </IconButton>
        </Box> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
            mb: 3,
            width: "60%",
          }}
        >
          <BloodGlucoseInsights tableData={data?.tableData} loading={loading} />
        </Box>
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
            mb: 3,
            width: "40%",
          }}
        >
          <GlucoseDataInsights
            glucoseInsightData={glucoseInsightData}
            loading={loading}
          />
        </Box>
      </Box>
    </>
  );
};

export default BloodGlucose;
