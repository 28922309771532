import {
  Box,
  CircularProgress,
  Divider,
  SxProps,
  Typography,
} from "@mui/material";
import { NoDataContainer } from "../../../../Common/styles/table";
import { LoadingContainer } from "../../../../CMS/cms.style";

export const tempContainer: SxProps = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "60px",
  p: "20px",
};

export const tempName: SxProps = {
  fontWeight: "400",
  fontSize: "14px",
  color: "#111928",
};

export const tempValue: SxProps = {
  fontWeight: "600",
  fontSize: "18px",
  color: "#355962",
};

const MedicationDataInsights = ({ insightsData, loading }: any) => {
  return (
    <Box sx={{ minHeight: "100px" }}>
      <Box
        sx={{
          padding: "16px",
          bgcolor: "#FFFFFF",
          borderRadius: "8px 8px 0 0",
          borderBottom: "1px solid #E0E3EB",
        }}
      >
        <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
          Data Insights
        </Typography>
      </Box>
      {!loading && insightsData && (
        <Box
          sx={{
            m: "16px",
            borderRadius: "12px",
            border: "1px solid #E0E3EB",
          }}
        >
          <Typography
            fontWeight={"600"}
            fontSize={"14px"}
            color="#637E85"
            borderBottom="1px solid #E0E3EB"
            padding="16px"
          >
            Average
          </Typography>
          {!loading &&
            insightsData?.map((data: any, index: number) => (
              <Box key={index}>
                <Box sx={tempContainer}>
                  <Typography sx={tempName}>{data?.medication_name}</Typography>
                  <Typography sx={tempValue}>
                    {data?.average_percentage_taken || "-"}
                  </Typography>
                </Box>
                {!(index === insightsData.length - 1) && <Divider />}
              </Box>
            ))}
        </Box>
      )}
      {!loading && !insightsData && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={LoadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default MedicationDataInsights;
