import { DateTime } from "luxon";

export const colorByValue = (value: any) => {
  if (value > 100) return "#0E9F6E";
  else if (value <= 100) return "#E26F38";
  else return "#0E9F6E";
};
export const getOrdinalIndicator = (day: number) => {
  if (day >= 11 && day <= 13) {
    return `${day} th`;
  } else {
    switch (day % 10) {
      case 1:
        return `${day} st`;
      case 2:
        return `${day} nd`;
      case 3:
        return `${day} rd`;
      default:
        return `${day} th`;
    }
  }
};
export const transformGraphData = (data: any[]) => {
  const waterData = [];
  for (let i = 0; i < data.length; i++) {
    const dayData = data[i];
    const startDate = dayData.date;
    const waterSingleData = {
      startDate,
      value: dayData.waterData,
    };
    waterData.push(waterSingleData);
  }
  return waterData;
};

export const formatDate = (date: any) =>
  date ? DateTime.fromFormat(date, "LL-dd-yyyy").toFormat("dd MMM yyyy") : "";

export const transformStepsGraphData = (data: any[]) => {
  const lineData = [];
  for (let i = 0; i < data.length; i++) {
    const dayData = data[i];
    const tempData = {
      x: dayData.x1,
      y: dayData.y1,
      data: dayData.data,
    };
    lineData.push(tempData);
  }
  // const filteredLineData = lineData.filter((datum) => datum.y !== null);
  return [{ id: "Steps", data: lineData }];
};
export const transFormBarGraphData = (data: any[]) => {
  const lineData = [];
  for (let i = 0; i < data.length; i++) {
    const tempData = {
      date: data[i].x1,
      value: data[i].y1,
    };
    lineData.push(tempData);
  }
  return lineData;
};

export const transformSleepGraphData = (data: any[]) => {
  const barData = [];
  for (let i = 0; i < data.length; i++) {
    const dayData = data[i];
    const tempData = {
      date: dayData.x1,
      value: dayData.value,
    };
    barData.push(tempData);
  }
  const filteredBarData = barData.filter((datum) => datum.value !== null);
  return filteredBarData;
};
