import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { NoDataContainer } from "../../../../Common/styles/table";
import { LoadingContainer } from "../../../../CMS/cms.style";

const RespiratoryDataInsights = ({ insightData, loading }: any) => {
  return (
    <Box minHeight={"100px"}>
      {!loading && insightData && (
        <>
          <Box
            sx={{
              padding: "16px",
              bgcolor: "#FFFFFF",
              borderRadius: "8px 8px 0 0",
              borderBottom: "1px solid #E0E3EB",
            }}
          >
            <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
              Data Insights
            </Typography>
          </Box>
          <Box
            sx={{
              m: "16px",
              height: "166px",
              borderRadius: "12px",
              border: "1px solid #E0E3EB",
            }}
          >
            <Typography
              fontWeight={"600"}
              fontSize={"14px"}
              color="#637E85"
              borderBottom="1px solid #E0E3EB"
              padding="16px"
            >
              Average Values
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: "112px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  {insightData?.high?.toFixed(2) || "-"}
                </Typography>
                <Typography
                  sx={{
                    color: "#637E85",
                    fontWeight: "400",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Highest
                </Typography>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box>
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: "600",
                    fontSize: "18px",
                    textAlign: "center",
                  }}
                >
                  {insightData?.low?.toFixed(2) || "-"}
                </Typography>
                <Typography
                  sx={{
                    color: "#637E85",
                    fontWeight: "400",
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Lowest
                </Typography>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {!loading && !insightData && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ ...LoadingContainer }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default RespiratoryDataInsights;
