import React from "react";
import HeartRate from "./Graphs/HeartRate";
import Respiration from "./Graphs/Respiration";
import { Box } from "@mui/material";
import Activity from "./Graphs/Activity";
import Temperature from "./Graphs/Temperature";
import SpO2 from "./Graphs/SpO2";
import StatsDateSelector from "./StatsDateSelector";

const Stats = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <StatsDateSelector />
      <HeartRate />
      <Activity />
      <Respiration />
      <SpO2 />
      <Temperature />
    </Box>
  );
};

export default Stats;
