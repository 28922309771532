import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AnalysisState {
  startDate: string | null;
  endDate: string | null;
  loading: boolean;
  toggleReload: boolean;
  dateLoaded: boolean;
  user: any;

  offSet: number;
}

const initialState: AnalysisState = {
  startDate: null,
  endDate: null,
  loading: true,
  toggleReload: false,
  dateLoaded: true,
  user: null,
  offSet: 0,
};

const getIntialState = (): AnalysisState => {
  return {
    ...initialState,
  };
};

export const analysisSlice = createSlice({
  name: "analysis",
  initialState: getIntialState,
  reducers: {
    setHealthStartDate: (state, action: PayloadAction<string | null>) => {
      state.startDate = action.payload;
    },
    setUserDetails: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setHealthEndDate: (state, action: PayloadAction<string | null>) => {
      state.endDate = action.payload;
    },
    setToggle: (state, action: PayloadAction<boolean>) => {
      state.toggleReload = action.payload;
    },
    setDateLoaded: (state, action: PayloadAction<boolean>) => {
      state.dateLoaded = action.payload;
    },
    setHealthLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setOffSet: (state, action: PayloadAction<number>) => {
      state.offSet = action.payload;
    },
  },
});

export const {
  setHealthStartDate,
  setHealthEndDate,
  setToggle,
  setDateLoaded,
  setHealthLoading,
  setUserDetails,
  setOffSet,
} = analysisSlice.actions;

export default analysisSlice.reducer;
