import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  HeaderLeftContent,
  HeaderRightContent,
  StyledHeader,
} from "../../Common/styles/header";
import {
  centeredItem,
  colFlexStyle,
  filterBox,
} from "../../Common/styles/flex";
import { CameraIcon, CameraLogo } from "../../Common/assets/Icons";
import { CustomCircularProgressWithLabel } from "../../Common/UI/ProgressWithLabel";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Add, ArrowBack, Delete } from "@mui/icons-material";
import { FieldArray, Form, Formik } from "formik";
import * as yup from "yup";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { CardStyle } from "../../ExerciseCollection/ExerciseBuilder/exercise.style";
import { InputWrapper, LabelStyle } from "../../Common/styles/form";
import { uploadFile } from "../../../utils/upload";
import { useDropzone } from "react-dropzone";
import { UploadWrapper } from "../../ExerciseCollection/ExerciseCollection.style";
import MyResponsivePie from "./PieChart";
import {
  calculateNutrientPercentages,
  emptyProgressData,
  getColorForProgressBar,
  transformCookingMethods,
  transformIngredients,
} from "../../../utils/recipe";
import RearrangeModal from "./RearrangeModal";
import { CustomChipStyle, disabledInputBox, indexBox } from "./recipe.style";

const UploadImageItem: React.FC<any> = ({
  image,
  setFieldValue,
  inputRef,
  readOnly,
  name,
}) => {
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles: any) => {
      try {
        const file = acceptedFiles?.[0];
        if (file) {
          if (file.size > 5 * 1024 * 1024) {
            toastMessage("warning", "File Size cannot be greater than 5 MB!");
            return;
          }
          setLoading(true);
          const url = await uploadFile(file, "questionnaire_image");
          setFieldValue("imageUrl", url);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    },
    [setFieldValue]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: !readOnly ? onDrop : undefined,
    multiple: false,
    accept: {
      "image/*": [],
    },
    noClick: readOnly,
  });

  return (
    <>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box
        {...getRootProps({ className: "dropzone" })}
        sx={{
          ...UploadWrapper,
          mt: 0,
          mb: 0,
          "&:hover": {
            borderColor: readOnly ? "none" : "primary.main",
            cursor: "pointer",
          },
          borderRadius: "12px",
          border: "2px solid #E0E3EB",
          borderStyle: image ? "1px solid #E0E3EB" : "dashed",
          overflow: "hidden",
          width: "245px",
          height: "270px",
          "& .preview-img": {
            height: "270px",
            width: "245px",
          },
        }}
      >
        <input {...getInputProps()} ref={inputRef} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {image ? (
            <img src={image} className="preview-img" alt={name} />
          ) : (
            <>
              <CameraIcon />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Recipe Name is Required")
    .test("is-not-blank", "Recipe Name cannot be empty", (value) => {
      return value.trim().length > 0;
    }),
  totalTime: yup
    .string()
    .optional()
    .test(
      "is-valid-format",
      "Total Time must be in Minutes:Seconds format",
      (value) => {
        if (value !== undefined && value !== null) {
          const regex = /^([0-9][0-9]):([0-5][0-9])$/;
          return regex.test(value.trim());
        }
        return true;
      }
    ),
  preparationTime: yup
    .string()
    .optional()
    .test(
      "is-valid-format",
      "Preparation Time must be in Minutes:Seconds format",
      (value) => {
        if (value !== undefined && value !== null) {
          const regex = /^([0-9][0-9]):([0-5][0-9])$/;
          return regex.test(value.trim());
        }
        return true;
      }
    ),
  finalWeight: yup
    .number()
    .typeError("Final Weight value must be a number")
    .optional()
    .test(
      "is-not-negative",
      "Final Weight value must be a positive number or zero",
      (value) => {
        if (value !== undefined && value !== null) {
          return value >= 0;
        }
        return true;
      }
    ),
  portions: yup
    .number()
    .typeError("Portions value must be a number")
    .optional()
    .test(
      "is-not-negative",
      "Portions value must be a positive number or zero",
      (value) => {
        if (value !== undefined && value !== null) {
          return value >= 0;
        }
        return true;
      }
    ),
  description: yup
    .string()
    .optional()
    .test("is-not-blank", "Description cannot be empty", (value) => {
      if (value !== undefined) {
        return value.trim().length > 0;
      }
      return true;
    }),
  tags: yup
    .string()
    .optional()
    .test("is-not-blank", "Tags cannot be empty", (value) => {
      if (value !== undefined) {
        return value.trim().length > 0;
      }
      return true;
    }),
  fat: yup
    .number()
    .typeError("Fat value must be a number")
    .optional()
    .test(
      "is-not-negative",
      "Fat value must be a positive number or zero",
      (value) => {
        if (value !== undefined && value !== null) {
          return value >= 0;
        }
        return true;
      }
    ),
  carbs: yup
    .number()
    .typeError("Carb value must be a number")
    .optional()
    .test(
      "is-not-negative",
      "Carb value must be a positive number or zero",
      (value) => {
        if (value !== undefined && value !== null) {
          return value >= 0;
        }
        return true;
      }
    ),
  proteins: yup
    .number()
    .typeError("Proteins value must be a number")
    .optional()
    .test(
      "is-not-negative",
      "Proteins value must be a positive number or zero",
      (value) => {
        if (value !== undefined && value !== null) {
          return value >= 0;
        }
        return true;
      }
    ),
  fiber: yup
    .number()
    .typeError("Fiber value must be a number")
    .optional()
    .test(
      "is-not-negative",
      "Fiber value must be a positive number or zero",
      (value) => {
        if (value !== undefined && value !== null) {
          return value >= 0;
        }
        return true;
      }
    ),
  energy: yup
    .number()
    .typeError("Energy value must be a number")
    .optional()
    .test(
      "is-not-negative",
      "Energy value must be a positive number or zero",
      (value) => {
        if (value !== undefined && value !== null) {
          return value >= 0;
        }
        return true;
      }
    ),
  calories: yup
    .number()
    .typeError("Calories value must be a number")
    .optional()
    .test(
      "is-not-negative",
      "Calories value must be a positive number or zero",
      (value) => {
        if (value !== undefined && value !== null) {
          return value >= 0;
        }
        return true;
      }
    ),
  sugar: yup
    .number()
    .typeError("Sugar value must be a number")
    .optional()
    .test(
      "is-not-negative",
      "Sugar value must be a positive number or zero",
      (value) => {
        if (value !== undefined && value !== null) {
          return value >= 0;
        }
        return true;
      }
    ),

  ingredients: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .optional()
        .test("is-not-blank", "Ingredient cannot be empty", (value) => {
          if (value !== undefined) {
            return value.trim().length > 0;
          }
          return true;
        }),
    })
  ),
  methods: yup.array().of(
    yup.object().shape({
      value: yup
        .string()
        .optional()
        .test("is-not-blank", "Cooking Method cannot be empty", (value) => {
          if (value !== undefined) {
            return value.trim().length > 0;
          }
          return true;
        }),
    })
  ),
  imageUrl: yup.string().optional(),
});

const CreateNewRecipe = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [progress, setProgress] = useState<any[]>([]);
  const [pieData, setPieData] = useState<any[]>([]);
  const [nutrientSummary, setNutrientSummary] = useState<{
    progressCount: number;
    piePercentage: number;
  }>({ progressCount: 0, piePercentage: 0 });
  let [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");

  // const handleDraft = () => {};

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(`recipes/${id}`);
        const newData = res.data?.data;
        const recipeData = {
          name: newData?.foodName,
          totalTime: newData?.totalTime,
          preparationTime: newData?.preparationTime,
          finalWeight: newData?.finalWeight,
          portions: newData?.portions,
          description: newData?.description,
          tags: newData?.tags
            ? newData?.tags
                .split(", ")
                .map((tag: string) => tag.trim())
                .filter((tag: string) => tag !== "")
            : [],
          tagDes: newData?.tags,
          fat: newData?.fat,
          carbs: newData?.carbs,
          proteins: newData?.proteins,
          fiber: newData?.fiber,
          energy: newData?.energy,
          calories: newData?.calories,
          sugar: newData?.sugar,

          ingredients:
            mode !== "view"
              ? newData?.ingredients
                ? newData.ingredients
                    .flatMap((item: any) =>
                      item?.description
                        ? item.description
                            .split(",")
                            .filter((desc: string) => desc !== "")
                            .map((desc: string) => ({ value: desc.trim() }))
                        : []
                    )
                    .concat({ value: "" })
                : null
              : newData.ingredients.flatMap((item: any) =>
                  item?.description
                    ? item.description
                        .split(",")
                        .filter((desc: string) => desc !== "")
                        .map((desc: string) => ({ value: desc.trim() }))
                    : []
                ),
          methods:
            mode !== "view"
              ? newData?.cookingMethods
                ? [
                    ...newData?.cookingMethods.map((item: any) => ({
                      value: item?.description,
                    })),
                    { value: "" },
                  ]
                : null
              : [
                  ...newData?.cookingMethods.map((item: any) => ({
                    value: item?.description,
                  })),
                ],
          imageUrl: newData?.imageUrl?.[0],
        };
        const graphData = calculateNutrientPercentages(newData);
        setNutrientSummary({
          progressCount: graphData?.allSum,
          piePercentage: graphData?.totalPiePercentage,
        });

        setData(recipeData);
        setPieData([
          { id: "1", label: "Fat", value: graphData?.fatPercentage },
          { id: "2", label: "Carbs", value: graphData?.carbPercentage },
          { id: "3", label: "Proteins", value: graphData?.proteinsPercentage },
        ]);
        setProgress([
          { label: "Fat", value: graphData?.fat },
          { label: "Carb", value: graphData?.carbs },
          { label: "Protein", value: graphData?.proteins },
          { label: "Fiber", value: graphData?.fiber },
        ]);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };

    if (id !== "new") {
      fetchDetails();
    }
  }, [id, mode]);

  const submitHandler = async (values: any) => {
    try {
      setButtonLoader(true);

      const body = {
        foodName: values?.name,
        totalTime: values?.totalTime,
        preparationTime: values?.preparationTime,
        finalWeight: values?.finalWeight,
        portions: values?.portions,
        description: values?.description,
        tags: values?.tags,
        fat: values?.fat,
        carbs: values?.carbs,
        proteins: values?.proteins,
        fiber: values?.fiber,
        energy: values?.energy,
        calories: values?.calories,
        sugar: values?.sugar,
        ingredients: transformIngredients(values?.ingredients),
        cookingMethods: transformCookingMethods(values?.methods),
        imageUrl: [values?.imageUrl],
      };
      let res: AxiosResponse;
      if (id !== "new") {
        res = await http.patch(`/recipes/${id}`, body);
      } else {
        res = await http.post("/recipes", body);
      }

      toastMessage("success", res.data.message);
      setButtonLoader(false);
      navigate("/app/food/recipes");
    } catch (err) {
      setButtonLoader(false);
      errorToastMessage(err as Error);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };
  const uploadPhoto = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Formik
      initialValues={{
        name: data?.name || "",
        totalTime: data?.totalTime || "",
        preparationTime: data?.preparationTime || "",
        finalWeight: data?.finalWeight || "",
        portions: data?.portions || "",
        description: data?.description || "",
        tags: data?.tagDes || "",
        fat: data?.fat || "",
        carbs: data?.carbs || "",
        proteins: data?.proteins || "",
        fiber: data?.fiber || "",
        energy: data?.energy || "",
        calories: data?.calories || "",
        sugar: data?.sugar || "",

        ingredients:
          data?.ingredients.length > 0
            ? [...data?.ingredients]
            : [
                {
                  value: "",
                },
              ],

        methods:
          data?.methods.length > 0
            ? [...data?.methods]
            : [
                {
                  value: "",
                },
              ],
        imageUrl: data?.imageUrl || "",
      }}
      validationSchema={schema}
      onSubmit={(values) => {
        submitHandler(values);
      }}
      enableReinitialize
    >
      {({ getFieldProps, setFieldValue, values, errors, touched }) => {
        return (
          <Form>
            <StyledHeader>
              <Box sx={{ ...HeaderLeftContent, gap: 1 }}>
                <IconButton onClick={handleBack}>
                  <ArrowBack htmlColor="#637E85" />
                </IconButton>
                <Typography variant="h2">
                  {id !== "new"
                    ? mode === "edit"
                      ? `Edit Recipe ${data?.name ? data?.name : ""}`
                      : `View Recipe  ${data?.name ? data?.name : ""}`
                    : "Create New Recipe"}
                </Typography>
              </Box>
              {mode !== "view" && (
                <Box sx={HeaderRightContent}>
                  {!buttonLoader ? (
                    <>
                      {/* <Button variant="outlined" onClick={handleDraft}>
                        Draft
                      </Button> */}
                      <Button variant="contained" type="submit">
                        Save
                      </Button>
                    </>
                  ) : (
                    <Box>
                      <CircularProgress size={"2rem"} />
                    </Box>
                  )}
                </Box>
              )}
            </StyledHeader>
            {!loading ? (
              <Box sx={{ display: "flex", mt: 2, gap: 2 }}>
                <Box sx={{ ...colFlexStyle, gap: 2, width: "285px" }}>
                  <Box sx={CardStyle}>
                    <FormControl sx={InputWrapper}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: 1,
                        }}
                      >
                        <UploadImageItem
                          image={values?.imageUrl}
                          setFieldValue={setFieldValue}
                          inputRef={fileInputRef}
                          readOnly={mode === "view"}
                          name={values.name}
                        />
                      </Box>
                    </FormControl>
                    {mode !== "view" && (
                      <Box
                        sx={{
                          ...filterBox,
                          justifyContent: "center",
                          mt: 2,
                          gap: 1,
                        }}
                        onClick={uploadPhoto}
                      >
                        <CameraLogo />
                        <Typography
                          fontWeight={600}
                          fontSize={16}
                          color="#8C8D8F"
                        >
                          Add Photos
                        </Typography>
                      </Box>
                    )}
                  </Box>
                  <Box sx={CardStyle}>
                    <Typography fontWeight={600} fontSize={18} color="#355962">
                      Global Analysis
                    </Typography>
                    {nutrientSummary?.progressCount > 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 2,
                          mt: 1.5,
                        }}
                      >
                        {progress?.map((item, index) =>
                          item?.value ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: 1,
                              }}
                              key={index}
                            >
                              <CustomCircularProgressWithLabel
                                value={item.value}
                                ringcolor={getColorForProgressBar(item.value)}
                              />
                              <Typography
                                fontWeight={500}
                                fontSize={12}
                                color="#355962"
                              >
                                {item.label}
                              </Typography>
                            </Box>
                          ) : null
                        )}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: 2,
                          mt: 1.5,
                        }}
                      >
                        {emptyProgressData?.map((item, index) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: 1,
                            }}
                            key={index}
                          >
                            <CustomCircularProgressWithLabel
                              value={item?.value}
                              ringcolor={getColorForProgressBar(item.value)}
                            />
                            <Typography
                              fontWeight={500}
                              fontSize={12}
                              color="#355962"
                            >
                              {item.label}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                  <Box sx={CardStyle}>
                    <Typography fontWeight={600} fontSize={18} color="#355962">
                      Macronutrients
                    </Typography>
                    {nutrientSummary?.piePercentage > 0 ? (
                      <Box sx={{ height: "300px", width: "250px" }}>
                        <MyResponsivePie data={pieData} />
                      </Box>
                    ) : (
                      <Typography
                        fontWeight={500}
                        fontSize={12}
                        color="#355962"
                        textAlign={"center"}
                        mt={2}
                      >
                        You haven't added any ingredients yet
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    ...colFlexStyle,
                    gap: 2,
                    flex: 3,
                  }}
                >
                  <Box sx={CardStyle}>
                    <Typography
                      variant="subtitle2"
                      fontWeight="medium"
                      color="#355962"
                      mb={3}
                    >
                      Basic Information
                    </Typography>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="food-name">
                        Name <span style={{ color: "#f16262" }}>*</span>
                      </FormLabel>
                      <TextField
                        placeholder="Food Name"
                        id="food-name"
                        {...getFieldProps("name")}
                        error={touched?.name && errors?.name ? true : false}
                        helperText={
                          touched?.name && errors?.name
                            ? (errors?.name as string)
                            : " "
                        }
                        disabled={mode === "view"}
                        sx={disabledInputBox}
                      />
                    </FormControl>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <FormControl sx={InputWrapper}>
                        <FormLabel sx={LabelStyle} htmlFor="total-time">
                          Total Time
                        </FormLabel>
                        <TextField
                          placeholder="Total Time"
                          id="total-time"
                          {...getFieldProps("totalTime")}
                          error={
                            touched?.totalTime && errors?.totalTime
                              ? true
                              : false
                          }
                          helperText={
                            touched?.totalTime && errors?.totalTime
                              ? (errors?.totalTime as string)
                              : " "
                          }
                          disabled={mode === "view"}
                          sx={disabledInputBox}
                        />
                      </FormControl>
                      <FormControl sx={InputWrapper}>
                        <FormLabel sx={LabelStyle} htmlFor="preparation-time">
                          Preparation Time
                        </FormLabel>
                        <TextField
                          placeholder="Preparation Time"
                          id="preparation-time"
                          {...getFieldProps("preparationTime")}
                          error={
                            touched?.preparationTime && errors?.preparationTime
                              ? true
                              : false
                          }
                          helperText={
                            touched?.preparationTime && errors?.preparationTime
                              ? (errors?.preparationTime as string)
                              : " "
                          }
                          disabled={mode === "view"}
                          sx={disabledInputBox}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <FormControl sx={InputWrapper}>
                        <FormLabel sx={LabelStyle} htmlFor="final-weight">
                          Final Weight
                        </FormLabel>
                        <TextField
                          placeholder="Final Weight"
                          id="final-weight"
                          {...getFieldProps("finalWeight")}
                          error={
                            touched?.finalWeight && errors?.finalWeight
                              ? true
                              : false
                          }
                          helperText={
                            touched?.finalWeight && errors?.finalWeight
                              ? (errors?.finalWeight as string)
                              : " "
                          }
                          disabled={mode === "view"}
                          sx={disabledInputBox}
                        />
                      </FormControl>
                      <FormControl sx={InputWrapper}>
                        <FormLabel sx={LabelStyle} htmlFor="portions">
                          Portions
                        </FormLabel>
                        <TextField
                          placeholder="Portions"
                          id="portions"
                          {...getFieldProps("portions")}
                          error={
                            touched?.portions && errors?.portions ? true : false
                          }
                          helperText={
                            touched?.portions && errors?.portions
                              ? (errors?.portions as string)
                              : " "
                          }
                          disabled={mode === "view"}
                          sx={disabledInputBox}
                        />
                      </FormControl>
                    </Box>

                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="description">
                        Description
                      </FormLabel>
                      <TextField
                        multiline
                        minRows={3}
                        placeholder="Description"
                        id="description"
                        {...getFieldProps("description")}
                        error={
                          touched?.description && errors?.description
                            ? true
                            : false
                        }
                        helperText={
                          touched?.description && errors?.description
                            ? (errors?.description as string)
                            : " "
                        }
                        disabled={mode === "view"}
                        sx={disabledInputBox}
                      />
                    </FormControl>
                    <Box sx={{ display: "flex", gap: 2 }}>
                      <FormControl sx={InputWrapper}>
                        <FormLabel sx={LabelStyle} htmlFor="fat">
                          Fat
                        </FormLabel>
                        <TextField
                          placeholder="Fat"
                          id="fat"
                          {...getFieldProps("fat")}
                          error={touched?.fat && errors?.fat ? true : false}
                          helperText={
                            touched?.fat && errors?.fat
                              ? (errors?.fat as string)
                              : " "
                          }
                          disabled={mode === "view"}
                          sx={disabledInputBox}
                        />
                      </FormControl>
                      <FormControl sx={InputWrapper}>
                        <FormLabel sx={LabelStyle} htmlFor="carbs">
                          Carb
                        </FormLabel>
                        <TextField
                          placeholder="Carb"
                          id="carbs"
                          {...getFieldProps("carbs")}
                          error={touched?.carbs && errors?.carbs ? true : false}
                          helperText={
                            touched?.carbs && errors?.carbs
                              ? (errors?.carbs as string)
                              : " "
                          }
                          disabled={mode === "view"}
                          sx={disabledInputBox}
                        />
                      </FormControl>
                      <FormControl sx={InputWrapper}>
                        <FormLabel sx={LabelStyle} htmlFor="proteins">
                          Proteins
                        </FormLabel>
                        <TextField
                          placeholder="Proteins"
                          id="proteins"
                          {...getFieldProps("proteins")}
                          error={
                            touched?.proteins && errors?.proteins ? true : false
                          }
                          helperText={
                            touched?.proteins && errors?.proteins
                              ? (errors?.proteins as string)
                              : " "
                          }
                          disabled={mode === "view"}
                          sx={disabledInputBox}
                        />
                      </FormControl>
                    </Box>

                    <Box sx={{ display: "flex", gap: 2 }}>
                      <FormControl sx={InputWrapper}>
                        <FormLabel sx={LabelStyle} htmlFor="energy">
                          Energy
                        </FormLabel>
                        <TextField
                          placeholder="Energy"
                          id="energy"
                          {...getFieldProps("energy")}
                          error={
                            touched?.energy && errors?.energy ? true : false
                          }
                          helperText={
                            touched?.energy && errors?.energy
                              ? (errors?.energy as string)
                              : " "
                          }
                          disabled={mode === "view"}
                          sx={disabledInputBox}
                        />
                      </FormControl>
                      <FormControl sx={InputWrapper}>
                        <FormLabel sx={LabelStyle} htmlFor="calories">
                          Calories
                        </FormLabel>
                        <TextField
                          placeholder="Calories"
                          id="calories"
                          {...getFieldProps("calories")}
                          error={
                            touched?.calories && errors?.calories ? true : false
                          }
                          helperText={
                            touched?.calories && errors?.calories
                              ? (errors?.calories as string)
                              : " "
                          }
                          disabled={mode === "view"}
                          sx={disabledInputBox}
                        />
                      </FormControl>
                      <FormControl sx={InputWrapper}>
                        <FormLabel sx={LabelStyle} htmlFor="sugar">
                          Sugar
                        </FormLabel>
                        <TextField
                          placeholder="Sugar"
                          id="sugar"
                          {...getFieldProps("sugar")}
                          error={touched?.sugar && errors?.sugar ? true : false}
                          helperText={
                            touched?.sugar && errors?.sugar
                              ? (errors?.sugar as string)
                              : " "
                          }
                          disabled={mode === "view"}
                          sx={disabledInputBox}
                        />
                      </FormControl>
                    </Box>
                    <FormControl sx={InputWrapper}>
                      <FormLabel sx={LabelStyle} htmlFor="fiber">
                        Fiber
                      </FormLabel>
                      <TextField
                        placeholder="Fiber"
                        id="fiber"
                        {...getFieldProps("fiber")}
                        error={touched?.fiber && errors?.fiber ? true : false}
                        helperText={
                          touched?.fiber && errors?.fiber
                            ? (errors?.fiber as string)
                            : " "
                        }
                        disabled={mode === "view"}
                        sx={disabledInputBox}
                      />
                    </FormControl>
                  </Box>

                  {mode !== "view" ? (
                    <Box sx={CardStyle}>
                      <FormControl sx={InputWrapper}>
                        <Typography
                          variant="subtitle2"
                          fontWeight="medium"
                          color="#355962"
                          mb={2}
                        >
                          Meal Tags
                        </Typography>

                        <TextField
                          multiline
                          minRows={3}
                          placeholder="Tags"
                          id="tags"
                          {...getFieldProps("tags")}
                          error={touched?.tags && errors?.tags ? true : false}
                          helperText={
                            touched?.tags && errors?.tags
                              ? (errors?.tags as string)
                              : " "
                          }
                          disabled={mode === "view"}
                          sx={disabledInputBox}
                        />
                      </FormControl>
                    </Box>
                  ) : (
                    data?.tags.length > 0 && (
                      <Box sx={CardStyle}>
                        <Typography
                          variant="subtitle2"
                          fontWeight="medium"
                          color="#355962"
                          mb={2}
                        >
                          Meal Tags
                        </Typography>
                        <Stack
                          direction="row"
                          flexWrap={"wrap"}
                          sx={{ gap: 1 }}
                        >
                          {data?.tags?.map((tag: string, index: number) => (
                            <Chip
                              key={index}
                              label={tag}
                              sx={CustomChipStyle}
                            />
                          ))}
                        </Stack>
                      </Box>
                    )
                  )}

                  <Box sx={CardStyle}>
                    <Typography
                      variant="subtitle2"
                      fontWeight="medium"
                      color="#355962"
                      mb={3}
                    >
                      Ingredients
                    </Typography>
                    <RearrangeModal
                      values={values}
                      setFieldValue={setFieldValue}
                      getFieldProps={getFieldProps}
                      errors={errors}
                      touched={touched}
                      isView={mode === "view"}
                    />
                  </Box>
                  <Box sx={CardStyle}>
                    <Typography
                      variant="subtitle2"
                      fontWeight="medium"
                      color="#355962"
                      mb={3}
                    >
                      Cooking Methods
                    </Typography>
                    <FieldArray name="methods">
                      {({ push, remove }) => (
                        <>
                          {values?.methods?.map(
                            (method: any, index: number) => (
                              <Box sx={{ display: "flex", gap: 2 }} key={index}>
                                <Box sx={indexBox}>
                                  <Typography variant="subtitle1">
                                    {index + 1}
                                  </Typography>
                                </Box>
                                <FormControl sx={InputWrapper}>
                                  {index === values?.methods?.length - 1 &&
                                  mode !== "view" ? (
                                    <TextField
                                      multiline
                                      placeholder="Add Cooking Method"
                                      id="add-methods"
                                      {...getFieldProps(
                                        `methods.${index}.value`
                                      )}
                                      InputProps={{
                                        startAdornment: index ===
                                          values?.methods?.length - 1 && (
                                          <InputAdornment position="start">
                                            <IconButton
                                              onClick={() => {
                                                push({
                                                  value: "",
                                                });
                                              }}
                                              disabled={
                                                !values.methods?.[index]?.value
                                              }
                                            >
                                              <Add />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  ) : (
                                    <TextField
                                      multiline
                                      placeholder="Add Cooking Method"
                                      id="add-methods"
                                      {...getFieldProps(
                                        `methods.${index}.value`
                                      )}
                                      disabled={mode === "view"}
                                      sx={{
                                        ...disabledInputBox,
                                        width: "100%",
                                      }}
                                      error={
                                        //@ts-ignore
                                        touched?.methods?.[index]?.value &&
                                        //@ts-ignore
                                        errors?.methods?.[index]?.value
                                          ? true
                                          : false
                                      }
                                      helperText={
                                        //@ts-ignore
                                        touched?.methods?.[index]?.value &&
                                        //@ts-ignore
                                        errors?.methods?.[index]?.value
                                          ? //@ts-ignore
                                            errors?.methods?.[index]?.value
                                          : " "
                                      }
                                      InputProps={{
                                        endAdornment: mode !== "view" && (
                                          <InputAdornment position="end">
                                            <IconButton
                                              disabled={
                                                values?.methods.length === 1
                                              }
                                              sx={{
                                                ".MuiSvgIcon-root": {
                                                  color: "#d32f2f",
                                                },
                                              }}
                                              onClick={() => remove(index)}
                                            >
                                              <Delete />
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                    />
                                  )}
                                </FormControl>
                              </Box>
                            )
                          )}
                        </>
                      )}
                    </FieldArray>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box sx={{ ...centeredItem, mt: 1 }}>
                <CircularProgress size={25} />
              </Box>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateNewRecipe;
