import {
  Backdrop,
  Box,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import StatsCards from "../Cards/StatsCards";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import NoContent from "./NoContent";
import { Delete } from "@mui/icons-material";
import { DateTime } from "luxon";
import MedicationAdherenceBarGraph from "../Graphs/PatientMedicationAdherence";
import BloodGlucosePiechart from "../Graphs/BloodGlucosePiechart";

type Props = {
  toggleLoader: boolean;
  openWidgetModal: () => void;
  refreshPage: () => void;
  setWidgetList: any;
};

function formatBarGraphData(data: any[]) {
  const formmatedData = data?.map((item: any) => {
    return {
      date: DateTime.fromISO(item?.month).toFormat("MMM"),
      value: item?.average_adherence_percentage,
    };
  });

  return formmatedData;
}

function formatGlucoseData(data: any) {
  const initialData = [
    { id: "1", label: "Normal", value: data?.medium },
    { id: "2", label: "Below", value: data?.low },
    { id: "3", label: "High", value: data?.high },
  ];

  const isAllNull = initialData.every(
    (item: any) => item?.value === null || item?.value === 0
  );

  return isAllNull ? [] : initialData;
}

const formatData = (data: any) => {
  let formattedData: any = {
    stats: [
      {
        id: 1,
        value: data?.total_patients,
        widgetKey: "total_patients",
        title: "Patients",
        desc: "Total Patients",
        color: "#0694A2",
      },
      {
        id: 2,
        value: data?.total_admins,
        widgetKey: "total_admins",
        title: "Admins",
        desc: "Total Admins",
        color: "#F49D4C",
      },
      {
        id: 3,
        value: data?.total_exercise,
        widgetKey: "total_exercise",
        title: "Exercises",
        desc: "Total exercises",
        color: "#4A1D96",
      },
      {
        id: 4,
        value: data?.total_learning_modules,
        widgetKey: "total_learning_modules",
        title: "Learning Modules",
        desc: "Total Learning Modules",
        color: "#0E9F6E",
      },
      {
        id: 5,
        value: data?.total_questionnaires,
        widgetKey: "total_questionnaires",
        title: "Questionnaires",
        desc: "Total Questionnaires",
        color: "#F88081",
      },
      {
        id: 6,
        value: data?.total_prescribed_and_total_med_count?.total
          ? `${data.total_prescribed_and_total_med_count.prescribed}/${data.total_prescribed_and_total_med_count.total}`
          : null,
        widgetKey: "total_prescribed_and_total_med_count",
        title: "Medications",
        desc: "Prescribed/Total Medications",
        color: "#158BEB",
      },
      {
        id: 7,
        value: data?.total_food_count,
        widgetKey: "total_food_count",
        title: "Food Items",
        desc: "Total Food Items",
        color: "#9061F9",
      },
    ].filter((item) => item.value !== null && item.value !== undefined),

    patientMedicationAdherence: data?.patients_meds_adherence_over_time
      ? formatBarGraphData(data?.patients_meds_adherence_over_time)
      : null,
    bloodGlucoseLevels: data?.percentage_glucose_levels
      ? formatGlucoseData(data?.percentage_glucose_levels)
      : null,
  };
  return formattedData;
};

const DashboardContent = ({
  toggleLoader,
  openWidgetModal,
  refreshPage,
  setWidgetList,
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [statsData, setStatsData] = useState<any>({});
  const [selectedWidget, setSelectedWidget] = useState<any>({});
  const [menuLoader, setMenuLoader] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  async function deleteWidget() {
    try {
      setMenuLoader(true);
      let res: AxiosResponse;
      res = await http.delete(
        `/user-dashboard/widgets/${selectedWidget?.widgetKey}`
      );
      toastMessage("success", res.data.message);
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
      console.log(err);
    } finally {
      setMenuLoader(false);
      setAnchorEl(null);
    }
  }

  const handleOptionsClick = (
    event: React.MouseEvent<HTMLElement>,
    data: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedWidget(data);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res: AxiosResponse = await http.get(
          `/dashboard-metrics?start_date=${"2024-01-01"}&end_date=${"2024-12-31"}`
        );
        const data = res.data.data;
        const newData = formatData(data);
        const widgets = Object.keys(data);
        setWidgetList(widgets);

        const statsObj = {
          stats: newData?.stats,
          patientMedicationAdherence: newData?.patientMedicationAdherence
            ? {
                data: newData?.patientMedicationAdherence,
                title: "Patient Medication Adherence",
                widgetKey: "patients_meds_adherence_over_time",
                // yMax: findMax(newData?.patientMedicationAdherence),
              }
            : null,
          bloodGlucoseLevels: newData?.bloodGlucoseLevels
            ? {
                data: newData?.bloodGlucoseLevels,
                title: "Blood Glucose Levels",
                widgetKey: "percentage_glucose_levels",
              }
            : null,
        };

        setStatsData(statsObj);

        setLoading(false);
      } catch (err) {
        errorToastMessage(err as Error);
        setLoading(false);
      }
    };
    fetchData();
  }, [toggleLoader, setWidgetList]);

  return (
    <>
      {loading ? (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : statsData?.stats?.length > 0 ||
        statsData?.patientMedicationAdherence ||
        statsData?.bloodGlucoseLevels ? (
        <>
          {statsData?.stats?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "flex-start",
                mb: 2,
              }}
            >
              {statsData?.stats?.length > 0 && (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(auto-fill, minmax(320px, 1fr))",
                    gridGap: "16px",
                    width: "100%",
                  }}
                >
                  {statsData?.stats?.map((item: any) => (
                    <Box key={item?.id}>
                      <StatsCards
                        data={item}
                        refreshPage={refreshPage}
                        handleOptionsClick={handleOptionsClick}
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          )}
          {statsData?.patientMedicationAdherence && (
            <Box mb={2}>
              <MedicationAdherenceBarGraph
                data={statsData?.patientMedicationAdherence}
                handleOptionsClick={handleOptionsClick}
              />
            </Box>
          )}
          {statsData?.bloodGlucoseLevels && (
            <Box mb={2}>
              <BloodGlucosePiechart
                data={statsData?.bloodGlucoseLevels}
                handleOptionsClick={handleOptionsClick}
              />
            </Box>
          )}

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            sx={{
              "& .MuiPaper-root": {
                height: "50px",
                width: "150px",
              },
            }}
          >
            {!menuLoader ? (
              <MenuItem onClick={deleteWidget}>
                <ListItemIcon>
                  <Delete fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 1,
                }}
              >
                <CircularProgress size={18} />
              </Box>
            )}
          </Menu>
        </>
      ) : (
        <>
          <NoContent openWidgetModal={openWidgetModal} />
        </>
      )}
    </>
  );
};

export default DashboardContent;
