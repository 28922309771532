import { Box, Typography } from "@mui/material";
import http from "../../utils/http";
import { errorToastMessage } from "../../utils/toast";
import { useNavigate } from "react-router-dom";
import { Logo } from "../Common/assets/Sidebar";
import { useAppDispatch } from "../../Redux/hooks";
import { setRefreshNotification } from "../../Redux/reducers/notificationSlice";

const NotificationList = ({
  data,
  setAnchorElNotification,
  unreadNotificationCount,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const markAsRead = async (id?: any) => {
    try {
      if (id) {
        const url = `/notifications/${id}`;
        const body = { isRead: true };
        await http.put(url, body);
      } else {
        const url = `notifications/all`;
        const body = { isRead: true };
        await http.patch(url, body);
      }

      dispatch(setRefreshNotification());
    } catch (error) {
      errorToastMessage(error as Error);
    }
  };

  function navigateToNotificationPage() {
    try {
      navigate(`/app/all-notifications?type=notifications`);
      setAnchorElNotification(null);
    } catch (error) {
      errorToastMessage(error as Error);
    }
  }

  return (
    <>
      {data.length !== 0 ? (
        <Box
          sx={{
            bgcolor: "background.paper",
            borderRadius: "6px",
            border: "1px solid rgba(33, 33, 33, 0.10)",
            boxShadow: "6px 6px 38px 0px rgba(0, 0, 0, 0.06)",
            width: "18.75rem",
            maxHeight: "30rem",
            minHeight: "10rem",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minHeight: "2.5rem",
              alignItems: "center",
              padding: ".5rem",
              borderBottom: "1px solid  #E0E3EB",
            }}
          >
            <Typography variant="subtitle1" fontWeight="medium">
              Notifications
            </Typography>
            {/* {unreadNotificationCount !== 0 && (
              <Typography
                variant="body1"
                color="secondary"
                fontWeight="regular"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  markAsRead();
                }}
              >
                Mark all as Read
              </Typography>
            )} */}
            <Typography
              variant="subtitle1"
              color="primary"
              fontWeight="regular"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigateToNotificationPage();
              }}
            >
              See all
            </Typography>
          </Box>
          {data.map((item: any) => (
            <Box
              key={item.id}
              sx={{
                // borderRadius: "5px 5px 0px 0px",
                borderBottom: "1px solid #E0E3EB",
                background: item?.read ? "" : "#FEF1F4",
                minHeight: "5rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: item?.isRead ? "default" : "pointer",
                paddingRight: ".5rem",
              }}
              onClick={() => {
                if (!item.isRead) {
                  markAsRead(item.id);
                }
              }}
            >
              {/* <Box
                sx={{
                  borderRadius: "8px",
                  height: "3.2rem",
                  width: "4rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "1rem",
                  background: "#D714401A",
                }}
              >
                <Logo />
              </Box> */}
              <Box
                sx={{
                  marginLeft: ".5rem",
                  width: "17rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <Box>
                    <Typography
                      variant="subtitle1"
                      fontWeight="regular"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "9rem",
                      }}
                    >
                      {item.heading}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight="light"
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: "9rem",
                      }}
                    >
                      {item.body}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        color: "#6B7280",
                        alignSelf: "flex-end",
                      }}
                    >
                      {item.time}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "12px",
                        textTransform: "capitalize",
                        color: "#6B7280",
                        alignSelf: "flex-end",
                      }}
                    >
                      {item.date}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              minHeight: "2.5rem",
              alignItems: "center",
              padding: ".5rem",
              borderBottom: "1px solid  #E0E3EB",
            }}
          >
            <Typography
              variant="subtitle1"
              color="primary"
              fontWeight="regular"
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigateToNotificationPage();
              }}
            >
              See all Notifications
            </Typography>
          </Box> */}
        </Box>
      ) : (
        <Box
          sx={{
            borderRadius: "5px 5px 0px 0px",
            borderBottom: "1px solid  #E0E3EB",
            minHeight: "15rem",
            display: "flex",
            flexDirection: "row",
            paddingTop: "2rem",
            justifyContent: "center",
            width: "18.75rem",
          }}
        >
          <Typography variant="subtitle1" fontWeight="light" color={"#808080"}>
            There are no Notifications
          </Typography>
        </Box>
      )}
    </>
  );
};

export default NotificationList;
