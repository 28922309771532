import { useEffect, useState } from "react";
import { Box, SxProps } from "@mui/material";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { errorToastMessage } from "../../../../../utils/toast";
import ChartLoader from "../ChartLoader";
import { DateTime } from "luxon";
import http from "../../../../../utils/http";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { useParams } from "react-router-dom";
import { CustomTooltip } from "../../../../Common/UI/Tooltip";
import BloodPressureInsights from "./BloodPressureInsights/BloodPressureInsights";
import BloodPressureDataInsights from "./BloodPressureInsights/BloodPressureDataInsights";
import { setButtonToggle } from "../../../../../Redux/reducers/healthSlice";
import { MainContainer } from "../style";

// const container: SxProps = {
//   borderRadius: "12px",
//   backgroundColor: "#ffffff",
//   boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
//   mb: 3,
//   height: "450px",
//   width: "100%",
//   overflow: "hidden",
//   display: "flex",
//   flexDirection: "column",
// };

const Colors = {
  red: "#D94A56",
  green: "#0EAD69",
  yellow: "#E3A008",
};

const determineColorGraph = (systolic: any, diastolic: any) => {
  if (systolic < 120 && diastolic < 80) {
    return Colors.green;
  } else if (systolic >= 120 && systolic <= 129 && diastolic < 80) {
    return Colors.yellow;
  } else if (
    (systolic >= 130 && systolic <= 139) ||
    (diastolic >= 80 && diastolic <= 89)
  ) {
    return Colors.red;
  } else if (systolic >= 140 || diastolic >= 90) {
    return Colors.red;
  } else if (systolic > 180 || diastolic > 120) {
    return Colors.red;
  }
  return Colors.green;
};

const determineColorToolTip = (serieId: any, value: any) => {
  if (serieId === "systolic") {
    if (value < 120) {
      return Colors.green;
    } else if (value >= 120 && value <= 129) {
      return Colors.yellow;
    } else if (value >= 130 && value <= 139) {
      return Colors.red;
    } else if (value >= 140) {
      return Colors.red;
    }
  } else if (serieId === "diastolic") {
    if (value < 80) {
      return Colors.green;
    } else if (value >= 80 && value <= 89) {
      return Colors.yellow;
    } else if (value >= 90 && value <= 119) {
      return Colors.red;
    } else if (value >= 120) {
      return Colors.red;
    }
  }
  return "#0EAD69";
};

const CustomNodes = (props: any) => {
  return props.nodes.map((node: any, index: number) => {
    const systolicY = props.yScale(node.data.y);
    const diastolicY = props.yScale(node.data.z);
    const color = determineColorGraph(node.data.y, node.data.z);

    return (
      <g key={index}>
        <line
          x1={node.x}
          y1={systolicY}
          x2={node.x}
          y2={diastolicY}
          stroke={color}
          strokeWidth={2}
        />

        {/* <text
          x={node.x}
          y={systolicY - 10}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={11}
          fontWeight={600}
          fill="#4B5563"
        >
          {node.data.y}
        </text> */}
        <rect
          x={node.x - node.size / 1.25}
          y={systolicY}
          width={node.size + 5}
          height={props.yScale(node.data.y2) - systolicY}
          stroke={color}
          strokeWidth={2}
          fill="#FFF"
          rx={7}
          ry={7}
        />

        {/* <text
          x={node.x}
          y={diastolicY - 10}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize={11}
          fontWeight={600}
          fill="#4B5563"
        >
          {node.data.z}
        </text> */}
        <rect
          x={node.x - node.size / 1.25}
          strokeWidth={2}
          y={diastolicY}
          width={node.size + 5}
          height={props.yScale(node.data.z1) - diastolicY}
          stroke={color}
          fill="#FFF"
          rx={7}
          ry={7}
        />
      </g>
    );
  });
};
const formatDate = (dateString: any, criteria: string) => {
  if (criteria === "month" || criteria === "year") {
    return dateString;
  }
  let formattedDay = dateString.trim();
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
  if (formattedDay && isoDateRegex.test(formattedDay)) {
    formattedDay = DateTime.fromFormat(
      formattedDay?.split("T")?.[0],
      "yyyy-LL-dd"
    ).toFormat("yyyy-LL-dd hh:mm a");
  }

  return formattedDay;
};

const BloodPressure = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { startDate, endDate, criteria } = useAppSelector(
    (state) => state.health
  );

  const [loading, setLoading] = useState<Boolean>(true);
  const [data, setData] = useState<any>({
    data: [
      {
        id: "systolic",
        data: [],
      },
      {
        id: "diastolic",
        data: [],
      },
    ] as any,
    loading: false,
    criteria: criteria,
    tableData: [],
    error: "",
    empty: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          dispatch(setButtonToggle(true));

          setData({
            data: [
              {
                id: "systolic",
                data: [],
              },
              {
                id: "diastolic",
                data: [],
              },
            ] as any,
            loading: true,
            criteria: criteria,
            error: "",
            empty: false,
          });

          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "LL-dd-yyyy").startOf("day");
          // const numberOfDays = end.diff(start, "days").get("days");
          // let range = "week";
          // if (numberOfDays > 8) {
          //   range = "month";
          // } else if (numberOfDays === 1) {
          //   range = "day";
          // }
          let vital: string = "week";
          if (criteria === "year") {
            vital = "month";
          }
          const res = await http.get(
            `/mh/bp-average-readings?range=${criteria}&vital=${vital}&userId=${id}&from=${start
              .toUTC()
              .toISO()}&to=${end.toUTC().toISO()}`
          );
          const bloodPressureData = res.data?.data;
          let bloodPressureTable: any[] = [];
          let systolicData: any = [];
          let diastolicData: any = [];
          if (criteria === "month") {
            const weeks = [" ", "w1", "w2", "w3", "w4", "w5", ".", " "];
            bloodPressureTable = weeks.map((week, index) => {
              if (index === 0)
                return {
                  dateLabel: week,
                  time: null,
                  systolic: { high: 0, low: 0 },
                  diastolic: { high: 0, low: 0 },
                  average: { systolicAverage: 0, diastolicAverage: 0 },
                  heartRate: 0,
                };
              const prevWeek = weeks[index - 1];
              const dataForPrevWeek = bloodPressureData.find(
                (item: any) => item.week_number === prevWeek
              );
              return {
                dateLabel: prevWeek,
                time: null,
                systolic: {
                  high: dataForPrevWeek ? dataForPrevWeek.systolic.high : 0,
                  low: dataForPrevWeek ? dataForPrevWeek.systolic.low : 0,
                },
                diastolic: {
                  high: dataForPrevWeek ? dataForPrevWeek.diastolic.high : 0,
                  low: dataForPrevWeek ? dataForPrevWeek.diastolic.low : 0,
                },
                average: {
                  systolicAverage: dataForPrevWeek
                    ? dataForPrevWeek.average.systolicAverage
                    : 0,
                  diastolicAverage: dataForPrevWeek
                    ? dataForPrevWeek.average.diastolicAverage
                    : 0,
                },
                heartRate: dataForPrevWeek
                  ? dataForPrevWeek.heart_rate.heartRateHigh
                  : 0,
              };
            });
            systolicData = weeks.map((week, index) => {
              if (index === 0) return { x: week, y: 0, y2: 0, z: 0, z1: 0 };
              const prevWeek = weeks[index - 1];
              const dataForPrevWeek = bloodPressureData.find(
                (item: any) => item.week_number === prevWeek
              );
              return {
                x: prevWeek,
                y: dataForPrevWeek ? dataForPrevWeek.systolic.high : 0,
                y2: dataForPrevWeek ? dataForPrevWeek.systolic.low : 0,
                z: dataForPrevWeek ? dataForPrevWeek.diastolic.high : 0,
                z1: dataForPrevWeek ? dataForPrevWeek.diastolic.low : 0,
              };
            });

            diastolicData = weeks.map((week, index) => {
              if (index === 0) return { x: week, y: 0, y2: 0, z: 0, z1: 0 };
              const prevWeek = weeks[index - 1];
              const dataForPrevWeek = bloodPressureData.find(
                (item: any) => item.week_number === prevWeek
              );
              return {
                x: prevWeek,
                y: dataForPrevWeek ? dataForPrevWeek.diastolic.high : 0,
                y2: dataForPrevWeek ? dataForPrevWeek.diastolic.low : 0,
                z: dataForPrevWeek ? dataForPrevWeek.systolic.high : 0,
                z1: dataForPrevWeek ? dataForPrevWeek.systolic.low : 0,
              };
            });
          } else if (criteria === "year") {
            const months = [
              " ",
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
              ".",
              "",
            ];
            bloodPressureTable = months.map((month, index) => {
              if (index === 0)
                return {
                  dateLabel: month,
                  time: null,
                  systolic: { high: 0, low: 0 },
                  diastolic: { high: 0, low: 0 },
                  average: { systolicAverage: 0, diastolicAverage: 0 },
                  heartRate: 0,
                };
              const prevWeek = months[index - 1];
              const dataForPrevWeek = bloodPressureData.find(
                (item: any) => item.month === prevWeek
              );
              return {
                dateLabel: prevWeek,
                time: null,
                systolic: {
                  high: dataForPrevWeek ? dataForPrevWeek.systolic.high : 0,
                  low: dataForPrevWeek ? dataForPrevWeek.systolic.low : 0,
                },
                diastolic: {
                  high: dataForPrevWeek ? dataForPrevWeek.diastolic.high : 0,
                  low: dataForPrevWeek ? dataForPrevWeek.diastolic.low : 0,
                },
                average: {
                  systolicAverage: dataForPrevWeek
                    ? dataForPrevWeek.average.systolicAverage
                    : 0,
                  diastolicAverage: dataForPrevWeek
                    ? dataForPrevWeek.average.diastolicAverage
                    : 0,
                },
                heartRate: dataForPrevWeek
                  ? dataForPrevWeek.heart_rate.heartRateHigh
                  : 0,
              };
            });
            systolicData = months.map((month, index) => {
              if (index === 0) return { x: month, y: 0, y2: 0, z: 0, z1: 0 };
              const prevWeek = months[index - 1];
              const dataForPrevWeek = bloodPressureData.find(
                (item: any) => item.month === prevWeek
              );
              return {
                x: prevWeek,
                y: dataForPrevWeek ? dataForPrevWeek.systolic.high : 0,
                y2: dataForPrevWeek ? dataForPrevWeek.systolic.low : 0,
                z: dataForPrevWeek ? dataForPrevWeek.diastolic.high : 0,
                z1: dataForPrevWeek ? dataForPrevWeek.diastolic.low : 0,
              };
            });

            diastolicData = months.map((month, index) => {
              if (index === 0) return { x: month, y: 0, y2: 0, z: 0, z1: 0 };
              const prevWeek = months[index - 1];
              const dataForPrevWeek = bloodPressureData.find(
                (item: any) => item.month === prevWeek
              );
              return {
                x: prevWeek,
                y: dataForPrevWeek ? dataForPrevWeek.diastolic.high : 0,
                y2: dataForPrevWeek ? dataForPrevWeek.diastolic.low : 0,
                z: dataForPrevWeek ? dataForPrevWeek.systolic.high : 0,
                z1: dataForPrevWeek ? dataForPrevWeek.systolic.low : 0,
              };
            });
          } else if (criteria === "week") {
            bloodPressureTable = bloodPressureData.map((item: any) => ({
              dateLabel:
                (criteria === "week"
                  ? DateTime.fromISO(item?.day).toFormat("LLLL dd yyyy")
                  : criteria === "month"
                  ? item?.week_number
                  : item?.month) || "-",
              time:
                criteria === "week"
                  ? DateTime.fromISO(item?.day).toFormat("hh:mm a")
                  : null,
              systolic: {
                high: item?.systolic?.high,
                low: item?.systolic?.low,
              },
              diastolic: {
                high: item?.diastolic?.high,
                low: item?.diastolic?.low,
              },
              average: {
                systolicAverage: item?.average?.systolicAverage,
                diastolicAverage: item?.average?.diastolicAverage,
              },
              heartRate: item?.heart_rate?.heartRateHigh,
            }));
          }

          res.data?.data.forEach((item: any) => {
            const formattedDay = formatDate(
              criteria === "week"
                ? item?.day
                : criteria === "month"
                ? item?.week_number
                : item?.month,
              criteria
            );
            if (item?.systolic) {
              systolicData.push({
                x: formattedDay,
                y: item?.systolic?.high,
                y2: item?.systolic?.low,
                z: item?.diastolic?.high,
                z1: item?.diastolic?.low,
              });
            }
            if (item?.diastolic) {
              diastolicData.push({
                x: formattedDay,
                y: item?.diastolic?.high,
                y2: item?.diastolic?.low,
                z: item?.systolic?.high,
                z1: item?.systolic?.low,
              });
            }
          });
          const isEmpty = bloodPressureData?.length === 0;
          setData({
            data: [
              {
                id: "systolic",
                data: isEmpty ? [] : systolicData.slice(0, 27),
              },
              {
                id: "diastolic",
                data: isEmpty ? [] : diastolicData.slice(0, 27),
              },
            ],
            loading: false,
            criteria: criteria,
            tableData: isEmpty ? [] : bloodPressureTable.slice(0, 27),
            error: "",
            empty: isEmpty,
          });
          setLoading(false);
          dispatch(setButtonToggle(false));
        } else {
          setLoading(false);
          setData({
            data: [
              {
                id: "systolic",
                data: [],
              },
              {
                id: "diastolic",
                data: [],
              },
            ],
            loading: false,
            criteria: criteria,
            tableData: [],

            error: "",
            empty: true,
          });
          dispatch(setButtonToggle(false));
        }
      } catch (err) {
        setLoading(false);
        dispatch(setButtonToggle(false));

        setData((prev: any) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            criteria: criteria,

            data: [
              {
                id: "systolic",
                data: [],
              },
              {
                id: "diastolic",
                data: [],
              },
            ],
            tableData: [],

            empty: false,
          };
        });
        errorToastMessage(err as Error);
      }
    };

    fetchData();
  }, [dispatch, setData, id, startDate, endDate, criteria]);
  return (
    <>
      <Box sx={MainContainer}>
        <Box sx={{ flex: 1, minHeight: "1px", p: 4 }}>
          <ResponsiveScatterPlot
            data={data?.data}
            margin={{ top: 20, right: 50, bottom: 75, left: 70 }}
            isInteractive={true}
            xScale={{
              type:
                data?.criteria === "month" || data?.criteria === "year"
                  ? "point"
                  : "time",
              format:
                data?.criteria === "month" || data?.criteria === "year"
                  ? undefined
                  : "%Y-%m-%d %I:%M %p",
              precision: "minute",
              useUTC: false,
            }}
            yScale={{ type: "linear", min: 50, max: "auto" }}
            yFormat=">-.2f"
            colors={"#327091"}
            layers={[
              "grid",
              "markers",
              "axes",
              "mesh",
              "legends",
              CustomNodes,
              ChartLoader(data?.loading, data?.error, data?.empty, "#70AE71"),
            ]}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              legend: "Blood Pressure (mmHg)",
              legendPosition: "middle",
              legendOffset: -60,
            }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              format:
                data?.criteria === "month" || data?.criteria === "year"
                  ? undefined
                  : "%a",
              tickValues:
                data?.empty || data.loading
                  ? [
                      data?.criteria === "month" || data?.criteria === "year"
                        ? ""
                        : DateTime.fromFormat(
                            startDate || "",
                            "LL-dd-yyyy"
                          ).toFormat("LL-dd-yyyy"),
                    ]
                  : "every 1 days",
              legend: "Time",
              legendPosition: "middle",
              legendOffset: 80,
            }}
            enableGridX={true}
            enableGridY={true}
            theme={{
              fontFamily: "Poppins, sans-serif",
              fontSize: 14,
              axis: {
                ticks: {
                  text: {
                    fontSize: 14,
                    fontWeight: 500,
                    fill: "#355962",
                  },
                },
                legend: {
                  text: {
                    fill: "#9CA3AF",
                    fontWeight: 500,
                    fontSize: 14,
                  },
                },
              },
            }}
            tooltip={({ node }) => {
              return (
                <CustomTooltip
                  value={node.data.y}
                  color={determineColorToolTip(node.serieId, node.data.y)}
                  label={node.serieId}
                />
              );
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
            mb: 3,
            width: "60%",
          }}
        >
          <BloodPressureInsights
            tableData={data?.tableData}
            loading={loading}
          />
        </Box>
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
            mb: 3,
            width: "40%",
          }}
        >
          <BloodPressureDataInsights
            insightData={
              data?.criteria === "week"
                ? data?.tableData?.[0]
                : data?.criteria === "month"
                ? data?.tableData?.[2]
                : data?.tableData?.find(
                    (data: any) =>
                      data?.diastolic?.high !== 0 ||
                      data?.systolic?.high !== 0 ||
                      data?.systolic?.low !== 0 ||
                      data?.diastolic?.low !== 0
                  )
            }
            loading={loading}
          />
        </Box>
      </Box>
    </>
  );
};

export default BloodPressure;
