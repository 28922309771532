import React from "react";
import { Box } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import { DateTime } from "luxon";
import { updateCardData } from "./Sleep";
import ChartLoader from "../ChartLoader";

const SleepGraph = ({
  data,
  setCardContent,
  cardData,
  setDate,
  criteria,
}: any) => {
  return (
    <Box sx={{ height: "360px" }}>
      <ResponsiveLine
        data={data?.data}
        margin={{ top: 20, right: 15, bottom: 42, left: 65 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: 0,
          max: 16,
          clamp: true,
        }}
        enableGridX={false}
        enablePoints={true}
        // pointSize={13}
        // pointColor={"#FFFFFF"}
        // pointBorderWidth={2}
        // pointBorderColor={"#327091"}
        yFormat=" >-.2f"
        colors={["#327091"]}
        lineWidth={3}
        gridYValues={[0, 2, 4, 6, 8, 10, 12, 14, 16]}
        axisTop={null}
        axisRight={null}
        layers={[
          "grid",
          "markers",
          "axes",
          // "areas",
          // "crosshair",
          "lines",
          "points",
          "mesh",
          "legends",
          ChartLoader(data?.loading, data?.error, data?.empty, "#70AE71"),
        ]}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legendOffset: 35,
          legend: "Time",
          legendPosition: "middle",
          tickValues:
            criteria === "month"
              ? data?.[0]?.data
                  ?.map((v: any, i: number) => {
                    const divider = 5;
                    if (i % divider === 0) return v?.x;
                    else return null;
                  })
                  .filter((v: any) => v)
              : undefined,
          format: (value: any) => {
            let dayOfWeek: any = value;
            if (criteria === "week") {
              const date = DateTime.fromFormat(value, "dd/MM/yyyy");
              dayOfWeek = date.toFormat("EEE");
            } else if (criteria === "month") {
              const date = DateTime.fromFormat(value, "dd/MM/yyyy");
              dayOfWeek = date.toFormat("d");
            }
            return dayOfWeek;
          },
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 15,
          tickRotation: 0,
          legendOffset: -50,
          tickValues: [0, 2, 4, 6, 8, 10, 12, 14, 16],
          legend: "Sleep",
          legendPosition: "middle",
          renderTick: ({ x, y, rotate, textAnchor, value }) => (
            <text
              x={x}
              y={y}
              fill="#355962"
              textAnchor={textAnchor}
              dominantBaseline="middle"
              transform={`translate(-15 ,2) rotate(${rotate})`}
              fontSize={14}
              fontWeight={500}
            >
              {value}h
            </text>
          ),
        }}
        theme={{
          fontSize: 14,
          grid: {
            line: {
              strokeDasharray: "6 6",
            },
          },
          axis: {
            ticks: {
              text: {
                fontSize: 14,
                fontWeight: 550,
                fill: "#355962",
              },
            },
            domain: {
              line: {
                stroke: "#E0E3EB",
              },
            },
            legend: {
              text: {
                fill: "#9CA3AF",
                fontSize: 14,
                fontWeight: 500,
              },
            },
          },
          legends: {
            text: {
              fontSize: 16,
              fontWeight: 600,
              fill: "#637E85",
            },
          },
        }}
        isInteractive={true}
        useMesh={true}
        onClick={(data) => {
          const date = data?.data?.x;
          const updatedData = updateCardData(cardData, data?.data);
          setCardContent(updatedData);
          setDate(date);
        }}
      />
    </Box>
  );
};

export default SleepGraph;
