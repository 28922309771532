const Loader: React.FC<any> = ({ color, x, y }) => (
  <g transform={`translate(${x - 50},${y - 50})`}>
    <path
      fill={color}
      d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        dur="1s"
        from="0 50 50"
        to="360 50 50"
        repeatCount="indefinite"
      />
    </path>
  </g>
);

const ChartLoader =
  (loading: boolean, error: string, empty: boolean, color?: string) =>
  (layerProps: any) => {
    return (
      <>
        {loading ? (
          <Loader
            color={color || "#327091"}
            x={layerProps.innerWidth / 2}
            y={layerProps.innerHeight / 2}
          />
        ) : empty ? (
          <text
            x={layerProps.innerWidth / 2}
            y={layerProps.innerHeight / 2}
            textAnchor="middle"
            dominantBaseline="central"
            fill="#9CA3AF"
            fontSize={24}
            fontWeight={"500"}
          >
            No Data available
          </text>
        ) : error ? (
          <text
            x={layerProps.innerWidth / 2}
            y={layerProps.innerHeight / 2}
            textAnchor="middle"
            dominantBaseline="central"
            fill="#9CA3AF"
            fontSize={24}
            fontWeight={"500"}
          >
            {error}
          </text>
        ) : null}
      </>
    );
  };

export default ChartLoader;
