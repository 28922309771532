import { Box, Divider, Stack, SxProps, Typography } from "@mui/material";
import { PhotoSizeSelectActualOutlined } from "@mui/icons-material";

const foodWrapper: SxProps = {
  p: 2,
  minHeight: "100px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const FoodItem = ({ food }: any) => {
  return (
    <Box sx={foodWrapper}>
      <div style={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Typography variant="body1" fontWeight={"medium"}>
          {food.name}
        </Typography>
        <Typography variant="body1" fontWeight={"regular"}>
          {food.tags}
        </Typography>
      </div>
      <Box sx={{ display: "flex", gap: 4 }}>
        <Typography variant="body1">
          {food?.energy ? food?.energy + " kcal" : "-"}
        </Typography>
        <Typography variant="body1">·</Typography>
        <Typography variant="body1">
          {food?.carbs ? food?.carbs + " carbs" : "-"}
        </Typography>
      </Box>
    </Box>
  );
};

export default FoodItem;
