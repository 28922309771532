import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import { NoDataContainer } from "../../../../Common/styles/table";
import { DateTime } from "luxon";
import {
  CheckCircleRounded,
  ExpandMore,
  WarningRounded,
} from "@mui/icons-material";
import FoodItem from "./FoodItem";
import {
  JournalAccordion,
  JournalAccordionHeader,
  JournalAccordionHeaderContainer,
  loggedContainer,
  notloggedContainer,
} from "../style";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import FoodGraph from "./FoodGraph";
import { commonContainerWrapper } from "../../../../Common/styles/flex";
import FoodDataInsights from "./FoodDataInsights";
import { setButtonToggle } from "../../../../../Redux/reducers/healthSlice";

const formatData = (data: any[]) => {
  const lineGraph: any[] = [
    {
      id: "Carbs",
      data: [],
    },
    {
      id: "Proteins",
      data: [],
    },
    {
      id: "Fat",
      data: [],
    },
    {
      id: "Fiber",
      data: [],
    },
  ];
  data.forEach((dayData: any) => {
    const x = dayData.date;
    const yCarbs = dayData.carbs;
    const yFats = dayData.fat;
    const yProteins = dayData.proteins;
    const yFiber = dayData.fiber;
    lineGraph[0].data.push({ x, y: yCarbs });
    lineGraph[1].data.push({ x, y: yFats });
    lineGraph[2].data.push({ x, y: yProteins });
    lineGraph[3].data.push({ x, y: yFiber });
  });

  return lineGraph;
};

const extractNumberFromGramString = (gramString: any) => {
  if (typeof gramString === "number") {
    return gramString;
  }

  if (typeof gramString === "string" && /^\d+(\.\d+)?g$/.test(gramString)) {
    return parseInt(gramString.replace("g", ""));
  }
  if (typeof gramString === "string" && /^\d+(\.\d+)?$/.test(gramString)) {
    return parseInt(gramString);
  }

  return 0;
};

const calculateYMax = (data: any) => {
  let maxY = 100;

  data.forEach((series: any) => {
    series.data.forEach((point: any) => {
      if (point.y > maxY) {
        maxY = point.y;
      }
    });
  });
  return maxY;
};
// const isAllZeroValues = (data: any[]) => {
//   for (const item of data) {
//     if (
//       item.carbs !== 0 ||
//       item.proteins !== 0 ||
//       item.fat !== 0 ||
//       item.fiber !== 0
//     ) {
//       return false;
//     }
//   }
//   return true;
// };

const Food = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { startDate, endDate, criteria } = useAppSelector(
    (state) => state.health
  );

  const [yMax, setyMax] = useState<number>(0);
  const [expanded, setExpanded] = useState<string | false>(false);

  const [insights, setInsights] = useState<any>(null);
  const [accordionData, setAccordionData] = useState<any[]>([]);
  const [data, setData] = useState<any>({
    data: [],
    loading: false,
    error: "",
    empty: false,
  });

  const handleChange =
    (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (startDate && endDate) {
          dispatch(setButtonToggle(true));
          setData({
            data: [],
            loading: true,
            error: "",
            empty: false,
          });
          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf("day");
          const res: AxiosResponse = await http.get(
            `food-log-temp?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );
          const data = res.data.data;
          const now = DateTime.now().startOf("day");
          if (end > now) {
            end = now;
          }

          const groupedData = data.reduce((acc: any, item: any) => {
            const date = DateTime.fromISO(item.createdAt).toFormat(
              "yyyy-LL-dd"
            );
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(item);
            return acc;
          }, {});
          const accordionData = Object.keys(groupedData).map((date) => {
            const dailyEntries = groupedData[date];
            const categorizedEntries = dailyEntries.reduce(
              (acc: any, entry: any) => {
                const mealType = entry.mealType;
                if (!acc[mealType]) {
                  acc[mealType] = [];
                }
                acc[mealType].push({
                  id: entry.id,
                  name: entry.recipe.foodName,
                  imageUrl: entry.recipe.imageUrl[0],
                  mealType: entry.mealType,
                  energy: entry.recipe.calories || "-",
                  fat: entry.recipe.fat || "-",
                  tags: entry?.recipe?.tags || "-",
                  carbs: entry.recipe.carbs || "-",
                  proteins: entry.recipe.proteins || "-",
                  fiber: entry.recipe.fiber || "-",
                });
                return acc;
              },
              { BreakFast: [], Lunch: [], Dinner: [], Snack: [] }
            );
            return {
              dateLabel: DateTime.fromISO(date).toFormat("dd LLLL yyyy"),
              dayLabel: DateTime.fromISO(date).toFormat("cccc"),
              date: DateTime.fromISO(date).toFormat("dd/MM/yyyy"),
              logged: dailyEntries.length > 0,
              entries: categorizedEntries,
            };
          });

          const sortedAccordionData = accordionData.reverse();
          const obj: any = {};
          Object.keys(groupedData).forEach((date) => {
            const dailyEntries = groupedData[date];
            const totals = dailyEntries.reduce(
              (acc: any, entry: any) => {
                acc.carbs += extractNumberFromGramString(entry.recipe.carbs);
                acc.fat += extractNumberFromGramString(entry.recipe.fat);
                acc.proteins += extractNumberFromGramString(
                  entry.recipe.proteins
                );
                acc.fiber += extractNumberFromGramString(entry.recipe.fiber);
                return acc;
              },
              { carbs: 0, fat: 0, proteins: 0, fiber: 0 }
            );

            obj[date] = {
              y1: totals.carbs,
              y2: totals.fat,
              y3: totals.proteins,
              y4: totals.fiber,
            };
          });
          setInsights(res?.data?.inSight);
          const n = end.diff(start, "days").get("days");
          const newData = [];
          for (let i = 0; i < n; i++) {
            const key = end.toFormat("yyyy-LL-dd");
            const entries = (groupedData?.[key] || []).map((entry: any) => {
              return {
                id: entry.id,
                name: entry.recipe.foodName,
                imageUrl: entry.recipe.imageUrl[0],
                // serving: entry.recipe.mealSize,
                time: DateTime.fromISO(entry.createdAt).toFormat("hh:mm a"),
                mealType: entry.recipe.mealType,
                energy: entry.recipe.calories || "-",
                fat: entry.recipe.fat || "-",
                carbs: entry.recipe.carbs || "-",
                proteins: entry.recipe.proteins || "-",
                fiber: entry.recipe.fiber || "-",
              };
            });
            newData.push({
              dayLabel: end.toFormat("cccc"),
              dateLabel: end.toFormat("dd LLLL yyyy"),
              date: end.toFormat("dd/MM/yyyy"),
              logged: entries.length > 0,
              entries: entries,
              carbs: obj[key]?.y1 ?? null,
              fat: obj[key]?.y2 ?? null,
              proteins: obj[key]?.y3 ?? null,
              fiber: obj[key]?.y4 ?? null,
            });
            end = end.plus({
              day: -1,
            });
          }
          const newData1: any = [];
          for (let i = newData.length - 1; i >= 0; i--) {
            newData1.push(newData[i]);
          }
          const isEmpty = data?.length > 0 ? false : true;

          const lineGraph = formatData(newData1);
          const yMaxValue = calculateYMax(lineGraph);
          setyMax(yMaxValue);
          setAccordionData(sortedAccordionData);
          setData({
            data: isEmpty ? [] : lineGraph,
            loading: false,
            error: "",
            empty: isEmpty,
          });

          dispatch(setButtonToggle(false));
        } else {
          setData({
            data: [],
            loading: false,
            error: "",
            empty: true,
          });
          dispatch(setButtonToggle(false));
        }
      } catch (err) {
        setData((prev: any) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            data: [],
            empty: false,
          };
        });
        errorToastMessage(err as Error);
        dispatch(setButtonToggle(false));
      }
    };
    fetchDetails();
  }, [dispatch, startDate, endDate, setData, id]);

  return (
    <>
      <Box sx={{ ...commonContainerWrapper, mb: 2 }}>
        <FoodGraph criteria={criteria} data={data} yMax={yMax} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ bgcolor: "#FFF", borderRadius: "8px", width: "58%" }}>
          <Box sx={{ px: 3, py: 4 }}>
            <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
              Nutrition Data
            </Typography>
          </Box>
          <Box sx={{ bgcolor: "#E0E3EB", p: 3 }}>
            <Typography color={"#355962"} variant="body1" fontWeight={600}>
              Date
            </Typography>
          </Box>
          {!data?.loading &&
            accordionData?.length > 0 &&
            accordionData?.map((item) => {
              return (
                <Accordion
                  key={item.dateLabel}
                  TransitionProps={{ unmountOnExit: true }}
                  expanded={expanded === `panel${item.dateLabel}`}
                  onChange={handleChange(`panel${item.dateLabel}`)}
                  sx={{
                    ...JournalAccordion,
                    mb: 0,
                    "&.Mui-expanded": {
                      margin: 0,
                      borderRadius: 0,
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <IconButton>
                        <ExpandMore sx={{ fontSize: 24 }} />
                      </IconButton>
                    }
                  >
                    <Box sx={JournalAccordionHeaderContainer}>
                      <Box sx={JournalAccordionHeader}>
                        <Box>
                          <Typography
                            fontWeight={600}
                            fontSize={"22px"}
                            color={"#355962"}
                            mb={0.5}
                          >
                            {item?.dateLabel}
                          </Typography>
                          <Typography
                            fontWeight={500}
                            fontSize={"16px"}
                            color={"#355962"}
                            sx={{ textTransform: "capitalize" }}
                          >
                            {item?.dayLabel}
                          </Typography>
                        </Box>
                      </Box>
                      {item.logged ? (
                        <Box sx={loggedContainer}>
                          <CheckCircleRounded sx={{ mr: 1 }} />
                          Food Logged
                        </Box>
                      ) : (
                        <Box sx={notloggedContainer}>
                          <WarningRounded sx={{ mr: 1 }} />
                          Food Not Logged
                        </Box>
                      )}
                    </Box>
                  </AccordionSummary>
                  <Divider />
                  <AccordionDetails sx={{ p: 2 }}>
                    {["BreakFast", "Lunch", "Dinner", "Snack"].map(
                      (mealType: any, index: number, array: string[]) => (
                        <Box key={mealType} sx={{ mb: 2 }}>
                          <Typography
                            variant="h6"
                            sx={{ pl: 2, pb: 2, color: "#355962" }}
                          >
                            {mealType.charAt(0).toUpperCase() +
                              mealType.slice(1)}
                          </Typography>
                          <Divider />

                          <Box>
                            {item?.entries?.[mealType]?.length > 0 ? (
                              item?.entries?.[mealType]?.map(
                                (logEntry: any) => (
                                  <FoodItem key={logEntry.id} food={logEntry} />
                                )
                              )
                            ) : (
                              <NoDataContainer>
                                <Typography variant="body1" color="gray">
                                  No Data
                                </Typography>
                              </NoDataContainer>
                            )}
                          </Box>
                          {index < array.length - 1 && <Divider />}
                        </Box>
                      )
                    )}
                  </AccordionDetails>
                </Accordion>
              );
            })}
          {!data?.loading && accordionData?.length === 0 && (
            <NoDataContainer>
              <Typography variant="body1" color="gray">
                No Data
              </Typography>
            </NoDataContainer>
          )}
          {data?.loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
            mb: 3,
            width: "40%",
          }}
        >
          <FoodDataInsights insightsData={insights} loading={data?.loading} />
        </Box>
      </Box>
    </>
  );
};

export default Food;
