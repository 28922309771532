import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { Box, Divider, LinearProgress, Typography } from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import { NoDataContainer } from "../../../../Common/styles/table";
import { DateTime } from "luxon";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import { commonContainerWrapper } from "../../../../Common/styles/flex";
import SleepGraph from "./SleepGraph";
import { transformStepsGraphData } from "../../../../../utils/health";
import { LinearProgressStyle } from "../style";
import { CustomCircularProgressLabel } from "../../../../Common/UI/ProgressWithLabel";
import { setButtonToggle } from "../../../../../Redux/reducers/healthSlice";

const colorByValue = (value: any) => {
  if (value <= 25) return "red";
  else if (value <= 70) return "#FACA15";
  else if (value <= 100) return "green";
  else return "green";
};

const cardData = [
  { id: 1, label: "Total", key: "total", percentage: 0, value: null },
  { id: 2, label: "REM", key: "rem", percentage: 0, value: null },
  { id: 3, label: "Deep", key: "deep", percentage: 0, value: null },
  {
    id: 4,
    label: "Efficiency",
    key: "sleepEfficiency",
    percentage: 0,
    value: null,
  },
  {
    id: 5,
    label: "RestFullness",
    key: "restFullness",
    percentage: 0,
    value: null,
  },
  { id: 6, label: "Latency", key: "latency", percentage: 0, value: null },
  { id: 7, label: "Timing", key: "timing", percentage: 0, value: null },
  { id: 8, label: "Sleep Score", key: "sleepScore", percentage: 0, value: 0 },
];
export function updateCardData(cardData: any, cardContent: any) {
  if (!cardContent) {
    return cardData;
  }

  const updatedCardData = cardData.map((card: any) => {
    if (cardContent.data.hasOwnProperty(card.key)) {
      if (
        typeof cardContent.data[card.key] === "object" &&
        cardContent.data[card.key] !== null
      ) {
        return {
          ...card,
          value: cardContent.data[card.key].duration,
          percentage: cardContent.data[card.key].percentage,
          color: cardContent.data[card.key].color,
        };
      } else {
        return {
          ...card,
          value: cardContent.data[card.key],
          percentage: cardContent.data[card.key],
        };
      }
    }
    return card;
  });
  return updatedCardData;
}
const totalSleepColor = (total: number) => {
  const n = total / (60 * 60);
  if (n > 10 || n < 6) {
    return "#FF7164";
  } else if (n >= 7 && n <= 9) {
    return "#327091";
  } else {
    return "#327091";
  }
};

const remSleepColor = (total: number) => {
  const n = total / (60 * 60);
  if (n < 1 || n > 2.5) {
    return "#FF7164";
  } else if (n >= 1.5 && n <= 2) {
    return "#327091";
  } else {
    return "#327091";
  }
};

const deepSleepColor = (total: number) => {
  const n = total / (60 * 60);
  if (n < 1.5 || n > 2) {
    return "#FF7164";
  } else if (n >= 1 && n <= 1.5) {
    return "#327091";
  } else {
    return "#327091";
  }
};

const efficiencySleepColor = (total: number) => {
  if (total < 75) {
    return "#FF7164";
  } else if (total >= 85) {
    return "#327091";
  } else {
    return "#327091";
  }
};

const Sleep = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { startDate, endDate, criteria } = useAppSelector(
    (state) => state.health
  );

  const [date, setDate] = useState<string>("");
  const [cardContent, setCardContent] = useState<any>(cardData);
  const [data, setData] = useState<any>({
    data: [],
    loading: false,
    error: "",
    empty: false,
  });

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (startDate && endDate) {
          dispatch(setButtonToggle(true));
          setData({
            data: [],
            loading: true,
            error: "",
            empty: false,
          });

          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf("day");
          const res: AxiosResponse = await http.get(
            `/sleep-log/by-user-id?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}&page=${1}&size=${1000}`
          );
          const lineData = res.data?.rows;
          const stepsObj: {
            [key: string]: { y: number; data: any };
          } = {};
          lineData.forEach((day: any) => {
            const date = day?.createdAt?.split("T")?.[0];
            if (date) {
              stepsObj[date] = {
                y: day.totalSleepDurationInHours,
                data: {
                  sleepScore: day.sleepScore,
                  total: {
                    duration: day.totalSleepDurationInHoursAndMinutes,
                    percentage:
                      day.totalSleepDurationInHoursAndMinutesPercentage,
                    color: totalSleepColor(day.totalSleepDuration || 0),
                  },
                  rem: {
                    duration: day.remSleepDurationInHoursAndMinutes,
                    percentage: day.remSleepDurationInHoursAndMinutesPercentage,
                    color: remSleepColor(day.remSleepDuration || 0),
                  },
                  deep: {
                    duration: day.deepSleepDurationInHoursAndMinutes,
                    percentage:
                      day.deepSleepDurationInHoursAndMinutesPercentage,
                    color: deepSleepColor(day.deepSleepDuration || 0),
                  },
                  sleepEfficiency: {
                    duration: day.sleepEfficiency,
                    percentage: day.sleepEfficiency,
                    color: efficiencySleepColor(day.sleepEfficiency || 0),
                  },
                  restFullness: {
                    duration: day.restfulnessScore,
                    percentage: day.restfulnessScore,
                    color: efficiencySleepColor(day.restfulnessScore || 0),
                  },
                  latency: {
                    duration: day.sleepLatencyScore,
                    percentage: day.sleepLatencyScore,
                    color: efficiencySleepColor(day.sleepLatencyScore || 0),
                  },
                  timing: {
                    duration: day.totalSleepDurationInHoursAndMinutesPercentage,
                    percentage:
                      day.totalSleepDurationInHoursAndMinutesPercentage,
                    color: efficiencySleepColor(
                      day.totalSleepDurationInHoursAndMinutesPercentage || 0
                    ),
                  },
                  // timing: {
                  //   duration: day.sleepTiming,
                  //   percentage: day.sleepTimingInHoursAndMinutesPercentage,
                  //   color: efficiencySleepColor(
                  //     day.sleepTimingInHoursAndMinutesPercentage || 0
                  //   ),
                  // },
                },
              };
            }
          });

          const n = end.diff(start, "days").get("days");
          const newData: any = [];
          for (let i = 0; i < n; i++) {
            const key = end.toFormat("yyyy-LL-dd");
            newData.push({
              dateLabel: end.toFormat("LLLL dd"),
              x1: end.toFormat("dd/LL/yyyy"),
              y1: stepsObj[key] ? stepsObj[key].y : null,
              data: stepsObj[key] ? stepsObj[key].data : {},
            });
            end = end.plus({
              day: -1,
            });
          }
          const newData1: any = [];
          for (let i = newData.length - 1; i >= 0; i--) {
            newData1.push(newData[i]);
          }
          const stepsChartData = transformStepsGraphData(newData1);
          console.log(lineData);
          setData({
            data: lineData?.length === 0 ? [] : stepsChartData,
            loading: false,
            error: "",
            empty: lineData?.length === 0 ? true : false,
          });
          dispatch(setButtonToggle(false));
        } else {
          setData({
            data: [],
            loading: false,
            error: "",
            empty: true,
          });
          dispatch(setButtonToggle(false));
        }
      } catch (err) {
        setData((prev: any) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            data: [],
            empty: false,
          };
        });
        errorToastMessage(err as Error);
        dispatch(setButtonToggle(false));
      }
    };
    fetchDetails();
  }, [dispatch, startDate, endDate, setData, id]);

  return (
    <>
      <Box sx={{ ...commonContainerWrapper, mb: 2 }}>
        <SleepGraph
          data={data}
          setCardContent={setCardContent}
          cardData={cardData}
          setDate={setDate}
          criteria={criteria}
        />
      </Box>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Box sx={{ ...commonContainerWrapper, p: 0, width: "65%" }}>
          <Box
            sx={{
              p: 2,
              borderBottom: "1px solid #e0e3e5",
            }}
          >
            <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
              Sleep Insights {date && `(${date})`}
            </Typography>
          </Box>
          {cardContent.length > 0 ? (
            <Box
              sx={{
                p: 2,
              }}
            >
              {cardContent
                ?.filter((card: any) => card.key !== "sleepScore")
                .map((data: any, index: number) => (
                  <Box
                    key={data?.id}
                    sx={{
                      ...LinearProgressStyle,
                      "& .MuiLinearProgress-bar1Determinate": {
                        borderRadius: 2.5,
                      },
                      "& .MuiLinearProgress-bar": {
                        backgroundColor:
                          data?.color || colorByValue(data?.value),
                      },
                    }}
                  >
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        fontWeight={500}
                        fontSize={"14px"}
                        color="#355962"
                      >
                        {data?.label}
                      </Typography>

                      <Typography
                        fontWeight={600}
                        fontSize={"14px"}
                        color="#355962"
                      >
                        {data?.value}
                      </Typography>
                    </Box>

                    <LinearProgress
                      variant="determinate"
                      value={data?.percentage}
                    />
                    {index !== cardData?.length - 1 && (
                      <Divider sx={{ marginTop: 2 }} />
                    )}
                  </Box>
                ))}
            </Box>
          ) : (
            <NoDataContainer>
              <Typography variant="body1" color="gray">
                No Data
              </Typography>
            </NoDataContainer>
          )}
        </Box>

        <Box
          sx={{
            ...commonContainerWrapper,
            p: 0,
            width: "35%",
            height: "300px",
          }}
        >
          <Box
            sx={{
              p: 2,
              borderBottom: "1px solid #e0e3e5",
            }}
          >
            <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
              Sleep Score
            </Typography>
          </Box>
          <Box
            sx={{
              height: "235px",
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomCircularProgressLabel value={cardContent[7].value} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Sleep;
