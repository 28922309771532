import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { Box } from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import { DateTime } from "luxon";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import { CustomTooltipLineGraph } from "../../../../Common/UI/Tooltip";
import { ResponsiveLine } from "@nivo/line";
import ChartLoader from "../ChartLoader";
import { setButtonToggle } from "../../../../../Redux/reducers/healthSlice";
import WeightInsights from "./WeightInsights";
import { MainContainer } from "../style";

export interface WeightEntry {
  id: string;
  weight: number;
  time: string;
}

const calculateYMax = (data: any) => {
  const yValues = data.map((point: any) => point.y);
  const maxY = Math.max(...yValues);
  return maxY;
};

const Weight = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [yMax, setYMax] = useState<number>(150);
  const [insightsData, setInsightsData] = useState<any>(null);
  // const [data, setData] = useState<WeightMetrics[]>([]);
  const dispatch = useAppDispatch();
  const { startDate, endDate, criteria } = useAppSelector(
    (state) => state.health
  );
  const [graphData, setGraphData] = useState<any>({
    data: [
      {
        id: "weight",
        data: [],
      },
    ] as any,
    loading: true,
    criteria: criteria,
    error: "",
    empty: false,
  });
  // const dispatch = useAppDispatch();
  // const [expanded, setExpanded] = useState<string | false>(false);

  // const handleChange =
  //   (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          dispatch(setButtonToggle(true));

          setGraphData({
            data: [
              {
                id: "weight",
                data: [],
              },
            ],
            loading: true,
            error: "",
            empty: true,
            criteria: criteria,
          });
          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end = DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf("day");
          if (criteria === "month" || criteria === "year") {
            end = DateTime.fromFormat(endDate, "LL-dd-yyyy").startOf("day");
          }

          const res: AxiosResponse = await http.get(
            `/user-details?userId=${id}&size=${1000}&page=${1}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
          );

          const now = DateTime.now();
          if (end > now) {
            if (criteria === "month") {
              end = now;
            } else if (criteria === "year") {
              end = now.startOf("month");
            }
          }
          const data = res?.data?.rows;
          setInsightsData(res?.data?.inSight);
          let weightData: any = [];
          // data.forEach((item: any) => {
          //   weightData.push({
          //     x: DateTime.fromFormat(
          //       item?.createdAt?.split("T")?.[0],
          //       "yyyy-LL-dd"
          //     ).toFormat("yyyy-LL-dd hh:mm a"),
          //     y: item.weight,
          //   });
          // });
          const empty = data.length > 0 ? false : true;

          const groupedData = data.reduce((acc: any, item: any) => {
            const date = DateTime.fromISO(item.createdAt).toFormat(
              "yyyy-LL-dd"
            );
            if (!acc[date]) {
              acc[date] = [];
            }
            acc[date].push(item);
            return acc;
          }, {});

          const n =
            criteria === "year"
              ? end.diff(start, "months").get("months") + 1
              : end.diff(start, "days").get("days");
          const newData = [];
          for (let i = 0; i < n; i++) {
            const key = end.toFormat("yyyy-LL-dd");
            const entries = (groupedData?.[key] || []).map((entry: any) => {
              return {
                id: entry.id,
                weight: entry.weight,
                time: DateTime.fromISO(entry.createdAt).toFormat("hh:mm a"),
              };
            });
            newData.push({
              dayLabel: end.toFormat("cccc"),
              dateLabel: end.toFormat("dd LLLL yyyy"),
              logged: entries.length > 0,
              entries: entries,
            });
            const averageValue =
              entries.length > 0
                ? entries.reduce(
                    (sum: number, entry: any) => sum + entry.weight,
                    0
                  ) / entries.length
                : null;
            weightData.push({
              x: DateTime.fromFormat(
                end?.toISO()?.split("T")?.[0],
                "yyyy-LL-dd"
              ).toFormat("yyyy-LL-dd hh:mm a"),
              y: averageValue,
            });
            end =
              criteria === "year"
                ? end.plus({
                    month: -1,
                  })
                : end.plus({
                    day: -1,
                  });
          }
          const yMaxValue = calculateYMax(weightData);
          setYMax(yMaxValue);
          setGraphData({
            data: [
              {
                id: "weight",
                data: empty ? [] : weightData,
              },
            ],
            loading: false,
            error: "",
            empty: empty,
            criteria: criteria,
          });

          // setData(newData);
          setLoading(false);
          dispatch(setButtonToggle(false));
        } else {
          setGraphData({
            data: [
              {
                id: "weight",
                data: [],
              },
            ] as any,
            loading: false,
            error: "",
            empty: true,
            criteria: criteria,
          });
          setLoading(false);
          dispatch(setButtonToggle(false));
        }
      } catch (err) {
        setLoading(false);
        dispatch(setButtonToggle(false));

        setGraphData((prev: any) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            criteria: criteria,

            data: [
              {
                id: "weight",
                data: [],
              },
            ],
            empty: false,
            tableData: [],
          };
        });
        errorToastMessage(err as Error);
      }
    };
    fetchDetails();
  }, [dispatch, startDate, endDate, setLoading, id, criteria]);

  return (
    <>
      <Box sx={MainContainer}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flex: 1,
            minHeight: "1px",
            py: 4,
            px: 1,
          }}
        >
          <ResponsiveLine
            data={graphData?.data}
            curve="monotoneX"
            margin={{ top: 20, right: 60, bottom: 55, left: 80 }}
            xScale={{
              format: "%Y-%m-%d %I:%M %p",
              precision: "minute",
              type: "time",
              useUTC: false,
            }}
            yScale={{
              type: "linear",
              min: 40,
              max: yMax > 150 ? yMax : 150,
            }}
            layers={[
              "grid",
              "markers",
              "axes",
              // "areas",
              // "crosshair",
              "lines",
              "points",
              "mesh",
              "legends",
              ChartLoader(
                graphData?.loading,
                graphData?.error,
                graphData?.empty,
                "#70AE71"
              ),
            ]}
            enableGridY={true}
            enableGridX={true}
            yFormat=" >-.2f"
            colors={["#327091"]}
            lineWidth={3}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              format: graphData?.criteria === "month" ? "%e" : "%B",
              tickValues:
                graphData?.empty || graphData.loading
                  ? [
                      DateTime.fromFormat(
                        startDate || "",
                        "LL-dd-yyyy"
                      ).toFormat("LL-dd-yyyy"),
                    ]
                  : graphData?.criteria === "month"
                  ? "every 3 days"
                  : "every 1 month",
              legend: "Time",
              legendPosition: "middle",
              legendOffset: 47,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 15,
              tickRotation: 0,
              legendOffset: -55,
              legend: "Weight",
              legendPosition: "middle",
              // tickValues: [50, 100, 150, 200, 250, 300],
            }}
            theme={{
              fontFamily: "Poppins, sans-serif",
              fontSize: 14,
              axis: {
                ticks: {
                  text: {
                    fontSize: 14,
                    fontWeight: 500,
                    fill: "#355962",
                  },
                },
                legend: {
                  text: {
                    fill: "#9CA3AF",
                    fontWeight: 500,
                    fontSize: 14,
                  },
                },
              },
            }}
            isInteractive={true}
            useMesh={true}
            tooltip={(value: any) => {
              const date = value?.point?.data?.x;

              let label = !(criteria === "year")
                ? DateTime.fromJSDate(date).toFormat("LL/dd/yyyy")
                : DateTime.fromJSDate(date).toFormat("MMM yyyy");

              return (
                <CustomTooltipLineGraph
                  value={value?.point?.data?.y}
                  color={value?.point?.color}
                  label={label}
                />
              );
            }}
            // xFormat="%Y-%m-%d %I:%M %p"
          />
        </Box>
      </Box>
      <Box
        sx={{
          borderRadius: "12px",
          backgroundColor: "#ffffff",
          boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
          width: "100%",
          overflow: "hidden",
        }}
      >
        <WeightInsights insightData={insightsData} loading={loading} />
      </Box>
      {/* {data.map((item) => {
        return (
          <Accordion
            key={item.dateLabel}
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded === `panel${item.dateLabel}`}
            onChange={handleChange(`panel${item.dateLabel}`)}
            sx={JournalAccordion}
          >
            <AccordionSummary
              expandIcon={
                <IconButton>
                  <ExpandMore sx={{ fontSize: 24 }} />
                </IconButton>
              }
              sx={{
                ".Mui-expanded": {
                  m: "12px 0px !important",
                },
              }}
            >
              <Box sx={JournalAccordionHeaderContainer}>
                <Box sx={JournalAccordionHeader}>
                  <Box>
                    <Typography
                      fontWeight={600}
                      fontSize={"22px"}
                      color={"#355962"}
                      mb={0.5}
                    >
                      {item?.dateLabel}
                    </Typography>
                    <Typography
                      fontWeight={500}
                      fontSize={"16px"}
                      color={"#355962"}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item?.dayLabel}
                    </Typography>
                  </Box>
                </Box>
                {item.logged ? (
                  <Box sx={loggedContainer}>
                    <CheckCircleRounded sx={{ mr: 1 }} />
                    Weight Logged
                  </Box>
                ) : (
                  <Box sx={notloggedContainer}>
                    <WarningRounded sx={{ mr: 1 }} />
                    Weight Not Logged
                  </Box>
                )}
              </Box>
            </AccordionSummary>

            {item.entries.length > 0 ? (
              <AccordionDetails
                sx={{
                  p: 2,
                  display: "grid",
                  gridTemplateColumns:
                    "repeat(auto-fill , minmax(272px , 1fr))",
                  gap: 2,
                  mb: 2,
                  alignContent: "center",
                }}
              >
                {item.entries.map((logEntry) => {
                  return <WeightItem key={logEntry.id} data={logEntry} />;
                })}
              </AccordionDetails>
            ) : (
              <NoDataContainer>
                <Typography variant="body1" color="gray">
                  No Data
                </Typography>
              </NoDataContainer>
            )}
          </Accordion>
        );
      })} */}
    </>
  );
};

export default Weight;
