import React, { useEffect, useState } from "react";
import { Box, SxProps } from "@mui/material";
import { errorToastMessage } from "../../../../../utils/toast";
import ChartLoader from "../ChartLoader";
import { DateTime } from "luxon";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/hooks";
import { AxiosResponse } from "axios";
import http from "../../../../../utils/http";
import { useParams } from "react-router-dom";
import { CustomTooltipLineGraph } from "../../../../Common/UI/Tooltip";
import TemperatureInsights from "./TemperatureInsights/TemperatureInsights";
import TemperatureDataInsights from "./TemperatureInsights/TemperatureDataInsights";
import { setButtonToggle } from "../../../../../Redux/reducers/healthSlice";

const container: SxProps = {
  borderRadius: "12px",
  backgroundColor: "#ffffff",
  boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
  mb: 3,
  height: "450px",
  width: "100%",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
};
const calculateYMax = (data: any) => {
  const yValues = data.map((point: any) => point.y);
  const maxY = Math.max(...yValues);
  return maxY;
};

const Temperature = () => {
  const { id } = useParams();
  const { startDate, endDate, criteria } = useAppSelector(
    (state) => state.health
  );
  const [yMax, setYMax] = useState<number>(0);
  const [tempInsightData, setTempInsightData] = useState<any>();
  const [loading, setLoading] = useState<Boolean>(true);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>({
    data: [
      {
        id: "Temperature",
        data: [],
      },
    ] as any,
    loading: false,
    error: "",
    empty: false,
    tableData: [],
    criteria: criteria,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (startDate && endDate) {
          setLoading(true);
          dispatch(setButtonToggle(true));

          setData({
            data: [
              {
                id: "Temperature",
                data: [],
              },
            ] as any,
            loading: true,
            error: "",
            empty: false,
            tableData: [],
            criteria: criteria,
          });
          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy").startOf(
            "day"
          );
          let end: any;
          if (criteria === "day") {
            end = DateTime.fromFormat(endDate, "LL-dd-yyyy").endOf("day");
          } else {
            end = DateTime.fromFormat(endDate, "LL-dd-yyyy").startOf("day");
          }
          const res: AxiosResponse = await http.get(
            `/temperature-log/by-user-id?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
            // &page=${1}&size=${1000}
          );
          const tempData = res.data?.rows?.rows;
          setTempInsightData(res.data?.inSight);
          let tempTable: any = [];
          tempTable = tempData.map((item: any) => ({
            dateLabel: DateTime.fromISO(item?.createdAt).toFormat(
              "LLLL dd yyyy"
            ),
            time: DateTime.fromISO(item?.createdAt).toFormat("hh:mm a"),
            value: item.value,
          }));

          let temp: any = [];
          tempData.forEach((item: any) => {
            temp.push({
              x: DateTime.fromISO(item?.createdAt).toFormat(
                "yyyy-LL-dd hh:mm a"
              ),
              y: item.value,
            });
          });
          const empty = tempData.length > 0 ? false : true;
          setLoading(false);
          setData({
            data: [
              {
                id: "Temperature",
                data: temp,
              },
            ],
            loading: false,
            error: "",
            empty: empty,
            tableData: tempTable,
            criteria: criteria,
          });
          const yMax = calculateYMax(temp);
          setYMax(yMax);
          dispatch(setButtonToggle(false));
        } else {
          setLoading(false);
          setData({
            data: [
              {
                id: "Temperature",
                data: [],
              },
            ] as any,
            loading: false,
            error: "",
            empty: true,
            tableData: [],
            criteria: criteria,
          });
          setYMax(0);
          dispatch(setButtonToggle(false));
        }
      } catch (err) {
        setLoading(false);
        dispatch(setButtonToggle(false));

        setData((prev: any) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            data: [
              {
                id: "Temperature",
                data: [],
              },
            ],
            empty: false,
            tableData: [],
            criteria: criteria,
          };
        });
        errorToastMessage(err as Error);
      }
    };

    fetchData();
  }, [dispatch, id, startDate, endDate, criteria]);

  return (
    <>
      <Box sx={container}>
        <Box sx={{ flex: 1, minHeight: "1px", p: 4 }}>
          <ResponsiveScatterPlot
            data={data?.data}
            margin={{ top: 20, right: 50, bottom: 90, left: 70 }}
            xScale={{
              // format: "%I:%M %p",
              format: "%Y-%m-%d %I:%M %p",
              precision: "minute",
              type: "time",
              useUTC: false,
            }}
            yScale={{ type: "linear", min: 95, max: yMax > 105 ? yMax : 105 }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              format:
                criteria === "week"
                  ? "%a"
                  : criteria === "day"
                  ? "%I %p"
                  : "%e",
              tickValues:
                data?.empty || data.loading
                  ? [
                      DateTime.fromFormat(
                        startDate || "",
                        "LL-dd-yyyy"
                      ).toFormat("LL-dd-yyyy"),
                    ]
                  : data?.criteria === "day"
                  ? "every 2 hours"
                  : data?.criteria === "week"
                  ? "every 1 day"
                  : "every 5 days",
              legend: "Time",
              legendPosition: "middle",
              legendOffset: 80,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 15,
              tickRotation: 0,
              legendOffset: -60,
              legend: "Temperature(°F)",
              legendPosition: "middle",
            }}
            theme={{
              fontFamily: "Poppins, sans-serif",
              fontSize: 14,
              axis: {
                ticks: {
                  text: {
                    fontSize: 14,
                    fontWeight: 500,
                    fill: "#355962",
                  },
                },
                legend: {
                  text: {
                    fill: "#9CA3AF",
                    fontWeight: 500,
                    fontSize: 14,
                  },
                },
              },
            }}
            colors={["#327091"]}
            nodeSize={10}
            useMesh={true}
            layers={[
              "grid",
              "axes",
              "nodes",
              "markers",
              ChartLoader(data?.loading, data?.error, data?.empty, "#70AE71"),
            ]}
            tooltip={(value: any) => {
              const date = value?.node?.data?.x;
              let label =
                DateTime.fromJSDate(date).toFormat("dd/LL/yyyy hh:mm a");

              return (
                <CustomTooltipLineGraph
                  value={value?.node?.data?.y}
                  color={value?.node?.color}
                  label={label}
                />
              );
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
            mb: 3,
            width: "60%",
          }}
        >
          <TemperatureInsights tableData={data?.tableData} loading={loading} />
        </Box>
        <Box
          sx={{
            borderRadius: "12px",
            backgroundColor: "#ffffff",
            boxShadow: "0px 0px 12px 0px rgba(0, 0, 0, 0.08)",
            mb: 3,
            width: "40%",
          }}
        >
          <TemperatureDataInsights
            tempInsightData={tempInsightData}
            loading={loading}
          />
        </Box>
      </Box>
    </>
  );
};

export default Temperature;
