import { useMemo, useState } from "react";
import { AxiosResponse } from "axios";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";

import * as yup from "yup";
import { FieldArray, Formik } from "formik";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "./../../../Common/styles/modal";
import { setToggle } from "../../../../Redux/reducers/healthSlice";
import { InputWrapper, LabelStyle } from "./../../../Common/styles/form";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { useAppDispatch, useAppSelector } from "../../../../Redux/hooks";
import http from "../../../../utils/http";
import { errorToastMessage, toastMessage } from "../../../../utils/toast";
import { debounce } from "lodash";
import { useParams } from "react-router-dom";

let schema = yup.object().shape({
  addressLine1: yup.string().optional(),
  addressCity: yup.string().optional(),
  addressState: yup.string().optional(),
  addressPostalCode: yup.string().optional(),
  addressCountryISOCode: yup.string().optional(),
  orderNotes: yup.string().optional(),
  clinicOrderNotes: yup.string().optional(),
  deepBrainStimulator: yup
    .boolean()
    .required("Deep Brain Stimulator Notes are Required"),
  infusionTherapy: yup.string().optional(),
  medDetails: yup.array(),
  // .min(1, "Medication details are Required"),
  reminderDemo: yup.boolean().required("Reminder Demo is Required"),
  orderReminderTimes: yup.array(),
  // .min(1, "Order Reminder Time cannot be empty")
  // .of(yup.string().required("Order Reminder Time cannot be empty")),
  shipDate: yup.string().optional(),
  expirationDate: yup.string().optional(),
  pkgDueDate: yup
    .string()
    .transform((value) => (value ? value?.trim() : value))
    .required("PKG Due Date is Required"),
});

type Props = {
  showModal: boolean;
  closeModal: () => void;
  //   refreshPage: () => void;
  clinicId: string;
  data: any;
};

const AddOrderModal = ({ showModal, closeModal, clinicId, data }: Props) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const { id } = useParams();
  const [searchLoader, setSearchLoader] = useState(false);
  const [medications, setMedications] = useState<any>([]);
  // const { role } = useAppSelector((state) => state.user);
  const { toggleReload } = useAppSelector((state) => state.health);
  // const { clinicId } = useAppSelector((state) => state.health.userData);

  const dispatch = useAppDispatch();
  const handleSearch = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          if (value) {
            setSearchLoader(true);
            let url = `/medication-management?search=${value}&noPagination=true`;
            const res: AxiosResponse = await http.get(url);
            const meds = res?.data?.data?.medications?.map((med: any) => {
              return {
                name: med?.propreiteryName,
                id: med?.id,
                // strength: med?.strength,
              };
            });
            setMedications(meds);
            setSearchLoader(false);
          }
        } catch (err) {
          errorToastMessage(err as Error);
          setSearchLoader(false);
        }
      }, 500),
    []
  );
  const submitHandler = async (values: any) => {
    try {
      setSubmitLoader(true);
      let url: string;
      let res: AxiosResponse;
      const orderId = data?.orderId;
      const body = {
        ...values,
      };
      body.orderReminderTimes = body.orderReminderTimes.filter(
        (order: string) => order
      );
      body.expirationDate = values.expirationDate
        ? DateTime.fromFormat(values.expirationDate, "dd/MM/yyyy").toFormat(
            "yyyy-MM-dd"
          )
        : undefined;
      body.pkgDueDate = DateTime.fromFormat(
        values.pkgDueDate,
        "dd/MM/yyyy"
      ).toFormat("yyyy-MM-dd");
      body.shipDate = values.shipDate
        ? DateTime.fromFormat(values.shipDate, "dd/MM/yyyy").toFormat(
            "yyyy-MM-dd"
          )
        : undefined;
      if (orderId) {
        url = `/clinic/${clinicId}/orders/${orderId}`;
        res = await http.put(url, body);
      } else {
        body.userId = id;
        url = `/clinic/${clinicId}/orders`;
        res = await http.post(url, body);
      }
      // role === "clinician"
      //   ? `/participants/clinic`
      //   : `/participants/${id}/clinic`;
      toastMessage("success", res.data.message);
      closeModal();
      dispatch(setToggle(!toggleReload));
    } catch (err) {
      setSubmitLoader(false);
      errorToastMessage(err as Error);
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={ModalBaseStyles}>
        <ModalHeader
          title={data?.orderId ? "Update Order" : "Add Order"}
          onCloseClick={closeModal}
        />

        <Formik
          initialValues={{
            // orderId: initialData?.orderId || "",
            // recordNumber: initialData?.recordNumber || "",
            orderNotes: data?.orderNotes || "",
            clinicOrderNotes: data?.clinicOrderNotes || "",
            deepBrainStimulator: data?.deepBrainStimulator || false,
            infusionTherapy: data?.infusionTherapy || "",
            addressLine1: data?.addressLine1 || "",
            medDetails: data?.medDetails || [],
            reminderDemo: data?.reminderDemo || false,
            orderReminderTimes: data?.orderReminderTimes?.length
              ? data?.orderReminderTimes
              : [""],
            shipDate: data?.shipDate
              ? DateTime.fromFormat(data.shipDate, "dd/MM/yyyy").toFormat(
                  "yyyy-MM-dd"
                )
              : "",
            expirationDate: data?.expirationDate
              ? DateTime.fromFormat(
                  data?.expirationDate,
                  "dd/MM/yyyy"
                ).toFormat("yyyy-MM-dd")
              : "",
            pkgDueDate: data?.pkgDueDate
              ? DateTime.fromFormat(data?.pkgDueDate, "dd/MM/yyyy").toFormat(
                  "yyyy-MM-dd"
                )
              : "",
            // street: data?.street || "",
            addressCity: data?.addressCity || "",
            addressState: data?.addressState || "",
            addressPostalCode: data?.addressPostalCode || "",
            addressCountryISOCode: data?.addressCountryISOCode || "",
          }}
          validationSchema={schema}
          onSubmit={(values) => {
            submitHandler(values);
          }}
        >
          {({
            handleSubmit,
            getFieldProps,
            errors,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              {/* <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="order-id">
                    Order ID
                  </FormLabel>
                  <TextField
                    id="order-id"
                    {...getFieldProps("orderId")}
                    error={touched?.orderId && errors?.orderId ? true : false}
                    helperText={
                      touched?.orderId && errors?.orderId
                        ? (errors?.orderId as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="record-number">
                    Record Number
                  </FormLabel>
                  <TextField
                    id="record-number"
                    {...getFieldProps("recordNumber")}
                    error={
                      touched?.recordNumber && errors?.recordNumber
                        ? true
                        : false
                    }
                    helperText={
                      touched?.recordNumber && errors?.recordNumber
                        ? (errors?.recordNumber as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box> */}
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address">
                    Address
                  </FormLabel>
                  <TextField
                    id="address"
                    {...getFieldProps("addressLine1")}
                    error={
                      touched?.addressLine1 && errors?.addressLine1
                        ? true
                        : false
                    }
                    helperText={
                      touched?.addressLine1 && errors?.addressLine1
                        ? (errors?.addressLine1 as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address-city">
                    Address City
                  </FormLabel>
                  <TextField
                    id="address-city"
                    {...getFieldProps("addressCity")}
                    error={
                      touched?.addressCity && errors?.addressCity ? true : false
                    }
                    helperText={
                      touched?.addressCity && errors?.addressCity
                        ? (errors?.addressCity as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address-state">
                    Address State
                  </FormLabel>
                  <TextField
                    id="address-state"
                    {...getFieldProps("addressState")}
                    error={
                      touched?.addressState && errors?.addressState
                        ? true
                        : false
                    }
                    helperText={
                      touched?.addressState && errors?.addressState
                        ? (errors?.addressState as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address-postalCode">
                    Address Postal Code
                  </FormLabel>
                  <TextField
                    id="address-postalcode"
                    {...getFieldProps("addressPostalCode")}
                    error={
                      touched?.addressPostalCode && errors?.addressPostalCode
                        ? true
                        : false
                    }
                    helperText={
                      touched?.addressPostalCode && errors?.addressPostalCode
                        ? (errors?.addressPostalCode as string)
                        : " "
                    }
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address-countryIsoCode">
                    Country ISO Code
                  </FormLabel>
                  <TextField
                    id="address-isoCode"
                    {...getFieldProps("addressCountryISOCode")}
                    error={
                      touched?.addressCountryISOCode &&
                      errors?.addressCountryISOCode
                        ? true
                        : false
                    }
                    helperText={
                      touched?.addressCountryISOCode &&
                      errors?.addressCountryISOCode
                        ? (errors?.addressCountryISOCode as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="order-notes">
                    Order Notes
                  </FormLabel>
                  <TextField
                    id="order-notes"
                    {...getFieldProps("orderNotes")}
                    error={
                      touched?.orderNotes && errors?.orderNotes ? true : false
                    }
                    helperText={
                      touched?.orderNotes && errors?.orderNotes
                        ? (errors?.orderNotes as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="clinic-order-notes">
                    Clinic Order Notes
                  </FormLabel>
                  <TextField
                    id="clinic-notes"
                    {...getFieldProps("clinicOrderNotes")}
                    error={
                      touched?.clinicOrderNotes && errors?.clinicOrderNotes
                        ? true
                        : false
                    }
                    helperText={
                      touched?.clinicOrderNotes && errors?.clinicOrderNotes
                        ? (errors?.clinicOrderNotes as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="deep-brain-simulator">
                    Deep Brain Stimulator
                  </FormLabel>
                  <Select
                    fullWidth
                    id="deep-brain-simulator"
                    value={values.deepBrainStimulator}
                    onChange={(e) => {
                      setFieldValue("deepBrainStimulator", e.target.value);
                    }}
                  >
                    {[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ].map((item: any, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.deepBrainStimulator && errors?.deepBrainStimulator
                      ? (errors?.deepBrainStimulator as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="infusion-therapy">
                    Infusion Therapy
                  </FormLabel>
                  <TextField
                    id="infusion-therapy"
                    {...getFieldProps("infusionTherapy")}
                    error={
                      touched?.infusionTherapy && errors?.infusionTherapy
                        ? true
                        : false
                    }
                    helperText={
                      touched?.infusionTherapy && errors?.infusionTherapy
                        ? (errors?.infusionTherapy as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box>

              <Box>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="med-details">
                    Med Details
                  </FormLabel>
                  <Autocomplete
                    filterOptions={(x) => x}
                    onInputChange={(_1: any, value: any, reason: string) => {
                      if (reason === "input") {
                        handleSearch(value);
                      }
                      // setFieldValue("medDetails", {
                      //   name: value,
                      //   id: null,
                      // });
                    }}
                    onChange={(_1: any, value: any) => {
                      const newMedDetails = Array.isArray(value)
                        ? value
                        : [value];
                      setFieldValue(
                        "medDetails",
                        newMedDetails.map((med) => {
                          return med?.name ? med?.name : med;
                        })
                      );
                    }}
                    options={medications}
                    value={values?.medDetails}
                    multiple
                    getOptionLabel={(option) => option?.name || option}
                    isOptionEqualToValue={(option, value) => {
                      return option?.name === value;
                    }}
                    renderOption={(props, option) => (
                      <li {...props} key={option?.id}>
                        {option?.name}
                      </li>
                    )}
                    loading={searchLoader}
                    loadingText={<CircularProgress size={20} />}
                    noOptionsText="No Results"
                    clearOnBlur={false}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search medications by typing..."
                      />
                    )}
                  />
                  <FormHelperText error>
                    {touched?.medDetails &&
                    errors?.medDetails &&
                    typeof errors?.medDetails === "string"
                      ? "*Medication is Required"
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="reminder-demo">
                    Reminder Demo
                  </FormLabel>
                  <Select
                    id="reminder-demo"
                    value={values?.reminderDemo}
                    onChange={(e) => {
                      setFieldValue("reminderDemo", e.target.value);
                    }}
                  >
                    {[
                      { value: true, label: "Yes" },
                      { value: false, label: "No" },
                    ].map((item: any, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {touched?.reminderDemo && errors?.reminderDemo
                      ? (errors?.reminderDemo as string)
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Box>

              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="orderReminderTimes">
                    Order Reminder Times
                  </FormLabel>
                  <FieldArray name="orderReminderTimes">
                    {({ remove, push }) => (
                      <>
                        {values?.orderReminderTimes?.map(
                          (timeString: any, index: number) => (
                            <Box key={index}>
                              <TimePicker
                                format="hh:mm a"
                                value={
                                  timeString
                                    ? DateTime.fromFormat(timeString, "HH:mm")
                                    : null
                                }
                                onChange={(newValue: any) =>
                                  setFieldValue(
                                    `orderReminderTimes[${index}]`,
                                    newValue.toFormat("HH:mm")
                                  )
                                }
                                // minutesStep={30}
                                // renderInput={(
                                //   params: JSX.IntrinsicAttributes &
                                //     TextFieldProps
                                // ) => (
                                //   <Box
                                //     sx={{
                                //       display: "flex",
                                //       mb: 2,
                                //       gap: 1,
                                //     }}
                                //   >
                                //     <TextField
                                //       {...params}
                                //       sx={{ width: "100%" }}
                                //       inputProps={{
                                //         ...params.inputProps,
                                //         readOnly: true,
                                //       }}
                                //     />

                                //     <Box
                                //       sx={{
                                //         display: "flex",
                                //         alignItems: "center",
                                //       }}
                                //     >
                                //       <IconButton onClick={() => push("")}>
                                //         <Add />
                                //       </IconButton>
                                //       <IconButton
                                //         disabled={
                                //           values?.orderReminderTimes?.length ===
                                //           1
                                //         }
                                //         color="error"
                                //         onClick={() => remove(index)}
                                //       >
                                //         <Delete />
                                //       </IconButton>
                                //     </Box>
                                //   </Box>
                                // )}
                              />
                              {/* @ts-ignore */}
                              {touched?.orderReminderTimes?.[index] &&
                                // @ts-ignore
                                errors?.orderReminderTimes?.[index] && (
                                  <FormHelperText error sx={{ mb: 1 }}>
                                    {/* @ts-ignore */}
                                    {
                                      // @ts-ignore
                                      errors?.orderReminderTimes?.[
                                        index
                                      ] as string
                                    }
                                  </FormHelperText>
                                )}
                            </Box>
                          )
                        )}
                      </>
                    )}
                  </FieldArray>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="ship-date">
                    Ship Date
                  </FormLabel>
                  <DatePicker
                    format="dd/MM/yyyy"
                    value={
                      values?.shipDate
                        ? DateTime.fromFormat(values?.shipDate, "dd/MM/yyyy")
                        : null
                    }
                    onChange={(newValue: any) => {
                      setFieldValue(
                        "shipDate",
                        newValue.toFormat("dd/MM/yyyy")
                      );
                    }}
                    // renderInput={(
                    //   params: JSX.IntrinsicAttributes & TextFieldProps
                    // ) => (
                    //   <TextField
                    //     {...params}
                    //     inputProps={{
                    //       ...params.inputProps,
                    //       readOnly: true,
                    //       placeholder: "Select date",
                    //     }}
                    //     error={
                    //       touched?.shipDate && errors?.shipDate ? true : false
                    //     }
                    //     helperText={
                    //       touched?.shipDate && errors?.shipDate
                    //         ? (errors?.shipDate as string)
                    //         : " "
                    //     }
                    //   />
                    // )}
                  />
                </FormControl>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="expiration-date">
                    Expiration Date
                  </FormLabel>
                  <DatePicker
                    format="dd/MM/yyyy"
                    value={
                      values?.expirationDate
                        ? DateTime.fromFormat(
                            values?.expirationDate,
                            "dd/MM/yyyy"
                          )
                        : null
                    }
                    onChange={(newValue: any) => {
                      setFieldValue(
                        "expirationDate",
                        newValue.toFormat("dd/MM/yyyy")
                      );
                    }}
                    // renderInput={(
                    //   params: JSX.IntrinsicAttributes & TextFieldProps
                    // ) => (
                    //   <TextField
                    //     {...params}
                    //     inputProps={{
                    //       ...params.inputProps,
                    //       readOnly: true,
                    //       placeholder: "Select date",
                    //     }}
                    //     error={
                    //       touched?.expirationDate && errors?.expirationDate
                    //         ? true
                    //         : false
                    //     }
                    //     helperText={
                    //       touched?.expirationDate && errors?.expirationDate
                    //         ? (errors?.expirationDate as string)
                    //         : " "
                    //     }
                    //   />
                    // )}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ ...InputWrapper, width: "50%" }}>
                  <FormLabel sx={LabelStyle} htmlFor="pkg-due-date">
                    PKG Due Date <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <DatePicker
                    format="dd/MM/yyyy"
                    value={
                      values?.pkgDueDate
                        ? DateTime.fromFormat(values?.pkgDueDate, "dd/MM/yyyy")
                        : null
                    }
                    onChange={(newValue: any) => {
                      setFieldValue(
                        "pkgDueDate",
                        newValue.toFormat("dd/MM/yyyy")
                      );
                    }}
                    // renderInput={(
                    //   params: JSX.IntrinsicAttributes & TextFieldProps
                    // ) => (
                    //   <TextField
                    //     {...params}
                    //     inputProps={{
                    //       ...params.inputProps,
                    //       readOnly: true,
                    //       placeholder: "Select date",
                    //     }}
                    //     error={
                    //       touched?.pkgDueDate && errors?.pkgDueDate
                    //         ? true
                    //         : false
                    //     }
                    //     helperText={
                    //       touched?.pkgDueDate && errors?.pkgDueDate
                    //         ? (errors?.pkgDueDate as string)
                    //         : " "
                    //     }
                    //   />
                    // )}
                  />
                </FormControl>
              </Box>
              {/* <Box sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={InputWrapper}>
                  <FormLabel sx={LabelStyle} htmlFor="address.street">
                    Address Street <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <TextField
                    id="address-street"
                    {...getFieldProps("street")}
                    error={touched?.street && errors?.street ? true : false}
                    helperText={
                      touched?.street && errors?.street
                        ? (errors?.street as string)
                        : " "
                    }
                  />
                </FormControl>
              </Box> */}

              <Box sx={ModalActionButtonStyles}>
                {!submitLoader ? (
                  <>
                    <Button type="submit" variant="contained">
                      Save
                    </Button>
                    <Button onClick={closeModal} variant="outlined">
                      Cancel
                    </Button>
                  </>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default AddOrderModal;
