import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../Redux/hooks";
import { errorToastMessage } from "../../../../../../utils/toast";
import { DateTime } from "luxon";
import { AxiosResponse } from "axios";
import http from "../../../../../../utils/http";
import { calculateYMax, getGraphData } from "../../../../../../utils/journal";
import TemplateGraph from "./TemplateGraph";
import { Box, Divider, Typography } from "@mui/material";
import { ContainerStyle, DetailStyle } from "../style";

const HeartRate = () => {
  const { id } = useParams();
  const { startDate, endDate } = useAppSelector((state) => state.analysis);
  const [yMax, setYMax] = useState<number>(0);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any>({
    data: [
      {
        id: "heart",
        data: [],
      },
    ] as any,
    loading: false,
    tableData: [],
    error: "",
    empty: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (startDate && endDate) {
          //   setLoading(true);

          setData({
            data: [
              {
                id: "heart",
                data: [],
              },
            ] as any,
            loading: true,
            tableData: [],

            error: "",
            empty: false,
          });
          const start = DateTime.fromFormat(startDate, "LL-dd-yyyy HH:mm:ss");
          let end: any = DateTime.fromFormat(endDate, "LL-dd-yyyy HH:mm:ss");
          const res: AxiosResponse = await http.get(
            `/heart-rate-log/by-user-id?userId=${id}&startDate=${start
              .toUTC()
              .toISO()}&endDate=${end.toUTC().toISO()}`
            // &page=${1}&size=${1000}`
          );
          const heartData = res.data?.rows;
          let heart: any = [];
          heart = getGraphData(heartData);
          const isEmpty = heartData.length === 0;
          const yMaxValue = calculateYMax(heart);
          setYMax(yMaxValue);
          setData({
            data: [
              {
                id: "heart",
                data: isEmpty ? [] : heart,
              },
            ],
            loading: false,
            error: "",
            empty: isEmpty,
          });
        } else {
          setData({
            data: [
              {
                id: "heart",
                data: [],
              },
            ] as any,
            loading: false,
            tableData: [],

            error: "",
            empty: true,
          });
        }
        // setLoading(false);
      } catch (err) {
        setData((prev: any) => {
          return {
            ...prev,
            error: "Something went wrong",
            loading: false,
            data: [
              {
                id: "heart",
                data: [],
              },
            ],
            tableData: [],

            empty: false,
          };
        });
        errorToastMessage(err as Error);
        // setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, id, startDate, endDate]);
  return (
    <Box sx={ContainerStyle}>
      <Box sx={DetailStyle}>
        <Box>
          <Typography
            fontWeight={"medium"}
            fontSize={"22px"}
            color="#355962"
            marginTop={2}
          >
            Heart Rate
          </Typography>
          <Typography
            color="#678188"
            fontSize={"14px"}
            fontWeight={"medium"}
            marginTop={1}
          >
            bpm
          </Typography>
        </Box>
      </Box>
      {/* <IconButton>
          <MoreVert />
        </IconButton> */}
      <Divider orientation="vertical" />
      <TemplateGraph
        data={data}
        yMin={40}
        yMax={yMax}
        threshold={200}
        startDate={startDate}
        endDate={endDate}
        color={"#FF5D5D"}
      />
    </Box>
  );
};

export default HeartRate;
