import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import { LoadingContainer } from "../../../../CMS/cms.style";
import { NoDataContainer } from "../../../../Common/styles/table";

const WeightInsights = ({ insightData, loading }: any) => {
  return (
    <Box minHeight={"100px"}>
      {!loading && insightData && (
        <>
          <Box
            sx={{
              padding: "16px",
              bgcolor: "#FFFFFF",
              borderRadius: "8px 8px 0 0",
              borderBottom: "1px solid #E0E3EB",
            }}
          >
            <Typography fontWeight={"medium"} fontSize={"22px"} color="#355962">
              Weight Insights
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                m: "16px",
                height: "150px",
                borderRadius: "12px",
                border: "1px solid #E0E3EB",
                width: "100%",
              }}
            >
              <Typography
                fontWeight={"600"}
                fontSize={"14px"}
                color="#637E85"
                borderBottom="1px solid #E0E3EB"
                padding="16px"
              >
                Starting
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "90px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#637E85",
                      fontWeight: "400",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                  >
                    Starting Weight
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: "600",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    {insightData?.starting || "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                m: "16px",
                height: "150px",
                borderRadius: "12px",
                border: "1px solid #E0E3EB",
                width: "100%",
              }}
            >
              <Typography
                fontWeight={"600"}
                fontSize={"14px"}
                color="#637E85"
                borderBottom="1px solid #E0E3EB"
                padding="16px"
              >
                Current
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "90px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#637E85",
                      fontWeight: "400",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                  >
                    High Resting HR (bpm)
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: "600",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    {insightData?.current || "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                m: "16px",
                height: "150px",
                borderRadius: "12px",
                border: "1px solid #E0E3EB",
                width: "100%",
              }}
            >
              <Typography
                fontWeight={"600"}
                fontSize={"14px"}
                color="#637E85"
                borderBottom="1px solid #E0E3EB"
                padding="16px"
              >
                Target
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "90px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#637E85",
                      fontWeight: "400",
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                  >
                    High Resting HR (bpm)
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000000",
                      fontWeight: "600",
                      fontSize: "18px",
                      textAlign: "center",
                    }}
                  >
                    {insightData?.goal || "-"}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
      {!loading && !insightData && (
        <NoDataContainer>
          <Typography variant="body1" color="gray">
            No Data
          </Typography>
        </NoDataContainer>
      )}
      {loading && (
        <Box sx={{ ...LoadingContainer }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

export default WeightInsights;
