import { Box, Divider, LinearProgress, Typography } from "@mui/material";
import { AdherenceContainer, LinearProgressStyle } from "../style";
// import { ResponsiveBar } from "@nivo/bar";
// import { ResponsiveLineCanvas } from "@nivo/line";
// import { DateTime } from "luxon";
type Props = {
  data: any;
};
// const colors: any = {
//   Carbs: "#FF5A1F",
//   Proteins: "#7E3AF2",
//   Fat: "#1C64F2",
//   Fiber: "#047481",
// };
const colorByValue = (value: any) => {
  if (value < 50) return "#FF7164";
  else if (value >= 50 && value < 75) return "#FACA15";
  else if (value >= 75 && value <= 100) return "#70AE71";
  else return "#70AE71";
};

const MedicationAdherence = ({ data }: Props) => {
  return (
    <>
      <Box sx={AdherenceContainer}>
        <Box marginBottom={"16px"}>
          <Typography
            fontWeight={"medium"}
            fontSize={"22px"}
            color="#355962"
            marginBottom={"8px"}
          >
            Medication Adherence
          </Typography>
          {/* {data?.type === "progress" && (
            <Typography fontWeight={"medium"} fontSize={"32px"} color="#355962">
              {data.totalPercent}%
            </Typography>
          )} */}
        </Box>
        {/* {data?.type === "progress" ? ( */}
        <>
          {data?.map((item: any, index: number) => (
            <Box
              key={index}
              sx={{
                ...LinearProgressStyle,
                "& .MuiLinearProgress-bar": {
                  backgroundColor: colorByValue(item?.individualPercent),
                },
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  fontWeight={"medium"}
                  fontSize={"22px"}
                  color="#355962"
                >
                  {item?.name}
                </Typography>

                <Typography
                  fontWeight={"medium"}
                  fontSize={"22px"}
                  color="#355962"
                >
                  {+item?.individualPercent}%
                </Typography>
              </Box>

              <LinearProgress
                variant="determinate"
                value={+item?.individualPercent}
              />
              {index !== data?.length - 1 && <Divider sx={{ marginTop: 1 }} />}
            </Box>
          ))}
        </>
        {/* ) : ( */}
        {/* <Box sx={{ height: "60vh" }}> */}
        {/* <ResponsiveLineCanvas
              data={data}
              margin={{ top: 20, right: 15, bottom: 70, left: 65 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: 0,
                max: 100,
                clamp: true,
              }}
              enableGridX={false}
              enablePoints={true}
              pointSize={13}
              pointColor={"#FFFFFF"}
              pointBorderWidth={2}
              pointBorderColor={(d: any) => {
                return d.serieColor;
              }}
              yFormat=" >-.2f"
              colors={(d: any) => {
                return d.color;
              }}
              lineWidth={3}
              gridYValues={[0, 20, 40, 60, 80, 100]}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 0,
                tickPadding: 10,
                tickRotation: 0,
                legendOffset: 60,
                legend: "Time",
                legendPosition: "middle",
                tickValues:
                  criteria === "month"
                    ? data?.[0]?.data
                        ?.map((v: any, i: number) => {
                          const divider = 5;
                          if (i % divider === 0) return v?.x;
                          else return null;
                        })
                        .filter((v: any) => v)
                    : undefined,
                format: (d) => {
                  let dayOfWeek: any = d;
                  if (criteria === "week") {
                    const date = DateTime.fromFormat(d, "dd/MM/yyyy");
                    dayOfWeek = date.toFormat("EEE");
                  } else if (criteria === "month") {
                    const date = DateTime.fromFormat(d, "dd/MM/yyyy");
                    dayOfWeek = date.toFormat("d");
                  }
                  return dayOfWeek;
                },
              }}
              axisLeft={{
                tickSize: 0,
                tickPadding: 15,
                tickRotation: 0,
                legendOffset: -50,
                tickValues: [0, 20, 40, 60, 80, 100],
                legend: "Adherence",
                legendPosition: "middle",
                renderTick: ({ x, y, rotate, textAnchor, value }) => (
                  <text
                    x={x}
                    y={y}
                    fill="#355962"
                    textAnchor={textAnchor}
                    dominantBaseline="middle"
                    transform={`translate(-15 ,2) rotate(${rotate})`}
                    fontSize={14}
                    fontWeight={500}
                  >
                    {value}
                  </text>
                ),
              }}
              theme={{
                fontSize: 14,
                grid: {
                  line: {
                    strokeDasharray: "6 6",
                  },
                },
                axis: {
                  ticks: {
                    text: {
                      fontSize: 14,
                      fontWeight: 550,
                      fill: "#355962",
                    },
                  },
                  domain: {
                    line: {
                      stroke: "#E0E3EB",
                    },
                  },
                  legend: {
                    text: {
                      fill: "#9CA3AF",
                      fontSize: 14,
                      fontWeight: 500,
                    },
                  },
                },
                legends: {
                  text: {
                    fontSize: 16,
                    fontWeight: 600,
                    fill: "#637E85",
                  },
                },
              }}
              isInteractive={true}
            /> */}
        {/* <Box
              sx={{
                flexWrap: "wrap",
                display: "flex",
                gap: 2,
                justifyContent: "flex-end",
              }}
            >
              {data?.map((item: any) => {
                return (
                  <Box
                    key={item?.id}
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <Box
                      sx={{
                        borderRadius: "50%",
                        backgroundColor: item?.color,
                        width: "12px",
                        height: "12px",
                      }}
                    ></Box>
                    <Typography variant="body1" color="#9CA3AF">
                      {item?.id}
                    </Typography>
                  </Box>
                );
              })}
            </Box> */}
        {/* <ResponsiveBar
              data={data?.medication.map((item: any) => ({
                date: item?.date,
                percent: item?.percent,
              }))}
              keys={["percent"]}
              indexBy="date"
              colors={(d: any) => colorByValue(d.formattedValue)}
              margin={{ top: 40, right: 50, bottom: 50, left: 80 }}
              padding={0.94}
              valueScale={{ type: "linear", min: 20, max: 100, clamp: true }}
              indexScale={{ type: "band" }}
              gridYValues={[20, 40, 60, 80, 100]}
              theme={{
                fontSize: 14,
                grid: {
                  line: {
                    strokeDasharray: "6 6",
                  },
                },
                axis: {
                  domain: {
                    line: {
                      stroke: "#E0E3EB",
                    },
                  },
                  ticks: {
                    text: {
                      fill: "#355962",
                      fontSize: 14,
                      fontWeight: 550,
                    },
                  },
                  legend: {
                    text: {
                      fill: "#9CA3AF",
                      fontSize: 14,
                      fontWeight: 500,
                    },
                  },
                },
              }}
              axisBottom={{
                tickSize: 0,
                tickPadding: 12,
                tickRotation: 0,
                legendPosition: "middle",
                legendOffset: 40,
              }}
              axisLeft={{
                tickSize: 0,
                tickPadding: 7,
                tickRotation: 0,
                legend: "Adherence",
                tickValues: [20, 40, 60, 80, 100],
                format: (value) => `${value}%`,
                legendPosition: "middle",
                legendOffset: -70,
              }}
              enableLabel={false}
              ariaLabel="Nivo bar chart"
            /> */}
      </Box>
      {/* )} */}
      {/* <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "20px",
            height: "20px",
          }}
        ></Box> */}
      {/* </Box> */}
    </>
  );
};

export default MedicationAdherence;
