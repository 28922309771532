import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  ModalActionButtonStyles,
  ModalBaseStyles,
  ModalHeader,
} from "../../Common/styles/modal";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import http from "../../../utils/http";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { NoDataContainer } from "../../Common/styles/table";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  refreshPage: () => void;
  widgets: string[];
};

const AddWidgetModal = ({
  showModal,
  closeModal,
  refreshPage,
  widgets,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [widgetList, setWidgetList] = useState<any[]>([]);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [selectedWidgets, setSelectedWidgets] = useState<any[]>([...widgets]);

  useEffect(() => {
    async function getWidgetsData() {
      try {
        setLoading(true);
        // const res = await http.get("/api/widgets");
        const data = [
          {
            id: 128,
            widgetKey: "total_patients",
            title: "Number of patients",
            description: "Total number of patients",
            image: "/total_patients.jpg",
          },
          {
            id: 126,
            widgetKey: "total_admins",
            title: "Number of administrators",
            description: "Total number of administrators",
            image: "/total_admins.jpg",
          },
          {
            id: 127,
            widgetKey: "total_exercise",
            title: "Number of exercises",
            description: "Total number of exercises",
            image: "/total_exercises.jpg",
          },
          {
            id: 124,
            widgetKey: "total_learning_modules",
            title: "Number of learning modules",
            description: "Total number of learning modules",
            image: "/total_learning_modules.jpg",
          },
          {
            id: 111,
            widgetKey: "total_questionnaires",
            title: "Number of Questionnaires",
            description: "Total number of questionnaires",
            image: "/total_questionnaires.jpg",
          },
          {
            id: 121324,
            widgetKey: "total_prescribed_and_total_med_count",
            title: "Prescribed/Total Medications",
            description: "Prescribed/Total Medications",
            image: "/prescribed_total_medications.jpg",
          },
          {
            id: 129,
            widgetKey: "total_food_count",
            title: "Used Food/TotalFood Items",
            description: "Used food items vs Total food items",
            image: "/used_total_food.jpg",
          },
          {
            id: 236,
            widgetKey: "patients_meds_adherence_over_time",
            title: "Patient Medication Adherence",
            description: "Patient Medication Adherence",
            image: "/patient_adherence.jpg",
          },
          {
            id: 237,
            widgetKey: "percentage_glucose_levels",
            title: "Blood Glucose Levels",
            description: "Blood Glucose Levels",
            image: "/blood_glucose_levels.jpg",
          },
        ];
        // const filteredWidgets = data.filter(
        //   (widget) => !widgets.includes(widget.widgetKey)
        // );

        setWidgetList(data);
      } catch (error) {
        errorToastMessage(error as Error);
      } finally {
        setLoading(false);
      }
    }

    getWidgetsData();
  }, [widgets]);

  const handleCheckboxToggle = (key: string) => {
    if (selectedWidgets.includes(key)) {
      setSelectedWidgets(selectedWidgets.filter((item) => item !== key));
    } else {
      setSelectedWidgets([...selectedWidgets, key]);
    }
  };

  async function submitHandler() {
    try {
      setSubmitLoader(true);
      const body = {
        keys: [...selectedWidgets],
      };
      const res = await http.post(`/user-dashboard/widgets`, body);
      toastMessage("success", res?.data?.message);
      closeModal();
      refreshPage();
    } catch (err) {
      errorToastMessage(err as Error);
    } finally {
      setSubmitLoader(false);
    }
  }

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box
        sx={{
          ...ModalBaseStyles,
          maxHeight: "95vh",
          width: {
            xs: "96vw",
            md: "53vw",
          },
        }}
      >
        <ModalHeader title={"Add Widget"} onCloseClick={closeModal} />
        {!loading ? (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {widgetList?.map((item: any) => (
              <Box
                key={item.widgetKey}
                display="flex"
                alignItems="center"
                width={"100%"}
                // height={"150px"}
              >
                <Checkbox
                  checked={selectedWidgets.includes(item?.widgetKey)}
                  icon={<CheckBoxOutlineBlank />}
                  onChange={() => handleCheckboxToggle(item?.widgetKey)}
                  checkedIcon={<CheckBox />}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 7,
                  }}
                >
                  <Box
                    sx={{
                      height: "200px",
                      width: "300px",
                      display: "flex",
                      alignItems: "center",
                      "& .image": {
                        maxWidth: "300px",
                        maxHeight: "200px",
                      },
                      ml: 2,
                    }}
                  >
                    <img
                      src={item.image}
                      alt={item.title}
                      className="image"
                      style={{ objectFit: "contain" }}
                      loading="lazy"
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Typography variant="h6">{item.title}</Typography>
                    <Typography variant="subtitle1">
                      {item.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
            {!loading && widgetList?.length === 0 && (
              <NoDataContainer>
                <Typography variant="body1" color="gray">
                  All widgets have been added!
                </Typography>
              </NoDataContainer>
            )}
            <Box sx={{ ...ModalActionButtonStyles, mt: 3 }}>
              {!submitLoader ? (
                <>
                  <Button onClick={closeModal} variant="outlined">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    disabled={selectedWidgets.length === 0}
                    onClick={submitHandler}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <CircularProgress size={25} />
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default AddWidgetModal;
